import * as types from "./billerMgmtTypes";

const initialState = {
  isLoading: false,
  billers: [],
  isLoadingBiller: false,
  billTypes: [],
  isLoadingBillItem: false,
  isLoadingBillTypes: false,
  isLoadingAggregator: false,
  uploadBulkBillerData: null,
  uploadBulkBillerColumnHeaders: [],
  uploadBulkBillerErrorRows: [],
  uploadBulkBillerErrorRowsSN: [],
  uploadBulkBillItemData: null,
  uploadBulkBillItemColumnHeaders: [],
  uploadBulkBillItemErrorRows: [],
  uploadBulkBillItemErrorRowsSN: [],

  uploadAggregatorBulkBillerData: null,
  uploadAggregatorBulkBillerColumnHeaders: [],
  uploadAggregatorBulkBillerErrorRows: [],
  uploadAggregatorBulkBillerErrorRowsSN: [],

  uploadAggregatorBulkBillItemData: null,
  uploadAggregatorBulkBillItemColumnHeaders: [],
  uploadAggregatorBulkBillItemErrorRows: [],
  uploadAggregatorBulkBillItemErrorRowsSN: [],

	aggregatorVendedBillers:[]
};

const billerMgmtReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.BILLER_MGMT_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.BILLER_MGMT_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case types.BILLER_MGMT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case types.BILLER_MGMT_BILLERS:
      return {
        ...state,
        isLoading: false,
        billers: payload,
      };
    case types.BILLER_MGMT_UPDATE_LOADING_AGGREGATOR:
      return {
        ...state,
        isLoadingAggregator: payload,
      };
    case types.BILLER_MGMT_UPDATE_LOADING_BILLER:
      return {
        ...state,
        isLoadingBiller: payload,
      };
    case types.BILLER_MGMT_UPDATE_LOADING_BILLITEM:
      return {
        ...state,
        isLoadingBillItem: payload,
      };
    case types.BILLER_MGMT_UPDATE_LOADING_BILLTYPES:
      return {
        ...state,
        isLoadingBillTypes: payload,
      };
    case types.BILLER_MGMT_BILLTYPES:
      return {
        ...state,
        isLoadingBillTypes: false,
        billTypes: payload,
      };
    case types.UPLOAD_BULK_BILLER_FILE:
      return {
        ...state,
        isLoading: false,
        uploadBulkBillerData: payload.uploadBulkBillerData,
        uploadBulkBillerColumnHeaders: payload.uploadBulkBillerColumnHeaders,
        uploadBulkBillerErrorRows: payload.uploadBulkBillerErrorRows,
        uploadBulkBillerErrorRowsSN: payload.uploadBulkBillerErrorRowsSN,
      };
    case types.UPLOAD_AGGREGATOR_BULK_BILLER_FILE:
      return {
        ...state,
        isLoading: false,
        uploadAggregatorBulkBillerData: payload.uploadAggregatorBulkBillerData,
        uploadAggregatorBulkBillerColumnHeaders:
          payload.uploadAggregatorBulkBillerColumnHeaders,
        uploadAggregatorBulkBillerErrorRows:
          payload.uploadAggregatorBulkBillerErrorRows,
        uploadAggregatorBulkBillerErrorRowsSN:
          payload.uploadAggregatorBulkBillerErrorRowsSN,
      };
    case types.UPLOAD_AGGREGATOR_BULK_BILL_ITEM_FILE:
      return {
        ...state,
        isLoading: false,
        uploadAggregatorBulkBillItemData:
          payload.uploadAggregatorBulkBillItemData,
        uploadAggregatorBulkBillItemColumnHeaders:
          payload.uploadAggregatorBulkBillItemColumnHeaders,
        uploadAggregatorBulkBillItemErrorRows:
          payload.uploadAggregatorBulkBillItemErrorRows,
        uploadAggregatorBulkBillItemErrorRowsSN:
          payload.uploadAggregatorBulkBillItemErrorRowsSN,
      };
    case types.UPLOAD_BULK_BILLITEM_FILE:
      return {
        ...state,
        isLoading: false,
        uploadBulkBillItemData: payload.uploadBulkBillItemData,
        uploadBulkBillItemColumnHeaders:
          payload.uploadBulkBillItemColumnHeaders,
        uploadBulkBillItemErrorRows: payload.uploadBulkBillItemErrorRows,
        uploadBulkBillItemErrorRowsSN: payload.uploadBulkBillItemErrorRowsSN,
      };
			case types.UPDATE_AGGREGATOR_VENDED_BILLERS:
      return {
        ...state,
        aggregatorVendedBillers: payload.aggregatorVendedBillers,
      };
    default:
      return state;
  }
};

export default billerMgmtReducer;
