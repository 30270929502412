import Button from "library/Button";
import Loader from "library/Loader";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";

import { StyledSuccessModal } from "./styles";

const StyledModalBackground = styled.div`
  position: fixed;
  overflow: hidden;
  top: 100;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(64, 25, 109, 0.2);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5em 1em;
  z-index: 99999;
  box-sizing: border-box;
  overflow: scroll;
`;

const UnsupportedFileUrlModal = ({
  setShowModal = () => {},
  onDissmiss = () => {},
  fileUrl = "",
  btnClassName,
  children,
  onSubmit = () => {},
  primaryBtnLabel = "Click me",
  isLoading = false,
}) => {
  const handleClick = (evt) => {
    if (evt.target.id === "modalbg") setShowModal(false);
  };

  return (
    <StyledModalBackground id="modalbg" onClick={handleClick}>
      <StyledSuccessModal>
        <div className="card-content">
          <h2>Unsupported File URL</h2>

          <div className="icon">
            <UnsupportedModalIcon />
          </div>

          <p className="error-message" style={{ marginBottom: 44 }}>
            This document cannot be viewed because the file url is not valid.
            {/* This is the URL provided -{" "}
            <span style={{ fontSize: 12, background: "#fefefe" }}>
              {fileUrl?.toString()}
            </span> */}
          </p>
        </div>

        <footer>
          <Button
            size="lg"
            loading={false}
            width="145px"
            variant="primary"
            disabled={false}
            onClick={() => {
              setShowModal(false);
              onDissmiss();
            }}
          >
            Okay
          </Button>
        </footer>
      </StyledSuccessModal>
    </StyledModalBackground>
  );
};

export default UnsupportedFileUrlModal;

const UnsupportedModalIcon = () => {
  return (
    <svg
      width="65"
      height="64"
      viewBox="0 0 65 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4202_57)">
        <path
          d="M54.5755 64H13.3402C7.89265 64 3.43722 59.5446 3.43722 54.097V12.8797C3.43722 7.43218 7.89265 2.97675 13.3402 2.97675H54.5574C60.005 2.97675 64.4604 7.43218 64.4604 12.8797V54.097C64.4785 59.5446 60.023 64 54.5755 64Z"
          fill="#DFE3FF"
        />
        <path
          d="M51.6202 61.0233H10.403C4.95543 61.0233 0.5 56.5691 0.5 51.1232V9.90005C0.5 4.45412 4.95543 0 10.403 0H51.6202C57.0678 0 61.5232 4.45412 61.5232 9.90005V51.1052C61.5412 56.5691 57.0858 61.0233 51.6202 61.0233Z"
          fill="#40196D"
        />
        <path
          d="M32.4005 42.2526C32.4005 43.2585 31.585 44.074 30.5791 44.074C29.5731 44.074 28.7576 43.2585 28.7576 42.2526V27.1845C28.7576 26.1785 29.5731 25.363 30.5791 25.363C31.585 25.363 32.4005 26.1785 32.4005 27.1845V42.2526Z"
          fill="white"
        />
        <rect
          width="3.64285"
          height="3.63824"
          rx="1.81912"
          transform="matrix(1 0 0 -1 28.7576 21.5169)"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4202_57">
          <rect
            width="64"
            height="64"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
