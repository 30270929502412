export const BUSINESS_BANKING_START = "BUSINESS_BANKING_START";
export const BUSINESS_BANKING_FAIL = "BUSINESS_BANKING_FAIL";
export const BUSINESS_BANKING_SUCCESS = "BUSINESS_BANKING_SUCCESS";
export const SEARCH_BUSINESS_ACCOUNT = "SEARCH_BUSINESS_ACCOUNT";
export const SEARCH_BUSINESS_PARAMS = "SEARCH_BUSINESS_PARAMS";
export const CLEAR_BUSINESS_SEARCH = "CLEAR_BUSINESS_SEARCH";
export const GET_UPGRADE_REQUEST = "GET_UPGRADE_REQUEST";
export const GET_ADDRESS_VERIFICATION_REQUESTS =
  "GET_ADDRESS_VERIFICATION_REQUESTS";
export const SEARCH_ADDITIONAL_BUSINESS_ACCOUNT =
  "SEARCH_ADDITIONAL_BUSINESS_ACCOUNT";
