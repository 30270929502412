import { useCallback, useEffect, useState } from "react";
import { fetchAndActivate, getValue } from "firebase/remote-config";

import { remoteConfig } from "bootstrap";

/**
 * It fetches the remote config value for the given key and stores it in the local storage
 * @param key - The key of the remote config value you want to fetch.
 * @param storageKey - The key to use when storing the config in local storage.
 * @returns An object with a config property.
 */
const useRemoteConfig = (key, storageKey) => {
	const [config, setConfig] = useState({});

	const fetchRemoteConfig = useCallback(() => {
		fetchAndActivate(remoteConfig)
			.then(() => {
				const { _value } = getValue(remoteConfig, `${key}`);
				const parsedValue = JSON.parse(_value);
				setConfig(parsedValue);
				localStorage.setItem([storageKey || key], JSON.stringify(parsedValue));
			})
			.catch((err) => {
				console.error(err);
			});
	}, [key, storageKey]);

	useEffect(() => {
		fetchRemoteConfig();
	}, [fetchRemoteConfig]);

	return { config };
};

export default useRemoteConfig;
