import * as types from "./disputeTypes";

const initialState = {
  isLoading: false,
  jiraAuthCode: "",
  jiraAccessToken: "",
  searchResult: [],
  searchParams: {},
  otherDetails: [],
  disputeDetails: {},
  jiraTicketID: undefined,
  bulkStatusUpdateFile: {},
  jiraComments: [],
  reConnectToJira: false,
  uploadBulkRefundData: null,
  uploadBulkRefundColumnHeaders: [],
  uploadBulkRefundErrorRows: [],
  uploadBulkRefundErrorRowsSN: [],
	searchFraudResult: null,
	searchFraudResultDetail: null
};

const disputeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.RECONNECT_TO_JIRA:
      return {
        ...state,
        reConnectToJira: payload,
      };
    case types.SEARCH_PARAMS:
      return {
        ...state,
        searchParams: payload,
      };
    case types.JIRA_AUTH_CODE:
      return {
        ...state,
        jiraAuthCode: payload,
      };
    case types.JIRA_ACCESS_TOKEN:
      return {
        ...state,
        isLoading: false,
        jiraAccessToken: payload,
      };
    case types.DISPUTE_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.DISPUTE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case types.GET_JIRA_COMMENTS:
      return {
        ...state,
        jiraComments: payload,
      };

    case types.GET_JIRA_ID:
      return {
        ...state,
        jiraTicketID: payload,
      };
    case types.DISPUTE_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case types.GET_ALL_DISPUTES:
      return {
        ...state,
        isLoading: false,
        searchResult: payload,
      };
			case types.GET_ALL_DISPUTES_FRAUD:
			return {
				...state,
				isLoading: false,
				searchFraudResult: payload,
			};
			case types.GET_DISPUTE_FRAUD_DETAIL:
				return {
					...state,
					isLoading: false,
					searchFraudResultDetail: payload,
				};
    case types.DISPUTE_COUNT:
      return {
        ...state,
        isLoading: false,
        totalRecord: payload,
      };
    case types.GET_TRANSACTION_DETAILS:
      return {
        ...state,
        isLoading: false,
        otherDetails: payload,
      };
    case types.GET_DISPUTE_DETAILS:
      return {
        ...state,
        isLoading: false,
        disputeDetails: payload,
      };
    case types.UPLOAD_BULK_DISPUTE_STATUS:
      return {
        ...state,
        isLoading: false,
        bulkStatusUpdateFile: payload,
      };
    case types.UPLOAD_BULK_REFUND_FILE:
      return {
        ...state,
        uploadBulkRefundData: payload.uploadBulkRefundData,
        uploadBulkRefundColumnHeaders:
          payload.uploadBulkRefundColumnHeaders,
					uploadBulkRefundErrorRows:
          payload.uploadBulkRefundErrorRows,
					uploadBulkRefundErrorRowsSN:
          payload.uploadBulkRefundErrorRowsSN,
      };
    default:
      return state;
  }
};

export default disputeReducer;
