import * as types from "./loanTypes";

const initialState = {
  isLoading: false,
  moduleRequests: [],
};

const loanReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.LOAN_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.LOAN_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case types.LOAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case types.GET_ALL_LOAN_REQUESTS:
      return {
        ...state,
        isLoading: false,
        moduleRequests: payload,
      };
    default:
      return state;
  }
};

export default loanReducer;
