/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "context/AuthContext";
import { useIdleTimer } from "react-idle-timer";
import InactivityModal from "components/misc/InactivityModal";
import SessionTimeoutModal from "components/misc/SessionTimeoutModal";
import Toast from "library/Toast";
import moment from "moment";
import { useHistory } from "react-router-dom";

const TimeOut = () => {
  const history = useHistory();
  const [timeLeft, setTimeLeft] = useState(0);
  const [idleTimeLeft, setIdleTimeLeft] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showSessionTimeoutModal, setShowSessionTimeoutModal] = useState(false);
  const [timeoutModalShown, setTimeoutModalShown] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const context = useContext(AuthContext);
  const blockTime = 5;
  const sessionTime = Math.abs(
    moment().diff(new Date(localStorage.getItem("KudaAppTokenExpire")))
  );

  const supportedEvents = [
    "mousemove",
    "keydown",
    "wheel",
    "DOMMouseScroll",
    "mousewheel",
    "mousedown",
    "touchstart",
    "touchmove",
    "MSPointerDown",
    "MSPointerMove",
    // "visibilitychange",
    "focus",
  ];

  const activationFn = () => {
    activate();
  };

  // Function to call activate on user interaction detection
  const reactivateUser = () => {
    // Loop through each event
    supportedEvents.forEach((event) => {
      // If user performs any of the specified events, call activate()
      window.addEventListener(event, activationFn);
    });
  };

  // Function to deactivate events on user interaction detection
  const deactivateEventsOnInteraction = () => {
    // Loop through each event
    supportedEvents.forEach((event) => {
      // If user performs any of the specified events, call activate()
      window.removeEventListener(event, activationFn);
    });
  };

  const onIdlePrompt = () => {
    setShowModal(true);
    deactivateEventsOnInteraction();
  };

  const logout = () => {
    history.push("/dashboard");
    localStorage.clear();
    sessionStorage.clear();
    context.logout();
    window.location.reload();
  };

  // Timer for inactivity
  const { activate, ...idleTimer } = useIdleTimer({
    onIdle: logout,
    onPrompt: onIdlePrompt,
    timeout: 1000 * 60 * 15,
    promptBeforeIdle: 1000 * 60 * blockTime, // Time to wait
    events: supportedEvents,
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: "idle-timer",
    syncTimers: 0,
    leaderElection: false,
  });

  const onPrompt = () => {
    setShowSessionTimeoutModal(true);
    setTimeoutModalShown(true);
  };
  // const twoMinsBeforeAnhour = 3.3333333333333333
  const fiveMinsBeforeAnhour = 8.3333333333333333;
  // Timer for session/token expiry
  const { getRemainingTime } = useIdleTimer({
    onIdle: logout,
    // onPrompt,
    timeout: sessionTime,
    // promptBeforeIdle: 300000,
    // promptBeforeIdle: Math.round(1000 * 60 * 5),
    events: [],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: "session-timer",
    syncTimers: 0,
    leaderElection: false,
  });

  useEffect(() => {
    const timeInterval = setInterval(() => {
      setTimeLeft(Math.ceil(getRemainingTime() / 1000));
    }, 500);
    window.onbeforeunload = function () {
      localStorage.setItem("sessionTime", getRemainingTime().toString());
    };
    return () => clearInterval(timeInterval);
  }, []);

  useEffect(() => {
    // console.log(timeLeft);
    if (timeLeft === 300) {
      setShowSessionTimeoutModal(true);
    }
  }, [timeLeft]);

  //
  useEffect(() => {
    const timeInterval = setInterval(() => {
      setIdleTimeLeft(Math.ceil(idleTimer.getRemainingTime() / 1000));
    }, 500);
    // Invoke reactivate function once
    // reactivateUser();
    return () => clearInterval(timeInterval);
  }, []);

  const handleClose = () => {
    setShowToast(true);
  };

  return (
    <>
      {showModal && (
        <InactivityModal
          showModal={showModal}
          setShowModal={setShowModal}
          isLoading={false}
          handleSuccess={() => logout()}
          timeLeft={idleTimeLeft}
          blockTime={blockTime}
          onDismiss={() => reactivateUser()}
        />
      )}
      {showSessionTimeoutModal && timeoutModalShown === false && (
        <SessionTimeoutModal
          showModal={showModal}
          setShowModal={setShowSessionTimeoutModal}
          onClose={() => {
            handleClose();
            setTimeoutModalShown(true);
          }}
          isLoading={false}
          // handleSuccess={() => logout()}
          timeLeft={timeLeft}
        />
      )}

      <Toast
        open={showToast}
        message={`Timeout in ${Math.floor(timeLeft / 60)
          .toString()
          .padStart(2, "0")}:${(timeLeft % 60).toString().padStart(2, "0")}`}
        removeToast={() => setShowToast(false)}
        autoDismiss={false}
      />
    </>
  );
};

export default TimeOut;
