import { CookieManager } from "helper/cookies";
import moment from "moment";

export const sessionInfo = {
  KudaAppToken: "KudaAppToken",
  KudaAppRefreshToken: "KudaAppRefreshToken",
  KudaAppUserData: "KudaAppUserData",
  KudaAppGoogleData: "KudaAppGoogleData",
  KudaAppTokenExpire: "KudaAppTokenExpire",
};

export const finishAuth = (
  token,
  userData,
  expires_at,
  googleProfile,
  refreshToken
) => {
  try {
    const domain = process.env.REACT_APP_SUITE_CLIENT_DOMAIN;
    const cookieManager = new CookieManager();
    cookieManager.createCookie(sessionInfo.KudaAppToken, token, domain);
    // localStorage.setItem(sessionInfo.KudaAppToken, token);
    cookieManager.createCookie(
      sessionInfo.KudaAppRefreshToken,
      refreshToken,
      domain
    );
    // localStorage.setItem(sessionInfo.KudaAppRefreshToken, refreshToken);
    localStorage.setItem(sessionInfo.KudaAppUserData, JSON.stringify(userData));

    const currentDate = new Date();

    // Calculate the time 60 minutes from now
    const futureDate = new Date(
      currentDate.getTime() + Number(expires_at) * 60 * 1000
    ); // 60 minutes * 60 seconds * 1000 milliseconds

    localStorage.setItem(sessionInfo.KudaAppTokenExpire, futureDate);
    localStorage.setItem(
      sessionInfo.KudaAppGoogleData,
      JSON.stringify(googleProfile)
    );
  } catch (error) {
    console.log(error);
  }
};

export const getToken = () => {
  const cookieManager = new CookieManager();
  // alert(cookieManager.getCookie(sessionInfo.KudaAppToken));
  // let token = localStorage.getItem(sessionInfo.KudaAppToken);
  let token = cookieManager.getCookie(sessionInfo.KudaAppToken);
  if (token && !isSessionExpired()) {
    return token;
  }
  return null;
};

export const getRefreshToken = () => {
  const cookieManager = new CookieManager();
  // let r_token = localStorage.getItem(sessionInfo.KudaAppRefreshToken);
  let r_token = cookieManager.getCookie(sessionInfo.KudaAppRefreshToken);
  if (r_token && !isSessionExpired()) {
    return r_token;
  }
  return null;
};

export const getUserData = () => {
  let userData = localStorage.getItem(sessionInfo.KudaAppUserData);
  if (userData) {
    return JSON.parse(userData);
  }
  return null;
};

export const getGoogleProfile = () => {
  let googleProfile = localStorage.getItem(sessionInfo.KudaAppGoogleData);
  if (googleProfile) {
    return JSON.parse(googleProfile);
  }
  return null;
};

export const deleteToken = () => {
  const cookieManager = new CookieManager();
  localStorage.clear();

  cookieManager.deleteCookies([
    sessionInfo.KudaAppToken,
    sessionInfo.KudaAppRefreshToken,
  ]);
  //   localStorage.removeItem("KudaAppToken");
  //   localStorage.removeItem("KudaAppUserData");
  //   localStorage.removeItem("KudaAppTokenExpire");
  //   localStorage.removeItem("KudaAppGoogleData");
};

export const isSessionExpired = () => {
  const expire = localStorage.getItem(sessionInfo.KudaAppTokenExpire);
  if (!expire) {
    return true;
  } else {
    return moment(expire).isBefore(moment());
  }
};
