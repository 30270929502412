import React from 'react'
import Main from 'layout/Main'
import HomeModuleCard from 'components/HomeModuleCard'
import { StyledFraudHome } from '../shared/styles/home.styles'
import { mainHeaderLink, paths } from '../HeaderLinks'
import { useHistory } from "react-router-dom";
import { ManageMerchantIcon, FraudHomeBaseIcon } from '../shared/icons'

const FraudManagementHome = () => {
	const history = useHistory();

	const terminalManagementItems = [
		{
			icon: FraudHomeBaseIcon,
			head: "Manage Flagged Transactions",
			description: "Review flagged transactions on the internal team queue",
			handleClick: () => history.push(paths.manageFlaggedTransactions),
			isActive: true,
		},
		{
			icon: FraudHomeBaseIcon,
			head: "Fraud Transaction Report",
			description: "View all customer’s flagged transactions.",
			handleClick: () => history.push(paths.fraudTransactionsReport),
			isActive: true,
		}
	]

	return (
		<Main mainRoute moduleName="Fraud Management" links={mainHeaderLink}>
			<StyledFraudHome>
				<HomeModuleCard items={terminalManagementItems} />
			</StyledFraudHome>
		</Main>
	)
}

export default FraudManagementHome