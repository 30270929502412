import React from "react";
import Icon from "../../library/Icons/Icon";
import { ArrowRight } from "../SvgPack";
import PropTypes from "prop-types";

const CardItem = ({ icon, head, description, handleClick, isActive, enabled }) => {
  return (
    <div
      className={`card${!enabled ? " disabled" : ""}`}
      onClick={() => enabled && handleClick()}
      style={{
        opacity: enabled ? 1 : 0.6,
        cursor: enabled ? "pointer" : "not-allowed",
      }}
    >
      {/* grayed out overlay if not active */}
      {!isActive ? <div className="greyed-out"></div> : null}
      <div className="left-icon">
        <Icon svg={icon} />
      </div>
      <div className="content">
        <h2>{head}</h2>
        <p>{description}</p>
      </div>
      <div className="right-icon">
        <Icon svg={ArrowRight} />
      </div>
    </div>
  );
};

CardItem.defaultProps = {
  enabled: true,
};

CardItem.propTypes = {
  enabled: PropTypes.bool,
};

export default CardItem;
