import styled from "styled-components";
import breakpoint from "../../library/breakpoint";

export const StyledSideBar = styled.div`
    position: fixed;
    top: 0;
    height: 100vh;
    width: ${(props) => props.sideBarWidth};
    padding: 70px 0px 0px;
    background-color: #f9f9f9;
    overflow-y: scroll;
    z-index: 1;

    &::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    & {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }

    :after {
        content: "";
    }

    transition: transform 0.6s;

    @media ${breakpoint.device.tablet} {
        transform: translateX(-${(props) => (props.sideBarWidth ? props.sideBarWidth : "")});
        transform: translateX(${(props) => (props.mobileNavBar ? "0" : "")});
        z-index: 99990;
    }

    .userinfo {
        padding: 20px 15px;
        background: #f9f9f9;
        border-bottom: 1px solid rgb(219 220 224 / 40%);
        position: sticky;
        top: 0px;
        z-index: 20;
    }

    .userinfo__nav {
        display: flex;
        background: #ffffff;
        box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.06);
        border-radius: 6px;
        padding: 13px;
    }

    .userinfo__nav-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 10px;

        h4 {
            font-weight: bold;
            font-size: 15px;
            display: flex;
            align-items: center;
            color: #000000;
            margin-bottom: 5px;
            display: block;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 162px;
            overflow: hidden;
        }

        p {
            font-weight: 900;
            font-size: 13px;
            color: #40196d;
						width: fit-content;
        }
    }

    hr {
        color: #dbdce0;
        opacity: 0.1;
    }
`;

export const StyledBurger = styled.button`
    display: none;
    @media ${breakpoint.device.tablet} {
        display: block;
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        flex-direction: column;
        justify-content: space-around;
        width: 16px;
        height: 20px;
        background: transparent;
        border: none;
        cursor: pointer;
        padding: 0;
        z-index: 10;
        display: flex;

        &:focus {
            outline: none;
        }
        div {
            width: 21px;
            height: 2px;
            right: 0px;
            background-color: #fff;
            border-radius: 8px;
            transition: all 0.3s linear;
            position: relative;
            transform-origin: 3px;

            :first-child {
                transform: ${({ isOpen }) => (isOpen ? "rotate(45deg)" : "rotate(0)")};
            }

            :nth-child(2) {
                transform: ${({ isOpen }) => (isOpen ? "rotate(-45deg)" : "rotate(0)")};
            }
        }
    }
`;
