import React from "react";
import Loader from "library/Loader";
import { useState, useEffect } from "react";
import { Img } from "react-image";
import heic2any from "heic2any";
import Overlay from "./overlay";
import {
  isGoogleDocsSupportedFile,
  isHeicHeifFile,
  isImageFile,
  isOfficeDocsFile,
  isUnsupportedFile,
} from "./utils";
import Skeleton from "react-loading-skeleton";
import UnsupportedFileModal from "./UnsupportedFileModal";
import UnsupportedFileUrlModal from "./UnsupportedFileUrlModal";
import FileLoadingModal from "./FileLoadingModal";
import FileDoesNotExistModal from "./FileDoesNotExistModal";

export const ViewerWithoutOverlay = ({
  docUrl,
  embedded = false,
  onLoad,
  onError,
}) => {
  const [loading, setLoading] = useState(true);
  const [isContentLoaded, setIsContentLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [convertedUrl, setConvertedUrl] = useState(null);

  const frameId = "frame-" + Math.round(Date.now() * Math.random());

  const convertHeicHeifToJpeg = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const convertedBlob = await heic2any({ blob, toType: "image/jpeg" });
      const convertedUrl = URL.createObjectURL(convertedBlob);
      setConvertedUrl(convertedUrl);
      setLoading(false);
    } catch (error) {
      setError(true);
      setLoading(false);
      console.error("Error converting .heic file:", error);
    }
  };

  useEffect(() => {
    if (docUrl) {
      setLoading(true);
      setError(false);
      setConvertedUrl(null);
      if (isHeicHeifFile(docUrl)) {
        convertHeicHeifToJpeg(docUrl);
      } else {
        setLoading(false);
      }
    }
  }, [docUrl]);

  const renderFile = (url) => {
    const handleEmbedLoad = (event) => {
      const embedElementId = event.target.id;
      const embedElement = document.querySelector(`#${embedElementId}`);
      // console.log(embedElement);
      const interval = setInterval(() => {
        try {
          if (embedElement.contentWindow) {
            clearInterval(interval);
            setIsContentLoaded(true);
          }
        } catch (error) {
          clearInterval(interval);
          setIsContentLoaded(true); // Show content if there is an error checking readyState
          console.error("Error checking embed load state:", error);
        }
      }, 100);
    };

    if (isOfficeDocsFile(url) || isGoogleDocsSupportedFile(url)) {
      const viewerLink = isOfficeDocsFile(url)
        ? `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
            url
          )}`
        : `https://docs.google.com/viewer?embedded=true&chrome=false&url=${encodeURIComponent(
            url
          )}`;

      return (
        <>
          {!isContentLoaded && !embedded && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <FileLoadingModal fileUrl={url} />
            </div>
          )}
          <iframe
            key={frameId + url}
            title="Uploaded Document"
            src={viewerLink}
            style={{
              height: "100%",
              width: "100%",
              borderRadius: "5px",
              display: isContentLoaded ? "block" : "none",
            }}
            onLoad={handleEmbedLoad}
            onError={() => {
              setError(true);
              setLoading(false);
            }}
            id={frameId}
          />
        </>
      );
    } else if (isImageFile(url) || (isHeicHeifFile(url) && convertedUrl)) {
      return (
        <Img
          src={isHeicHeifFile(url) ? convertedUrl : url}
          alt="Uploaded Document"
          style={{ height: "100%", width: "100%", borderRadius: "5px" }}
          onLoad={() => setLoading(false)}
          onError={() => setError(true)}
        />
      );
    } else if (isUnsupportedFile(url) || !isGoogleDocsSupportedFile(url)) {
      return (
        <div>
          <p>
            Unsupported file type for direct viewing. You can download it using
            the link below:
          </p>
          <a href={url} download>
            Download File
          </a>
        </div>
      );
    } else {
      return <div>Unsupported file type.</div>;
    }
  };

  // useEffect(() => {
  //   const docFrame = document.querySelector(`#${frameId}`);
  //   docFrame?.contentDocument?.location?.replace(docFrame?.src ?? "");
  // }, [frameId]);

  // useEffect(() => {
  //   const docFrame = document.querySelector(`#${frameId}`);
  //   // console.log(docFrame?.contentDocument.readyState);
  //   let interval = setInterval(() => {
  //     // docFrame.contentWindow.window.location.set();
  //     if (docFrame?.contentWindow?.document?.URL === "about:blank") {
  //       docFrame?.contentWindow?.location?.replace(docFrame?.src ?? "");
  //       clearInterval(interval);
  //     }
  //   }, 500);

  //   return () => clearInterval(interval);
  // }, [docUrl]);

  return (
    <div
      style={
        embedded === false
          ? { height: "calc(100vh - 131px)", width: "calc(100vw - 131px)" }
          : { height: "100%", width: "100%" }
      }
    >
      {Array.isArray(docUrl) ? (
        docUrl.map((url, index) => (
          <div
            key={index}
            style={{ height: "100%", width: "100%", color: "white" }}
          >
            {loading && !embedded ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Loader variant={"white"} />
                <span style={{ color: "#fff" }}>Loading document...</span>
              </div>
            ) : loading && embedded ? (
              <Skeleton
                height={"100%"}
                width={"100%"}
                style={{ borderRadius: 5 }}
              />
            ) : null}
            {error && <div>Error loading document. Please try again.</div>}
            {renderFile(url)}
          </div>
        ))
      ) : (
        <div style={{ height: "100%", width: "100%", color: "white" }}>
          {loading && !embedded ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Loader variant={"white"} />
              <span style={{ color: "#fff" }}>Loading document...</span>
            </div>
          ) : loading && embedded ? (
            <Skeleton
              height={"100%"}
              width={"100%"}
              style={{ borderRadius: 5 }}
            />
          ) : null}
          {error && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              Error loading document. Please try again.
            </div>
          )}
          {renderFile(docUrl)}
        </div>
      )}
    </div>
  );
};

const MultiDocumentViewer = ({
  showDocs,
  setShowDocs = () => {},
  docUrl,
  title = "Supporting Document",
}) => {
  const checkFileSupported = (docUrl = "") => {
    if (
      isOfficeDocsFile(docUrl) ||
      isGoogleDocsSupportedFile(docUrl) ||
      isImageFile(docUrl) ||
      isHeicHeifFile(docUrl)
    ) {
      return true;
    }

    return false;
  };

  const isValidUrl = (urlString) => {
    var urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // validate protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // validate fragment locator
    return !!urlPattern.test(urlString);
  };

  const verifyDocList = (docList) => {
    return Array.isArray(docList)
      ? docList.some((doc) => checkFileSupported(doc))
      : checkFileSupported(docList);
  };

  const checkIfDocListIsEmpty = (docList) => {
    if (Array.isArray(docList)) {
      return docList.length <= 0;
    }

    if (typeof docList === "string") {
      return Boolean(docList.trim()) === false;
    }
  };

  const verifyIsUrl = (docList) => {
    return Array.isArray(docList)
      ? docList.some((doc) => isValidUrl(doc))
      : isValidUrl(docList);
  };

  const createDownloadUrl = (url) => {
    const a = document.createElement("a");
    a.href = url;
    a.target = "_";
    a.download = "Supporting_Doc_" + new Date().getTime();
    a.click();
    a.remove();
  };

  const handleDownload = () => {
    if (Array.isArray(docUrl)) {
      docUrl.forEach((url) => {
        createDownloadUrl(url);
      });
    } else {
      createDownloadUrl(docUrl);
    }
  };

  const filteredDocUrls = Array.isArray(docUrl)
    ? docUrl.filter((doc) => checkFileSupported(doc))
    : docUrl;

  return (
    showDocs &&
    (verifyDocList(docUrl) ? (
      <Overlay name={title} setShowDocs={setShowDocs}>
        <ViewerWithoutOverlay
          docUrl={filteredDocUrls}
          key={Math.round(Date.now() * Math.random())}
        />
      </Overlay>
    ) : checkIfDocListIsEmpty(docUrl) ? (
      <FileDoesNotExistModal
        showDocs={showDocs}
        setShowModal={setShowDocs}
        docUrl={filteredDocUrls}
      />
    ) : !verifyDocList() && verifyIsUrl(docUrl) ? (
      <UnsupportedFileModal
        showDocs={showDocs}
        setShowModal={setShowDocs}
        onDownload={handleDownload}
        docUrl={filteredDocUrls}
      />
    ) : (
      <UnsupportedFileUrlModal
        showDocs={showDocs}
        setShowModal={setShowDocs}
        docUrl={filteredDocUrls}
      />
    ))
  );
};
// FileDoesNotExistModal
export default MultiDocumentViewer;
