import styled from "styled-components";

export const StyledActionModal = styled.div`
  animation: fadeInUp 0.5s forwards;
  background-color: #ffff;
  padding: 25px;
  width: 416px;

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(30px);
    }

    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  .card-content {
    & > * {
      font-family: inherit;
      box-sizing: border-box;
    }

    .divider {
      height: 1px;
      background-color: #dbdce0;
      margin-block: 10px 18px;
    }

    .select-container {
      display: flex;
      flex-direction: column;
      gap: 25px 0;
      margin-block: 25px 10px;
    }

    .footer {
      margin-top: 20px;
    }

    .header {
      display: flex;
      padding: 0 0 15px;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #dbdce0;

      .cancel {
        cursor: pointer;
      }

      p {
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 25px;
        color: #000000;
      }
    }

    .title {
      padding: 20px 0;

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #000000;
      }
    }
  }

  footer {
    display: flex;
    justify-content: center;
    column-gap: 20px;
    padding: 20px 0 0;

    button {
      flex: 1;
    }
  }
`;

export const StyledEditAggregatorModal = styled.div`
  animation: fadeInUp 0.5s forwards;
  background-color: #ffff;
  padding: 25px;
  width: 720px;

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(30px);
    }

    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  .card-content {
    & > * {
      font-family: inherit;
      box-sizing: border-box;
    }

    .divider {
      height: 1px;
      background-color: #dbdce0;
      margin-block: 10px 18px;
    }

    .select-container {
      display: flex;
      flex-direction: column;
      gap: 25px 0;
      margin-block: 25px 10px;
    }

    .footer {
      margin-top: 20px;
    }

    .header {
      display: flex;
      padding: 0 0 15px;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #dbdce0;

      .cancel {
        cursor: pointer;
      }

      p {
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 25px;
        color: #000000;
      }
    }

    .title {
      padding: 20px 0;

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #000000;
      }
    }
  }

  footer {
    display: flex;
    justify-content: center;
    column-gap: 20px;
    padding: 30px 0 0;
  }
`;

export const StyledSuccessModal = styled.div`
  width: 415px;
  max-width: 100%;
  height: auto;
  background: #ffffff;
  border: 1px solid rgba(219, 220, 224, 0.5);
  box-shadow: 15px 30px 40px rgba(64, 25, 109, 0.07);
  border-radius: 6px;
  padding: 46px 40px;
  display: flex;
  flex-direction: column;
  animation: fadeInUp 0.5s forwards;

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(30px);
    }

    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  .card-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    h2 {
      font-style: normal;
      font-weight: 800;
      font-size: 20px;
      line-height: 25px;
      text-align: center;
      color: #000000;
    }

    .icon {
      margin: 44px 0px;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      text-align: center;
      color: #000000;
      margin-bottom: 30px;

      span {
        font-weight: 800;
      }
    }

    .error-message {
      margin-bottom: 20px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #000000;
    }
  }

  .ticket-title {
    margin-top: 10px;
    font-style: normal;
    font-weight: 800;
    font-size: 13px;
    line-height: 18px;
    color: #000000;
  }

  .ticket-box {
    background: #eff1ff;
    display: flex;
    width: 236.3px;
    margin-inline: auto;
    justify-content: space-between;
    padding: 16px 13px;
    margin: 10px 0 30px;
    border-radius: 5px;

    .ticket-id {
      font-style: normal;
      font-weight: 900;
      font-size: 13px;
      line-height: 25px;
      color: #40196d;
    }

    .icon-box {
      display: flex;
      column-gap: 5px;
      align-items: center;
      cursor: pointer;
      min-height: 29px;

      .text {
        font-style: normal;
        font-weight: 700;
        font-size: 11px;
        line-height: 13px;
        color: #40196d;
      }
    }
  }

  footer {
    display: flex;
    height: 48px;
    gap: 20px;
    justify-content: center;
    margin-top: 20px;

    button {
      width: 145px;
    }
  }
`;
