import styled from "styled-components";
import breakpoint from "../../library/breakpoint";

export const StyledMain = styled.main`
  position: absolute;
  left: ${(props) => /* props?.sideBarWidth || */ "264px"};
  height: 100vh;
  overflow-y: auto;
  width: calc(100% - ${(props) => /* props?.sideBarWidth || */ "264px"});
  padding: 70px 30px;
  transition: left 0.6s;

  @media only screen and (max-width: 920px) {
    left: ${(props) => props?.sideBarWidth || "0px"};
    width: 100%;
    padding: 70px 16px;

    .mobile-is--column {
      flex-direction: column;
    }
    .mobile-is--flex---start {
      align-items: flex-start !important;
    }
  }

  .main-header {
    padding-top: 35px;
    position: sticky;
    top: 0;
    background: #ffffff;
    margin-left: -30px;
    margin-right: -30px;
    padding-left: 30px;
    padding-right: 30px;
    z-index: 9999;
    margin-bottom: 15px;

    @media ${breakpoint.device.tablet} {
      margin-left: -16px;
      margin-right: -16px;
      padding-left: 16px;
      padding-right: 16px;
    }
    .main-header--text {
      font-style: normal;
      font-weight: 900;
      font-size: 20px;
      line-height: 25px;
      color: #121212;
      text-transform: capitalize;
    }

    .main-header--text.subRoute {
      // margin-bottom: 30px;
    }
  }

  .main-header__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }

  .tab-nav {
    .tab-nav__list {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .cursor-pointer {
        cursor: pointer;
      }
    }

    .tab-nav__icon {
      margin: 0 9px;
    }
  }

  .main-header__actions.subRoute {
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .subRoute-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${breakpoint.device.tablet} {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 16px;
    }
  }

  .actions {
    display: flex;
    align-items: center;
  }

  .nav-link {
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    text-decoration: none;
    color: #000000;
    padding-bottom: 6px;
  }

  .nav-link:not(:first-child) {
    margin-left: 25px;
  }

  .nav-link__active {
    border-bottom: 2px solid #40196d;
  }

  hr {
    margin-top: 20px;
    opacity: 0.6;
    border: 0.5px solid #dbdce0;
  }

  .subRoute {
    // margin-top: 30px;
  }

  .review {
    margin-left: 0px;
  }
`;

export const ModifiedStyledMain = styled.main`
  position: relative;
  left: ${(props) => /* props?.sideBarWidth || */ "264px"};
  width: calc(100% - ${(props) => /* props?.sideBarWidth || */ "264px"});
  padding: 70px 30px;
  min-height: ${(p) => (p.fullScreen ? "100vh" : "auto")};
  transition: left 0.6s;
  @media only screen and (max-width: 920px) {
    left: ${(props) => props?.sideBarWidth || "0px"};
    width: 100%;
    padding: 70px 16px 40px;
  }
  @media only screen and (max-width: 450px) {
    padding: 70px 16px 16px;
  }

  .main-header {
    padding-top: 35px;
    background-color: ${(props) => (props.isSticky ? `#fff` : "transparent")};
    position: ${(props) => (props.isSticky ? `fixed` : "static")};
    top: ${(props) => (props.isSticky ? `70px` : "0px")};
    z-index: ${(props) => (props.isSticky ? `10` : "0")};
    width: 100%;

    .main-header--text {
      font-style: normal;
      font-weight: 900;
      font-size: 20px;
      line-height: 25px;
      color: #121212;
      text-transform: capitalize;
    }

    .main-header--text.subRoute {
      // margin-bottom: 30px;
    }
  }

  .main-children {
    padding-top: ${(props) => (props.isSticky ? `70px` : "0px")};
  }

  .main-header__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }

  .tab-nav {
    .tab-nav__list {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .tab-nav__icon {
      margin: 0 9px;
    }
  }

  .main-header__actions.subRoute {
    margin-top: 0px;
    padding-bottom: 10px;
  }

  .subRoute-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${breakpoint.device.tablet} {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 16px;
    }
  }

  .actions {
    display: flex;
    align-items: center;
  }

  .nav-link {
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    text-decoration: none;
    color: #000000;
    padding-bottom: 6px;
  }

  .nav-link:not(:first-child) {
    margin-left: 25px;
  }

  .nav-link__active {
    border-bottom: 2px solid #40196d;
  }

  hr {
    margin-top: 20px;
    opacity: 0.6;
    border: 0.5px solid #dbdce0;
  }

  .subRoute {
    // margin-top: 30px;
  }

  .review {
    margin-left: 15px;
  }
`;

export const CustomModifiedStyledMain = styled.main`
  position: relative;
  left: ${(props) => /*props?.sideBarWidth || */ "264px"};
  width: calc(100% - ${(props) => /*props?.sideBarWidth || */ "264px"});
  padding: 70px 30px;
  transition: left 0.6s;

  @media only screen and (max-width: 920px) {
    left: ${(props) => props?.sideBarWidth || "0px"};
    width: 100%;
    padding: 70px 16px;
  }

  .main-header {
    padding: 35px 0px 10px;
    position: sticky;
    top: 70px;
    background: #fff;
    z-index: 90;

    .main-header--text {
      font-style: normal;
      font-weight: 900;
      font-size: 20px;
      line-height: 25px;
      color: #121212;
      text-transform: capitalize;
    }

    .main-header--text.subRoute {
      // margin-bottom: 0px;
    }
  }

  .main-header__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .tab-nav {
    .tab-nav__list {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .tab-nav__icon {
      margin: 0 9px;
    }
  }

  .main-header__actions.subRoute {
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .subRoute-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${breakpoint.device.tablet} {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 16px;
    }
  }

  .actions {
    display: flex;
    align-items: center;
  }

  .nav-link {
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    text-decoration: none;
    color: #000000;
    padding-bottom: 6px;
  }

  .nav-link:not(:first-child) {
    margin-left: 25px;
  }

  .nav-link:last-child {
    color: #979797;
  }

  .nav-link__active {
    border-bottom: 2px solid #40196d;
  }

  hr {
    margin-top: 20px;
    opacity: 0.6;
    border: 0.5px solid #dbdce0;
  }

  .subRoute {
    // margin-top: 30px;
  }

  .review {
    margin-left: 15px;
  }

  .top-header {
    position: sticky;
    top: 150px;
    z-index: 90;
    background: #fff;
  }

  .sticky-divider {
    position: sticky;
    top: 200px;
    z-index: 90;
  }
`;
