import React from "react";
import { LessThan } from "../../components/SvgPack";
import {
  StyledCloseButton,
  StyledModalHeader,
  StyledTitle,
} from "./Modal.styles";

function ModalHeader({
  modalTitle,
  closeModal,
  disableClickOutside,
  bckBtn,
  backAction,
  showCancelIcon = true,
}) {
  return (
    <StyledModalHeader>
      <StyledTitle>
        {bckBtn && (
          <div className="icon" onClick={backAction}>
            <LessThan />
          </div>
        )}
        <h3>{modalTitle}</h3>
      </StyledTitle>
      {showCancelIcon && (
        <StyledCloseButton onClick={closeModal}>&times;</StyledCloseButton>
      )}
    </StyledModalHeader>
  );
}

export default ModalHeader;
