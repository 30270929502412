import * as types from "./bankMgmtTypes";

const initialState = {
  isLoading: false,
  banks: [],
  isLoadingBanks: false,
  uploadBulkBanksColumnHeaders: [],
  uploadBulkBanksErrorRows: [],
  uploadBulkBanksErrorRowsSN: [],
  uploadBulkBanksData: null,
  modifiedBanks: {},
};

const bankManagementReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.BANK_MGMT_START:
      return { ...state, isLoading: true };
    case types.BANK_MGMT_SUCCESS:
      return { ...state, isLoading: false };
    case types.BANK_MGMT_FAIL:
      return { ...state, isLoading: false };
    case types.GET_BULK_CREATE_BANK_FILE_INFO:
      return {
        ...state,
        isLoading: false,
        uploadBulkBanksColumnHeaders: payload.uploadBulkBanksColumnHeaders,
        uploadBulkBanksErrorRows: payload.uploadBulkBanksErrorRows,
        uploadBulkBanksErrorRowsSN: payload.uploadBulkBanksErrorRowsSN,
        uploadBulkBanksData: payload.uploadBulkBanksData,
      };
    case types.ULPOAD_BANK_LIST:
      return { ...state, banks: payload };

    case types.ULPOAD_MODIFIED_BANKS:
      return {
        ...state,
        modifiedBanks: payload,
      };
    default:
      return state;
  }
};

export default bankManagementReducer;
