import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  useTable,
  usePagination,
  useAsyncDebounce,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useRowSelect,
} from "react-table";
import { TableStyle } from "./Table.styles";
import TableCheckbox from "./TableCheckBox";
import Skeleton from "../../components/Skeleton";
import TableLoadMore from "./TableLoadMore";
import TableHead from "./TableHead";
import TableBody from "./TableBody";
import TableFooter from "./TableFooter";
import TableFilter from "./TableFilter";
import TableCustomCheckBox from "./TableCustomCheckBox";
function Table({
  columns,
  data,
  hasPagination,
  hasFilter,
  isLoading,
  width,
  height,
  searchFieldPlaceholder,
  showCheckBoxes,
  onRowSelect,
  requestURL,
  loaderCount,
  totalRecords = null,
  getRecords = null,
  defaultTableSize = 100,
  noResultFoundExtra,
  hideDefaultFilters = false,
  showCustomFilters = false,
  renderHeaderJsx = {},
  renderCustomFilters = () => <div className="mb-2" />,
  customFilterComponent = <></>,
  customEmptyState,
  searchHeight,
  noSearchError,
  hideSearchField = false,
  showOnlyTableSizeDropdown = false,
  errorRowsSN = [],
  selectFieldLowerHeight = false,
  effectPageToDefault = false,
  currentPageIndex = 0,
  provideTableUpdate = () => {},
  customErrorMessage,
  disableKey,
  customEmptyStateIcon,
  //for the search above the table for server side search
  setSearchParam = undefined,
  // provideTableNavigation = (pageNumber = 0, gotoPage = (page = 0) => { }) => { },
  preSelectedRows,
  useTableCustomCheckBox = false,
  updatePageNumber = () => {},
  updatePageSize = () => {},
	noCapitalizeTd = false,
	// padding left for filer select button
	useNeutralPLButtonOnSelectFilter = false
}) {
  const [selectedRow, setSelectedRow] = useState({});
  const [tableSize, setTableSize] = useState(defaultTableSize);
  const isMounted = useRef(false);
  const isServerPaginated = totalRecords && totalRecords > data?.length;
  const [pageNum, setPageNum] = useState(0);

  const debounceLimit = setSearchParam === undefined ? 200 : 500;

  // SET SKELETON LOADER COUNT
  const tableData = useMemo(
    () => (isLoading ? Array(loaderCount ?? 10).fill({}) : data),
    [isLoading, data]
  );
  // SET DEFAULT PAGESIZE
  const tablePageSize = useMemo(() => {
    if (hasPagination) return tableSize;
    return data?.length ? data?.length : tableSize;
  }, [hasPagination, data]);
  const tableColumns = useMemo(
    () =>
      isLoading
        ? columns.map((column) => ({
            ...column,
            Cell: <Skeleton />,
          }))
        : columns,
    [isLoading, columns]
  );
  const renderTableCheckBoxes = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      {
        id: "selection",
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        Header: ({ getToggleAllPageRowsSelectedProps }) =>
          useTableCustomCheckBox ? (
            <TableCustomCheckBox {...getToggleAllPageRowsSelectedProps()} />
          ) : (
            <TableCheckbox {...getToggleAllPageRowsSelectedProps()} />
          ),
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: ({ row }) =>
          useTableCustomCheckBox ? (
            <TableCustomCheckBox {...row.getToggleRowSelectedProps()} />
          ) : (
            <TableCheckbox {...row.getToggleRowSelectedProps()} />
          ),
      },
      ...columns,
    ]);
  };
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    canPreviousPage,
    gotoPage,
    canNextPage,
    pageCount,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
    toggleRowSelected,
    // initialState: { pageIndex = 0, pageSize = 100 },
  } = useTable(
    {
      columns: tableColumns,
      data: tableData,
      initialState: {
        selectedRowIds: selectedRow?.[pageNum] ?? [],
        pageSize: tableSize,
        pageIndex: currentPageIndex,
      },
      manualPagination: isServerPaginated,
      pageCount:
        totalRecords && totalRecords > data?.length
          ? Math.ceil(totalRecords / tableSize)
          : Math.ceil(data?.length / tableSize),
      autoResetPage: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => (showCheckBoxes ? renderTableCheckBoxes(hooks) : null)
  );
  const [, setPages] = useState([]);
  const { targetHeader, jsx } = renderHeaderJsx;

  const preselectRows = () => {
    // Iterate over each entry in the preSelectedRows object
    Object.entries(preSelectedRows).forEach(([id, value]) => {
      // For each entry, toggle the selected state for each row
      Object.entries(value).forEach(([key, valueItem]) => {
        // Toggle the selected state for the row using toggleRowSelected
        toggleRowSelected(key, valueItem);
      });
    });
  };

  useEffect(() => {
    if (preSelectedRows) {
      preselectRows();
    }
  }, [preSelectedRows]);

  // defaults table to page one
  useEffect(() => {
    if (effectPageToDefault) {
      setPageNum(0);
      gotoPage(0);
    }
  }, [effectPageToDefault]);
  useEffect(() => {
    if (currentPageIndex) {
      setPageNum(currentPageIndex);
      gotoPage(currentPageIndex);
    }
    // provideTableNavigation(pageNum, gotoPage);
  }, [currentPageIndex]);
  useEffect(() => {
    provideTableUpdate(pageNum);
  }, [pageNum]);

  useEffect(() => {
    pageNumbers(pageCount, pageIndex + 1);
    if (isMounted.current && isServerPaginated) {
      // FETCH NEW PAGE
      getRecords && getRecords(pageSize, pageIndex + 1);
    } else {
      isMounted.current = true;
    }
  }, [pageIndex]);
  useEffect(() => {
    if (onRowSelect) {
      const filterData = selectedFlatRows.map((i) => i.original);
      setSelectedRow({ ...selectedRow, [pageIndex]: selectedRowIds });
      onRowSelect(filterData);
    }
  }, [selectedRowIds]);
  const onChange = useAsyncDebounce((value) => {
    if (setSearchParam === undefined) {
      setGlobalFilter(value || undefined);
    } else {
      setSearchParam(value);
    }
  }, debounceLimit);
  const pageNumbers = (count, current) => {
    var shownPages = 3;
    var result = [];
    if (count === 1) {
      result.push(count);
    } else if (current > count - shownPages) {
      result.push(count - 2, count - 1, count);
    } else {
      result.push(current, current + 1, current + 2, "...", count);
    }
    return setPages(result);
  };
  const handlePageSize = (val) => {
    setTableSize(val);
    setPageSize(val);
    updatePageSize(val);
  };
  return (
    <TableStyle height={height}>
      <div className="container">
        <div className="card-body">
          {hasFilter && !hideDefaultFilters && !showCustomFilters && !showOnlyTableSizeDropdown && (
            <TableFilter
              {...{
                width,
                pageSize,
                onChange,
                searchFieldPlaceholder,
                handlePageSize,
                customFilterComponent,
                searchHeight,
                noSearchError,
                hideSearchField,
                showOnlyTableSizeDropdown,
                selectFieldLowerHeight,
								useNeutralPLButtonOnSelectFilter
              }}
            />
          )}
          {hideDefaultFilters &&
            showCustomFilters &&
            renderCustomFilters(onChange, handlePageSize, pageSize)}
          <div className={`data-table${targetHeader && jsx ? " dropdown" : ""}`}>
            <table className="table" {...getTableProps()}>
              <TableHead
                headerGroups={headerGroups}
                onChange={onChange}
                renderHeaderJsx={renderHeaderJsx}
              />
              {page && page.length > 0 && (
                <TableBody
                  {...{
                    prepareRow,
                    getTableBodyProps,
                    page,
                    errorRowsSN,
                    disableKey,
										noCapitalizeTd
                  }}
                />
              )}
            </table>
          </div>
          {page && page.length === 0 && (
            <TableLoadMore
              customEmptyState={customEmptyState}
              customErrorMessage={customErrorMessage}
              requestURL={requestURL}
              CustomInfo={noResultFoundExtra}
              customEmptyStateIcon={customEmptyStateIcon}
            />
          )}
        </div>
        <div className="w-100">
          {hasPagination && page.length > 0 && (
            <TableFooter
              {...{
                data,
                page,
                totalRecords,
                pageIndex,
                gotoPage,
                pageSize,
                previousPage,
                canPreviousPage,
                nextPage,
                canNextPage,
                setPageIndexNumber: (index) => {
                  setPageNum(index);
                  updatePageNumber(index + 1);
                },
              }}
            />
          )}
        </div>
      </div>
    </TableStyle>
  );
}
Table.prototype = {};
Table.defaultProps = {
  hasFilter: true,
  hasPagination: true,
  searchHeight: undefined,
  noSearchError: false,
};
export default Table;
