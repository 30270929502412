/* eslint-disable no-unused-vars */
import { Component } from "react";
import Error from "./Error";

class ErrorBoundary extends Component {
  state = {
    error: null,
    errorInfo: null,
  };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  reload = () => {
    window.location.reload();
  };

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    // if (
    //   this.state.hasError &&
    //   !this.state.errorInfo?.componentStack?.includes("TimeOut")
    // ) {
    //   return <Error />;
    // }
    return this.props.children;
  }
}

export default ErrorBoundary;
