import React from 'react'
import Main from 'layout/Main'
import HomeModuleCard from 'components/HomeModuleCard'
import { StyledRemmitanceHome } from '../shared/styles/home.styles'
import { mainHeaderLink, paths } from '../HeaderLinks'
import { useHistory } from "react-router-dom";
import { RemittanceIcon } from '../shared/icons'

const RemittanceManagementHome = () => {
	const history = useHistory();

	const remittanceManagementItems = [
		{
			icon: RemittanceIcon,
			head: "Manage FX Rates",
			description: "Manage FX rates for all currencies for specific countries ",
			handleClick: () => history.push(paths.managefxRates),
			isActive: true,
		},
		{
			icon: RemittanceIcon,
			head: "FX Rate History",
			description: "View and track the history log of FX rate changes ",
			handleClick: () => history.push(paths.fxRateHistory),
			isActive: true,
		}
	]

	return (
		<Main mainRoute moduleName="Remittance Management" links={mainHeaderLink}>
			<StyledRemmitanceHome>
				<HomeModuleCard items={remittanceManagementItems} />
			</StyledRemmitanceHome>
		</Main>
	)
}

export default RemittanceManagementHome