import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "./feature/user/userReducer";
import moduleReducer from "./feature/module/moduleReducer";
import rolesReducer from "./feature/role/roleReducer";
import serverReducer from "./feature/server/serverReducer";
import cardReducer from "./feature/card/cardReducer";
import campaignReducer from "./feature/campaign/campaignReducer";
import accountReducer from "./feature/account/accountReducer";
import transferLimitReducer from "./feature/transferLimit/transferLimitReducer";
import productOpsReducer from "./feature/productOperations/productOpsReducer";
import businessReducer from "./feature/businessBanking/businessBankingReducer";
import customerOpsReducer from "./feature/customerOperations/customerOpsReducer";
import financeOpsReducer from "./feature/financeOperations/financeOpsReducer";
import disputeReducer from "./feature/dispute/disputeReducer";
import retailReducer from "./feature/retailBanking/retailReducer";
import creditReducer from "./feature/credit/creditReducer";
import loanReducer from "./feature/loan/loanReducer";
import billerMgmtReducer from "./feature/billerManagement/billerMgmtReducer";
import noneResOperationsReducer from "./feature/nonResidentOperations/noneResReducer";
import terminalMgmtReducer from "./feature/terminalManagement/terminalMgmtReducer";
import bankManagementReducer from "./feature/bankManagement/bankMgmtReducer";
import kycOperationsReducer from "./feature/kycOperations/kycOperationsReducer";
import complianceMgmtReducer from "./feature/compliance/complianceMgmtReducer";
import fraudMgmtReducer from "./feature/fraudManagement/fraudMgmtReducer";
import remittanceMgmtReducer from "./feature/remittanceManagement/remittanceMgmtReducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: [], // i.e state to persist here
};

const rootReducer = combineReducers({
  users: userReducer,
  modules: moduleReducer,
  roles: rolesReducer,
  server: serverReducer,
  card: cardReducer,
  campaign: campaignReducer,
  account: accountReducer,
  transferLimit: transferLimitReducer,
  product: productOpsReducer,
  businessBanking: businessReducer,
  customer: customerOpsReducer,
  finance: financeOpsReducer,
  dispute: disputeReducer,
  retail: retailReducer,
  credit: creditReducer,
  loan: loanReducer,
  billerMgmt: billerMgmtReducer,
  noneRes: noneResOperationsReducer,
  terminalMgmt: terminalMgmtReducer,
  bankMgmt: bankManagementReducer,
  kycOperations: kycOperationsReducer,
  complianceMgmt: complianceMgmtReducer,
  fraudMgmt: fraudMgmtReducer,
  remittanceMgmt: remittanceMgmtReducer
});

export default persistReducer(persistConfig, rootReducer);
