import React from "react";
import { StyledNav } from "./Nav.styles";

function Nav({ name, link, icon, count, collapsed = false, toggleMobileNavBar }) {
  return (
    <StyledNav to={link} activeClassName="active" onClick={() => {
			toggleMobileNavBar?.(false)
		}}>
      <div className="nav-icon">{icon}</div>
      {!collapsed ? (
        <>
          <div className="nav-name">{name}</div>
          {count && <div className="nav-mobile_count">{count}</div>}
        </>
      ) : null}
    </StyledNav>
  );
}

export default Nav;
