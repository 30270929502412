/* eslint-disable no-unused-vars */
import Button from "library/Button";
import Loader from "library/Loader";
import styled from "styled-components";
import { useContext, useEffect, useState } from "react";

import { StyledAlertModal } from "./styles";
import { AuthContext } from "context/AuthContext";
import { useCookieManager } from "helper/cookies";

const StyledModalBackground = styled.div`
  position: fixed;
  overflow: hidden;
  top: 100;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(64, 25, 109, 0.2);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5em 1em;
  z-index: 99999;
  box-sizing: border-box;
  overflow: scroll;
`;

const SessionTimeoutModal = ({
  showModal,
  setShowModal = () => {},
  btnClassName,
  children,
  onSubmit = () => {},
  primaryBtnLabel = "Click me",
  isLoading,
  setShowSuccessModal,
  handleSuccess,
  timeLeft,
  onClose,
}) => {
  const refreshToken = useCookieManager("KudaAppRefreshToken");
  const [showError, setShowError] = useState(false);
  const context = useContext(AuthContext);
  const handleClick = (evt) => {
    if (evt.target.id === "modalbg") {
      setShowModal(false);
      onClose?.();
    }
  };

  const handleSignIn = (evt) => {
    const refToken = refreshToken;
    context.refreshLogin(refToken, (success) => {
      if (success) {
        setShowModal(false);
        handleSuccess?.();
      } else {
        showError(success);
      }
    });
  };

  const fn = (val = 0) => Math.floor(val).toString().padStart(2, "0");

  return (
    <StyledModalBackground id="modalbg" onClick={handleClick}>
      {!showError ? (
        <StyledAlertModal style={{ height: 390 }}>
          <div className="card-content">
            <h2>Session Timeout</h2>

            <div className="icon">
              <AlertIcon />
            </div>

            <p className="">
              Your session will expire in{" "}
              <b>
                {fn(timeLeft / 60)}:{fn(timeLeft % 60)}{" "}
                {timeLeft / 60 < 1 ? "seconds" : "minutes"}
              </b>{" "}
              and you’ll need to sign in again.
            </p>
          </div>

          <footer>
            <Button
              size="lg"
              loading={context?.isLoading}
              width="auto"
              variant="primary"
              disabled={false}
              onClick={() => {
                handleSignIn();
              }}
            >
              Keep Me Signed In
            </Button>
          </footer>
        </StyledAlertModal>
      ) : (
        <StyledAlertModal style={{ height: 427 }}>
          <div className="card-content">
            <h2>Sorry, we couldn't extend your session</h2>

            <div className="icon">
              <AlertIcon variant="danger" />
            </div>

            <p className="">
              You might need to log out and log back in again to continue.
              Please click "I Understand" to continue
            </p>
          </div>

          <footer>
            <Button
              size="lg"
              loading={context?.isLoading}
              width="auto"
              variant="primary"
              disabled={false}
              onClick={() => {
                onClose?.();
                setShowModal(false);
              }}
            >
              I understand
            </Button>
          </footer>
        </StyledAlertModal>
      )}
    </StyledModalBackground>
  );
};

const AlertIcon = ({ variant = "warning" }) => (
  <svg
    width="65"
    height="64"
    viewBox="0 0 65 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_50712_2083)">
      <path
        d="M54.5758 63.9998H13.3405C7.89293 63.9998 3.4375 59.5444 3.4375 54.0968V12.8795C3.4375 7.432 7.89293 2.97656 13.3405 2.97656H54.5577C60.0053 2.97656 64.4607 7.432 64.4607 12.8795V54.0968C64.4787 59.5444 60.0233 63.9998 54.5758 63.9998Z"
        fill={variant === "warning" ? "#FFF5DB" : "#FFDBDB"}
      />
      <path
        d="M51.6202 61.0233H10.403C4.95543 61.0233 0.5 56.5691 0.5 51.1232V9.90005C0.5 4.45412 4.95543 0 10.403 0H51.6202C57.0678 0 61.5232 4.45412 61.5232 9.90005V51.1052C61.5412 56.5691 57.0858 61.0233 51.6202 61.0233Z"
        fill={variant === "warning" ? "#FFC83E" : "#F7685B"}
      />
      <path
        d="M33.2014 19.6818C33.2014 18.6758 32.386 17.8604 31.38 17.8604C30.3741 17.8604 29.5586 18.6758 29.5586 19.6818V34.7499C29.5586 35.7558 30.3741 36.5713 31.38 36.5713C32.386 36.5713 33.2014 35.7558 33.2014 34.7499V19.6818Z"
        fill="white"
      />
      <rect
        x="29.5586"
        y="40.417"
        width="3.64285"
        height="3.63824"
        rx="1.81912"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_50712_2083">
        <rect width="64" height="64" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default SessionTimeoutModal;
