import * as types from "./remittanceMgmtTypes";
import * as remittanceMgmtApi from "./remittanceMgmtApi";

export function getFXRatesData(data, onSuccess = null, onError = null) {
    return async (dispatch) => {
        try {
            dispatch({ type: types.FETCH_FXRATE_STARTED });
            const res = await remittanceMgmtApi.GetFXRates(data);

            const fxRates = res?.data?.data || [];
            const status = res?.data?.status;
            const message = res?.data?.message;
            const totalRecords = res?.data?.data?.length;
            if (status && fxRates) {
                dispatch({
                    type: types.FETCH_FXRATE_SUCCESS,
                    payload: fxRates,
                });
                onSuccess?.(fxRates, totalRecords);
            } else {
                dispatch({ type: types.FETCH_FXRATE_FAILED });
                onError?.(message);
            }
        } catch (error) {
            const message =
                error?.response?.data?.message || "Server Error Please Reload the page";
            dispatch({ type: types.FETCH_FXRATE_FAILED });
            onError?.(message);
        }
    };
}


export function getFXRatesHistory(data, onSuccess = null, onError = null) {
    return async (dispatch) => {
        try {
            dispatch({ type: types.FETCH_FXRATEHISTORY_STARTED });
            const res = await remittanceMgmtApi.GetFXRatesHistory(data);

            const fxRates = res?.data?.data || [];
            const status = res?.data?.status;
            const message = res?.data?.message;
            const totalRecords = res?.data?.dataCount;
            if (status && fxRates) {
                dispatch({
                    type: types.FETCH_FXRATEHISTORY_SUCCESS,
                    payload: fxRates,
                });
                onSuccess?.(fxRates, totalRecords);
            } else {
                dispatch({ type: types.FETCH_FXRATEHISTORY_FAILED });
                onError?.(message);
            }
        } catch (error) {
            const message =
                error?.response?.data?.message || "Server Error Please Reload the page";
            dispatch({ type: types.FETCH_FXRATEHISTORY_FAILED });
            onError?.(message);
        }
    };
}


export const createFXRate =
    (data, onSuccess = null, onError = null) =>
        async (dispatch) => {
            try {
                dispatch({ type: types.CREATE_FX_RATE_STARTED });
                const res = await remittanceMgmtApi.CreateFXRate(data);
                const status = res.data.status;
                const errorData = res.data.data;
                const message = res.data.message || "Server Error Please Reload the page";
                if (status) {
                    dispatch({ type: types.CREATE_FX_RATE_SUCCESS });
                    onSuccess?.(message);
                } else {
                    dispatch({ type: types.CREATE_FX_RATE_FAILED });
                    onError?.(message, errorData);
                }
            } catch (error) {
                const errorData = error?.response?.data?.data;

                const message =
                    error?.response?.data?.message || "Server Error Please Reload the page";
                dispatch({ type: types.CREATE_FX_RATE_FAILED });
                onError?.(message, errorData);
            }
        };

export const setFxRateInView = (payload) => async (dispatch) => {
    dispatch({ type: types.SET_FX_RATE_INVIEW, payload })
}

export const updateFxRate =
    (data, onSuccess = null, onError = null) =>
        async (dispatch) => {
            try {
                dispatch({ type: types.UPDATE_FX_RATE_STARTED });
                const res = await remittanceMgmtApi.UpdateFXRate(data);
                const status = res.data.status;
                const errorData = res.data.data;
                const message = res.data.message || "Server Error Please Reload the page";
                if (status) {
                    dispatch({ type: types.UPDATE_FX_RATE_SUCCESS });
                    onSuccess?.(message);
                } else {
                    dispatch({ type: types.UPDATE_FX_RATE_FAILED });
                    onError?.(message, errorData);
                }
            } catch (error) {
                const errorData = error?.response?.data?.data;

                const message =
                    error?.response?.data?.message || "Server Error Please Reload the page";
                dispatch({ type: types.UPDATE_FX_RATE_FAILED });
                onError?.(message, errorData);
            }
        };