import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import ErrorBoundary from "../../components/ErrorBoundary";
import SuspenseLoader from "library/SuspenseLoader";
import { poaReviewBaseModuleUrl } from "modules/ReviewPOAAddress/HeaderLinks";
import useRolePermissionCheck from "hooks/useRolePermissionCheck";
import FraudManagement from "views/FraudManagement";
import RemittanceManagement from "views/RemittanceManagement";

const UserManagement = lazy(() => import("../../views/UserManagement"));
const CardManagement = lazy(() => import("../../views/CardManagement"));
const CampaignManagement = lazy(() => import("../../views/CampaignManagement"));
const UserDashboard = lazy(() => import("../../views/UserDashboard"));
const NotFound = lazy(() => import("../../views/NotFound"));
const Login = lazy(() => import("../../views/Login"));
const ComplianceOperations = lazy(() =>
  import("../../views/ComplianceOperations")
);
const ProductOperations = lazy(() => import("../../views/ProductOperations"));
const CustomerOperations = lazy(() => import("../../views/CustomerOperations"));
const DeveloperDashboard = lazy(() => import("../../views/DeveloperDashboard"));
const DisputeManagement = lazy(() => import("../../views/DisputeResolution"));
const FinanceOperations = lazy(() => import("../../views/FinanceOperations"));
const CreditManagement = lazy(() => import("views/CreditManagement"));
const BusinessBanking = lazy(() => import("views/BusinessBanking"));
const RetailBanking = lazy(() => import("views/RetailBanking"));
const BillerManagement = lazy(() => import("views/BillerManagement"));
const NoneResidentOperartions = lazy(() =>
  import("views/NoneResidentOperations")
);
const KYCOperations = lazy(() => import("views/KYCOperations"));
const BusinessAPIOperations = lazy(() => import("views/BusinessAPIOperations"));
const TerminalManagement = lazy(() => import("views/TerminalManagement"));
const BankManagement = lazy(() => import("views/BankManagement"));
const RefundManagement = lazy(() => import("views/RefundManagement"));
const ReviewPOAAddress = lazy(() => import("views/ReviewPOAAddress"));
const MainDocumentViewPage = lazy(() => import("views/DocumentViewPage"));

const DefaultLayout = () => {
  useRolePermissionCheck();

  return (
    <>
      <ErrorBoundary>
        <Suspense
          fallback={
            <div style={{ marginTop: "20px" }}>
              <SuspenseLoader variant={"purple"} />
            </div>
          }
        >
          <Switch>
            <Route exact path="/">
              <Redirect to="/dashboard" />
            </Route>
            <Route path="/login" component={Login} />{" "}
            <PrivateRoute path="/users" component={UserManagement} />
            {/* <PrivateRoute path="/users" component={UserManagement} /> */}
            <PrivateRoute
              path="/business-banking"
              component={BusinessBanking}
            />
            <PrivateRoute path="/dispute" component={DisputeManagement} />
            <PrivateRoute path="/retail-banking" component={RetailBanking} />
            <PrivateRoute path="/cards" component={CardManagement} />
            <PrivateRoute path="/referrals" component={CampaignManagement} />
            <PrivateRoute path="/dashboard" component={UserDashboard} />
            <PrivateRoute path="/compliance" component={ComplianceOperations} />
            <PrivateRoute path="/product" component={ProductOperations} />
            <PrivateRoute
              path="/customer-operations"
              component={CustomerOperations}
            />
            <PrivateRoute
              path="/developer-dashboard"
              component={DeveloperDashboard}
            />
            <PrivateRoute path="/finance" component={FinanceOperations} />
            <PrivateRoute path="/credit" component={CreditManagement} />
            <PrivateRoute
              path="/biller-management"
              component={BillerManagement}
            />
            <PrivateRoute
              path="/remittance-management"
              component={RemittanceManagement}
            />
            <PrivateRoute
              path="/none-residents"
              component={NoneResidentOperartions}
            />
            <PrivateRoute
              path="/address-verification"
              component={KYCOperations}
            />
            <PrivateRoute
              path="/terminal-management"
              component={TerminalManagement}
            />
            <PrivateRoute
              path="/fraud-management"
              component={FraudManagement}
            />
            <PrivateRoute
              path="/business-api-operations"
              component={BusinessAPIOperations}
            />
            <PrivateRoute
              path="/business-api-operations"
              component={BusinessAPIOperations}
            />
            <PrivateRoute
              path="/refund-management"
              component={RefundManagement}
            />
            <PrivateRoute path="/bank-management" component={BankManagement} />
            <PrivateRoute
              path={poaReviewBaseModuleUrl}
              component={ReviewPOAAddress}
            />
            <Route path="/view-document" component={MainDocumentViewPage} />
            <Route path="/404" component={NotFound} />
            <Redirect to="/404" />
          </Switch>
        </Suspense>
      </ErrorBoundary>
    </>
  );
};

export default DefaultLayout;
