import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

const checkPathnameExists = (permissionArray, pathname) => {
  return permissionArray.some((permissionItem) =>
    pathname.includes(permissionItem.moduleGroup.url)
  );
};

const useRolePermissionCheck = () => {
  const location = useLocation();
  const history = useHistory();
  const userData = JSON.parse(window.localStorage.getItem("KudaAppUserData"));

  useEffect(() => {
    const urlsToExempt = [
      "/login",
      "/dashboard",
      "/dashboard/home",
      "/dashboard/approval",
      "/view-document",
			"/",
			"/404"
    ];

    if (
      userData?.rolePermissions?.length &&
      !urlsToExempt.includes(location.pathname)
    ) {
      const checkUrlPerModule = checkPathnameExists(
        userData.rolePermissions,
        location.pathname
      );
      if (!checkUrlPerModule) {
        history.push("/404");
      }
    }
  }, [location.pathname, history, userData?.rolePermissions]);
};

export default useRolePermissionCheck;
