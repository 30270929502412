import * as types from "./remittanceMgmtTypes";

const initialState = {
    isFXRatesLoading: false,
    fxRates: [],
    isFXRatesHistoryLoading: false,
    fxRateHistory: [],
    createFxRateLoading: false,
    updateFxRateLoading: false,
    fxRateInView: {}
};

const remittanceMgmtReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.FETCH_FXRATE_STARTED:
            return {
                ...state,
                isFXRatesLoading: true,
            }
        case types.FETCH_FXRATE_SUCCESS:
            return {
                ...state,
                isFXRatesLoading: false,
                fxRates: payload,
            };
        case types.FETCH_FXRATE_FAILED:
            return {
                ...state,
                isFXRatesLoading: false
            }
        case types.FETCH_FXRATEHISTORY_STARTED:
            return {
                ...state,
                isFXRatesHistoryLoading: true,
            }
        case types.FETCH_FXRATEHISTORY_SUCCESS:
            return {
                ...state,
                isFXRatesHistoryLoading: false,
                fxRateHistory: payload,
            };
        case types.FETCH_FXRATEHISTORY_FAILED:
            return {
                ...state,
                isFXRatesHistoryLoading: false
            }
        case types.CREATE_FX_RATE_STARTED:
            return {
                ...state,
                createFxRateLoading: true
            }
        case types.CREATE_FX_RATE_SUCCESS:
            return {
                ...state,
                createFxRateLoading: false
            }
        case types.CREATE_FX_RATE_FAILED:
            return {
                ...state,
                createFxRateLoading: false
            }
        case types.SET_FX_RATE_INVIEW:
            return {
                ...state,
                fxRateInView: payload
            }
        case types.UPDATE_FX_RATE_STARTED:
            return {
                ...state,
                updateFxRateLoading: true,
            }
        case types.UPDATE_FX_RATE_SUCCESS:
            return {
                ...state,
                updateFxRateLoading: false,
            }
        case types.UPDATE_FX_RATE_FAILED:
            return {
                ...state,
                updateFxRateLoading: false,
            }
        default:
            return state;
    }
};

export default remittanceMgmtReducer;
