import styled from 'styled-components';

export const DropdownContainer = styled.div`
  position: relative;
  margin: 0 auto;

  .input-field {
min-height: 38px
  }
  .smaller-text {
    justify-content: center;
    font-size: 11px;
    display: flex;
    align-items: center;
  }
`;

export const DropdownButton = styled.div`
    cursor: pointer;
`;

export const DropdownButtonContainer = styled.div`
    position: relative;
    display: inline-block;
`;

export const DropdownSelect = styled.select`
    cursor: pointer;
    
    
`;

export const DropdownMenu = styled.div`
    position: absolute;
    width: 312px;
    background-color: #ffffff;
    border: 2px solid #EFF1FF;
    border-radius: 10px;
    margin-top: 5px;
    z-index: 1;
    padding: 10px;
    .grey-bg {
        background-color: #D9D9D933;
    }
`;

export const DropdownMenuItem = styled.div`
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #DBDCE0;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    gap:10px;

    &:hover {
        background-color: #f0f0f0;
    }
`;

export const Icon = styled.span`
  width: 20px;
  height: 20px;
  margin-right: 14px;
`;

export const DropdownOption = styled.option`
    background-color: #ffffff;
    color: #000000;
    padding: 10px;

    &:hover {
        background-color: #f0f0f0;
    }
`;