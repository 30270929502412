import React from "react";
import { StyledModalFooter } from "./Modal.styles";

function ModalFooter({ btnPrimary, btnSecondary, btnEqualWidth }) {
  return (
    <StyledModalFooter {...{ btnSecondary, btnPrimary,btnEqualWidth }}>
      {btnSecondary && <div className="btn-sec">{btnSecondary}</div>}
      {btnPrimary && <div className="btn-pry">{btnPrimary}</div>}
    </StyledModalFooter>
  );
}

export default ModalFooter;
