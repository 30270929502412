export const JIRA_AUTH_CODE = "JIRA_AUTH_CODE";
export const SEARCH_PARAMS = "SEARCH_PARAMS";
export const DISPUTE_START = "DISPUTE_START";
export const DISPUTE_FAIL = "DISPUTE_FAIL";
export const DISPUTE_SUCCESS = "DISPUTE_SUCCESS";
export const JIRA_ACCESS_TOKEN = "JIRA_ACCESS_TOKEN";
export const GET_ALL_DISPUTES = "GET_ALL_DISPUTES";
export const DISPUTE_COUNT = "DISPUTE_COUNT";
export const GET_TRANSACTION_DETAILS = "GET_TRANSACTION_DETAILS";
export const GET_DISPUTE_DETAILS = "GET_DISPUTE_DETAILS";
export const SEND_MAIL_TO_CUSTOMER = "SEND_MAIL_TO_CUSTOMER";
export const GET_JIRA_ID = "GET_JIRA_ID";
export const UPLOAD_BULK_DISPUTE_STATUS = "UPLOAD_BULK_DISPUTE_STATUS";
export const GET_JIRA_COMMENTS = "GET_JIRA_COMMENTS";
export const RECONNECT_TO_JIRA = "RECONNECT_TO_JIRA";
export const UPLOAD_BULK_REFUND_FILE = "UPLOAD_BULK_REFUND_FILE"
export const GET_ALL_DISPUTES_FRAUD = "GET_ALL_DISPUTES_FRAUD"
export const GET_DISPUTE_FRAUD_DETAIL = "GET_DISPUTE_FRAUD_DETAIL"
