export const isOfficeDocsFile = (url) =>
  /\.(ppt|pptx|doc|docx|xls|xlsx)$/i.test(url?.split("?")[0]);

export const isGoogleDocsSupportedFile = (url) =>
  /\.(webm|mpeg4|mp4|3gpp|mov|avi|mpegps|wmv|flv|txt|css|html|php|c|cpp|h|hpp|js|pdf|pages|ai|psd|tiff|dxf|svg|eps|ps|ttf|xps|zip|rar|document)$/i.test(
    url?.split("?")[0]
  );

export const isImageFile = (url) =>
  /\.(jpg|jpeg|png|gif|bmp|tiff|webp|svg)$/i.test(url?.split("?")[0]);

export const isHeicHeifFile = (url) => /\.(heic|heif)$/i.test(url);

export const isUnsupportedFile = (url) =>
  /\.(psd|ai)$/i.test(url?.split("?")[0]);
