import React, { useState, useRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";

import Button from "library/Button/Button";

import { formatDateToString } from "utils/utilityFunctions";

const PopupContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

const InputField = styled.input`
  width: 245px;
  padding: 8px 14px;
  border: 1px solid #dfe3e9;
  cursor: pointer;
  font-size: 12px;
  min-height: 38px;
  border: none;
  outline: none;

  &:focus {
    border: none;
  }
`;

const InputContainer = styled.div`
  width: 390px;
  border: 1px solid #dfe3e9;
  border-radius: 5px;
  display: flex;

  .descriptor {
    display: flex;
    background-color: #f9f9f9;
    font-size: 12px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #dbdce0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
`;

const PopupContent = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 8px;
  z-index: 1000;
  width: 285px;
  > h3 {
    font-size: 15px;
    font-style: normal;
    font-weight: 900;
    text-align: center;
    color: #40196d;
  }

  .react-datepicker {
    border: none;
    width: 100%;
  }

  .react-datepicker__header {
    background-color: #ffff;
    border-bottom: none;
  }

  .react-datepicker__day-names {
    margin-top: 10px;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    border-radius: 100%;
    background-color: #40196d;
    color: #ffff;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 100%;
    background-color: #40196d;
    color: #fff;
  }

  .react-datepicker__day-name {
    color: #979797;
  }

  .react-datepicker__month-container {
    float: none;
  }

  .react-datepicker__current-month {
    display: none;
  }

  .react-datepicker__month-dropdown-container,
  .react-datepicker__year-dropdown-container {
    border: none;
    font-size: 15px;
    font-weight: 400;
    outline: none;

    &:focus {
      border: none;
    }
  }

  .react-datepicker__month-select,
  .react-datepicker__year-select {
    border: none;
    outline: none;

    &:focus {
      border: none;
    }
  }

  .react-datepicker__year-select {
    max-height: 200px;
    overflow-y: auto;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 10px;
  .btn-wrap {
    width: 100%;
  }
  .btn-main {
    width: 100%;
  }
`;

function DateRangePicker({ filterDate }) {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleDone = () => {
        if (startDate && endDate) {
            setIsOpen(false);
        }
        filterDate({
            startDate: formatDateToString(startDate),
            endDate: formatDateToString(endDate)
        })

    };

    const handleCancel = () => {
        setStartDate(null);
        setEndDate(null);
        setIsOpen(false);
        filterDate({
            startDate: "",
            endDate: ""
        })
    };

    const handleInputClick = () => {
        setIsOpen(!isOpen);
    };

    const minDate = new Date("2019-01-01");

    return (
        <PopupContainer>
            <InputContainer>
                <div className="descriptor">Date Range</div>

                <InputField
                    onClick={handleInputClick}
                    placeholder="DD/MM/YYYY - DD/MM/YYYY"
                    value={
                        startDate && endDate
                            ? `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`
                            : ""
                    }
                    readOnly
                />
            </InputContainer>
            {isOpen && (
                <PopupContent ref={ref}>
                    <h3 className="mb-3">Select Date Range</h3>
                    <DatePicker
                        selected={startDate}
                        onChange={(dates) => {
                            const [start, end] = dates;
                            setStartDate(start);
                            setEndDate(end);
                        }}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        inline
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        minDate={minDate}
                        maxDate={new Date()}
                    />
                    <ButtonContainer>
                        <div className="btn-wrap">
                            <Button
                                size="md"
                                width="100%"
                                variant="secondary"
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                        </div>
                        <div className="btn-wrap">
                            <Button
                                size="md"
                                width="100%"
                                variant="primary"
                                onClick={handleDone}
                                disabled={!startDate || !endDate}
                            >
                                Done
                            </Button>
                        </div>
                    </ButtonContainer>
                </PopupContent>
            )}
        </PopupContainer>
    );
}

export default DateRangePicker;
