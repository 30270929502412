import { authPostCall, postCall } from "../apiCalls";
import { USER_URL } from "../endpoints";

export const login = (data) => {
  return postCall(`${USER_URL}/login`, data);
};

export const refreshUserSession = (r_token = "") => {
  return authPostCall(
    `${USER_URL}/RefreshToken?currentRefreshToken=${r_token}`,
    {}
  );
};
