import { useState, useRef } from "react";
import { useOnClickOutside } from "../../utils/customHooks";
import { DropdownIcon } from "../../components/SvgPack";
import {
  DropDownContainer,
  DropDownHeader,
  DropDownList,
  DropDownListContainer,
  ListItem,
} from "./SearchField.styles";

const SearchDropdown = ({
  options,
  selectedOption,
  setSelectedOption,
  showIcon = true,
  placeholder = "Select",
  disabled = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const node = useRef();
  useOnClickOutside(node, () => handleClose());

  const toggling = () => setIsOpen(!isOpen);
  const handleClose = () => setIsOpen(false);

  const onOptionClicked = (value) => () => {
    setSelectedOption(value);
    setIsOpen(false);
  };

  return (
    <DropDownContainer>
      <DropDownHeader
        onClick={!disabled ? toggling : () => {}}
        style={{ cursor: disabled ? "not-allowed" : "pointer" }}
      >
        {selectedOption.name || placeholder}
        &emsp;
        {showIcon && options?.length > 1 && <DropdownIcon />}
      </DropDownHeader>
      {isOpen && (
        <DropDownListContainer ref={node}>
          <DropDownList>
            {options.map((option) => (
              <ListItem onClick={onOptionClicked(option)} key={Math.random()}>
                {option.name}
                {<div />}
              </ListItem>
            ))}
          </DropDownList>
        </DropDownListContainer>
      )}
    </DropDownContainer>
  );
};

export default SearchDropdown;
