import { authPostCall, authPutCall, getCall } from "api/apiCalls";

const remittanceBaseUrl = "/api/v3/Remittance";

export const GetFXRates = ({
    currency = "",
    startDate = "",
    endDate = "",
    pageNumber = 1,
    pageSize = 10,
}) => {
    return getCall(`${remittanceBaseUrl}/FxRates?SearchCurrency=${currency}&pageNumber=${pageNumber}&pageSize=${pageSize}&StartDate=${startDate}&EndDate=${endDate}`);
};

export const GetFXRatesHistory = ({
    currency = "",
    startDate = "",
    endDate = "",
    pageNumber = 1,
    pageSize = 10,
}) => {
    return getCall(`${remittanceBaseUrl}/FxRateHistory?SearchCurrency=${currency}&pageNumber=${pageNumber}&pageSize=${pageSize}&StartDate=${startDate}&EndDate=${endDate}`);
};

export const CreateFXRate = (data) => {
    return authPostCall(`${remittanceBaseUrl}/AddFxRate`, data);
}

export const UpdateFXRate = (data) => {
    return authPutCall(
        `${remittanceBaseUrl}/UpdateFxRate`,
        data
    );
}