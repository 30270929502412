import { ToastContext } from "context/ToastContext";
import Button from "library/Button";
import Modal from "library/Modal";
import Select from "library/Select";
import Textarea from "library/Textarea";
import { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { TreatFlaggedTransactions } from "redux/feature/fraudManagement/fraudMgmtActions";

const RejectionModal = ({
  showModal,
  setShowModal,
  reasons,
  redirectUrl,
  id,
  moduleGroupId,
}) => {
  const [reason, setReason] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const toastContext = useContext(ToastContext);
  const [isLoading, setIsLoading] = useState(false);

  const resetField = () => {
    setOtherReason("");
    setReason("");
  };

  const handleReason = (value) => {
    setReason(value);
    setOtherReason("");
  };

  const handleValidation = () => {
    return !reason || (reason.toLowerCase() === "other" && !otherReason);
  };

  let toastConfig = {
    error: false,
    autoDismiss: true,
  };

  const onSuccess = (message = "Operation Successful") => {
    setShowModal(false);
    setIsLoading(false);
    history.push(redirectUrl);
    toastContext.setToast(message, toastConfig);
  };

  const onError = (message) => {
    toastConfig.error = true;
    setIsLoading(false);
    toastContext.setToast(message, toastConfig);
  };

  const handleRejection = () => {
    setIsLoading(true);
    dispatch(
      TreatFlaggedTransactions(
        {
          id,
          approve: false,
          reason: otherReason?.length ? otherReason : reason,
        },
        onSuccess,
        onError
      )
    );
  };

  return (
    <Modal
      overflow
      modalTitle="Decline Request"
      showModal={showModal}
      handleClose={isLoading ? () => {} : resetField}
      width="488px"
      setShowModal={isLoading ? () => {} : setShowModal}
      btnSecondary={
        <Button
          width="200px"
          size="lg"
          variant="secondary"
          onClick={() => {
            setShowModal(false);
            resetField();
          }}
          disabled={isLoading}
        >
          Cancel
        </Button>
      }
      btnPrimary={
        <Button
          width="200px"
          size="lg"
          variant="danger"
          disabled={handleValidation() || isLoading}
          onClick={handleRejection}
          loading={isLoading}
        >
          Reject
        </Button>
      }
    >
      <div>
        <p className="my-4">
          Please, choose a reason for declining this request.
        </p>
        <div>
          <div className="input-container">
            <Select
              width="100%"
              label="Reason"
              options={reasons}
              placeholder="Select a reason"
              onGetSelectValue={(value) => handleReason(value)}
              value={reason}
            />
          </div>
          <div className="input-container mt-4">
            {reason.toLowerCase() === "other" && (
              <Textarea
                width="100%"
                value={otherReason}
                onChange={(e) => setOtherReason(e.target.value)}
                placeholder="Fill in your reason here."
                label="More Details"
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RejectionModal;
