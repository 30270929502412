import styled from "styled-components";

export const StyledModalBackground = styled.div`
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(64, 25, 109, 0.2);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5em 1em;
    z-index: 99999;
    // z-index: 99999;
    box-sizing: border-box;
    overflow: scroll;

    > div {
        border-radius: 6px;
        margin: auto 0;
    }
`;

export const StyledModalMain = styled.div`
    background: #ffffff;
    border: 1px solid rgba(219, 220, 224, 0.5);
    box-shadow: 15px 30px 40px rgba(64, 25, 109, 0.07);
    border-radius: 5px;
    padding: 10px;
    position: relative;
    margin: auto;
    overflow: visible;
    padding: 15px 20px;

    .modal-hr {
        margin: 0 !important;
        opacity: 0.6;
        border: 1px solid #dbdce0;
        width: 100%;
    }
    width: ${(props) => (props.width ? props.width : "300px")};

    div[label="User Role"] .select-items {
        position: relative;
        top: 10px;
    }

    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`;

export const StyledModalFooter = styled.footer`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
    gap: 16px;

    .btn-pry,
    .btn-sec {
        width: 100%;
    }

    .btn-pry > *,
    .btn-sec > * {
        width: 100%;
    }
`;

export const StyledModalHeader = styled.header`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
`;

export const StyledCloseButton = styled.div`
    font-size: 30px;
    cursor: pointer;
    .lien-button {
        font-size: 16px;
        font-weight: 900;
        color: #f7685b !important;
        display: flex;
    }
`;

export const StyledTitle = styled.div`
    display: flex;
    margin: 0;
    justify-content: center;
    align-items: center;

    .icon {
        margin-right: 10px;
        height: 20px;
        cursor: pointer;
    }

    h3 {
        font-size: 20px;
        font-weight: 800;
        line-height: 25px;
        color: #000000;
    }
`;

export const StyledModalContent = styled.div`
    display: flex;
    flex-direction: column;
    line-height: 1.8;
    color: #141414;
    // max-height: "60vh";
    min-height: auto;
    max-height: ${(props) => (props.maxHeight ? props?.maxHeight : "unset")};
    overflow: ${(props) => (props.overflow ? "visible" : "auto")};
`;

export const CloseModalButton = styled.h1`
    background-color: black;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 5px;
    width: 32px;
    height: 32px;
    padding: 0;
    z-index: 10;
`;
