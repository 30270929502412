import * as types from "./userTypes";

const initialState = {
  isLoading: false,
  allUsers: [],
  userDetails: {},
  notifications: [],
  pendingApproval: [],
  userModules: [],
  notificationCount: null,
  moduleRequests: [],
  totalRecords: 0,
};

const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.USER_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case types.GET_ALL_USERS:
      return {
        ...state,
        isLoading: false,
        allUsers: payload,
      };
    case types.GET_A_USER:
      return {
        ...state,
        isLoading: false,
        userDetails: payload,
      };
    case types.USER_FAIL:
      return {
        ...state,
        isLoading: false,
        allUsers: [],
      };
    case types.GET_NOTIFICATIONS:
      return {
        ...state,
        isLoading: false,
        notifications: payload,
      };
    case types.GET_NOTIFICATIONS_FAIL:
      return {
        ...state,
        isLoading: false,
        notifications: [],
      };
    case types.GET_PENDING_APPROVAL:
      return {
        ...state,
        isLoading: false,
        pendingApproval: payload,
      };
    case types.GET_PENDING_APPROVAL_FAIL:
      return {
        ...state,
        isLoading: false,
        pendingApproval: [],
      };
    case types.GET_NOTIFICATION_COUNT:
      return {
        ...state,
        isLoading: false,
        notificationCount: payload,
      };
    case types.GET_NOTIFICATION_COUNT_FAIL:
      return {
        ...state,
        isLoading: false,
        notificationCount: 0,
      };
    case types.GET_USER_MODULES:
      return {
        ...state,
        isLoading: false,
        userModules: payload,
      };
    case types.GET_PENDING_USER_MODULES:
      return {
        ...state,
        isLoading: false,
        moduleRequests: payload,
      };
    case types.SET_DATA_COUNT:
      return {
        ...state,
        isLoading: false,
        totalRecords: payload,
      };
    default:
      return state;
  }
};

export default userReducer;
