import * as types from "./campaignTypes";
import * as campaignUtils from "./campaignUtils";

const initialState = {
  isLoading: false,
  allCampaign: {
    data: [],
  },
  campaignLoggedForApproval: [],
  allCriteria: [],
  allReferallParticipants: {},
  allPromoCampaign: {
    data: [],
  },
  activeReferralCampaign: null,
  campaignSummary: [],  
  referralSettings: null,
};

const campaignReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.CAMPAIGN_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.CAMPAIGN_SUCCESS:
    case types.CAMPAIGN_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case types.GET_ALL_CAMPAIGN:
      return {
        ...state,
        isLoading: false,
        allCampaign: campaignUtils.formatAllCampaignData(
          state.allCampaign,
          payload
        ),
      };
    case types.GET_ALL_CAMPAIGN_LOGGED_FOR_APPROVAL:
      return {
        ...state,
        isLoading: false,
        campaignLoggedForApproval: payload,
      };
    case types.GET_ALL_CRITERIA:
      return {
        ...state,
        isLoading: false,
        allCriteria: payload,
      };
    case types.GET_ALL_REFERRAL_PARTICIPANT:
      return {
        ...state,
        isLoading: false,
        allReferallParticipants: campaignUtils.formatReferralData(
          state.allReferallParticipants,
          payload
        ),
      };
    case types.GET_ACTIVE_REFERRAL_CAMPAIGN:
      return {
        ...state,
        isLoading: false,
        activeReferralCampaign: payload,
      };
    case types.GET_CAMPAIGN_SUMMARY:
      return {
        ...state,
        isLoading: false,
        campaignSummary: payload,
      };
    case types.GET_ALL_PROMO_CAMPAIGN:
      return {
        ...state,
        isLoading: false,
        allPromoCampaign: campaignUtils.formatAllCampaignData(
          state.allPromoCampaign,
          payload
        ),
      };
    case types.GET_REFERRAL_SETTING:
      return {
        ...state,
        isLoading: false,
        referralSettings: payload,
      };
      case types.CAMPAIGN_REFERRAL_FAIL:
        case types.CAMPAIGN_REFERRAL_SUCCESS:
          return {
            ...state,
            referralSettings:payload,
            isLoading: false,
          };
    case types.CLEAR_CACHED_PARTICIPANT:
      return {
        ...state,
        allReferallParticipants: {},
      };
    default:
      return state;
  }
};

export default campaignReducer;
