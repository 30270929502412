import React from "react";
import { StyledBurger } from "./SideBar/SideBar.styles";

function Burger({ onClick, mobileNavBar, width }) {
  return (
    <StyledBurger onClick={onClick} isOpen={mobileNavBar} width={width}>
      <div />
      <div />
    </StyledBurger>
  );
}

export default Burger;
