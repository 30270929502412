export const CAMPAIGN_START = "CAMPAIGN_START";
export const CAMPAIGN_FAIL = "CAMPAIGN_FAIL";
export const CAMPAIGN_SUCCESS = "CAMPAIGN_SUCCESS";

export const GET_ALL_CAMPAIGN = "GET_ALL_CAMPAIGN";

export const GET_ALL_CAMPAIGN_LOGGED_FOR_APPROVAL =
  "GET_ALL_CAMPAIGN_LOGGED_FOR_APPROVAL";

export const GET_ALL_CRITERIA = "GET_ALL_CRITERIA";

export const GET_ALL_REFERRAL_PARTICIPANT = "GET_ALL_REFERRAL_PARTICIPANT";
export const CLEAR_CACHED_PARTICIPANT = "CLEAR_CACHED_PARTICIPANT";

export const GET_ACTIVE_REFERRAL_CAMPAIGN = "GET_ACTIVE_REFERRAL_CAMPAIGN";

export const UPDATE_CAMPAIGN_SUCCESS = "UPDATE_CAMPAIGN_SUCCESS";
export const GET_CAMPAIGN_SUMMARY = "GET_CAMPAIGN_SUMMARY";

export const GET_ALL_PROMO_CAMPAIGN = "GET_ALL_PROMO_CAMPAIGN";

export const GET_REFERRAL_SETTING = "GET_REFERRAL_SETTING";
export const CAMPAIGN_REFERRAL_FAIL = "CAMPAIGN_REFERRAL_FAIL";
export const CAMPAIGN_REFERRAL_SUCCESS = "CAMPAIGN_REFERRAL_SUCCESS";
