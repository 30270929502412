import { useEffect } from "react";
import XLSX from "xlsx";

export const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [ref, handler]);
};

/**
 * @usehandleExcelUpload - convert excel file to JSON
 * @return - { data, fileName }
 */
export const usehandleExcelUpload = () => {
  const handleExcelUpload = async (file) => {
    const promise = new Promise((resolve) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);

        resolve({ data, fileName: file?.name });
      };
    });

    return await promise;
  };

  return handleExcelUpload;
};
