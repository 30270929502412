import styled, { css } from "styled-components";
import breakpoint from "../breakpoint";

export const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Muli";
  font-style: normal;
  font-weight: bold;
  line-height: 25px;
  text-align: center;
  color: ${(props) => props.theme.colors.kudaWhite};
  cursor: pointer;
  border: none;
  transition: all 0.6s;
  border-radius: 8px;
  background: ${(props) => props.theme.colors.kudaPurple};
  padding: 10px;

  height: ${(props) => (props.size === "sm" ? "24px" : props.size === "md" ? "38px" : props.size === "lg" ? "44px" : props.height || "")};

  &:hover {
    transform: translate3d(0px, -3px, 0px);
  }

  ${(props) =>
      props.disabled &&
      css`
          opacity: 0.5;
      `};

  ${(props) =>
      props.size === "lg" &&
      css`
          font-size: 15px;
          min-width: ${(props) => (props.width ? props.width : "200px")};

          @media ${breakpoint.device.tablet} {
              min-width: ${(props) => (props.width ? props.width : "50%")};
          }
      `}

  ${(props) =>
      (props.size === "sm" || props.size === "md") &&
      css`
          min-width: ${(props) => (props.width ? props.width : "140px")};
          font-size: 12px;
          border-radius: 6px;
          padding: 0px 20px;

          @media ${breakpoint.device.tablet} {
              min-width: ${(props) => (props.width ? props.width : "145px")};
          }
      `}

    ${(props) =>
        props.size === "md" &&
        css`
            border-radius: 8px;
        `}

  ${(props) =>
      props.variant === "secondary" &&
      css`
          border: 1px solid rgba(64, 25, 109, 0.3);
          color: ${(props) => props.theme.colors.kudaPurple};
          background: ${(props) => props.theme.colors.kudaLightGrey};
      `}
  ${(props) =>
      props.variant === "danger" &&
      css`
          color: ${(props) => props.theme.colors.kudaRed};
          background: #ffdbdb;
      `};

  ${(props) =>
      props.type === "text" &&
      css`
          border: none;
          padding: none;
          text-align: right;
          height: auto;
          background: none;
          color: ${(props) => props.theme.colors.kudaPurple};
      `};

  .icon-left {
    margin-right: 10px;
  }

  .btn-main {
    display: flex;
    margin: 0;
    padding: 0;
    justify-content: center;
    font-size: 14px;
    ${(props) =>
        props.size === "sm" &&
        css`
            font-size: 12px;
        `}
  }
}
`;
