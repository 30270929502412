import breakpoint from "library/breakpoint";
import styled from "styled-components";

export const StyledDashboardLayout = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  min-height: 100vh;
  overflow: hidden;
  position: relative;

  .dashboard-header {
    display: flex;
    position: fixed;
    align-items: center;
    height: 70px;
    width: 100%;
    padding: 0 36px;
    background: #40196d;
    z-index: 99991;
  }

  & > main {
    overflow-y: scroll;
  }

  @media only screen and ${breakpoint.device.tablet} {
    .dashboard-header {
      padding: 0 16px;
    }
  }
`;
