import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { StyledDoc } from "./styles";

const Overlay = ({ children, setShowDocs, name }) => {
  const [isPanning, setIsPanning] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);
  const [transforms, setTransforms] = useState({
    rotation: 0,
    zoomLevel: 1,
    transformOrigin: "center",
  });

  const contentRef = useRef(null);

  const handleZoomIn = (evt) => {
    setTransforms((prev) => {
      const newZoomLevel = Math.min(prev.zoomLevel + 0.1, 3);
      return {
        ...prev,
        zoomLevel: newZoomLevel,
        transformOrigin: "center",
      };
    });

    adjustScrollPosition();
    evt.stopPropagation();
    setShowDocs(true);
  };

  const handleZoomOut = (evt) => {
    setTransforms((prev) => {
      const newZoomLevel = Math.max(prev.zoomLevel - 0.1, 0.5);
      return {
        ...prev,
        zoomLevel: newZoomLevel,
        transformOrigin: "center",
      };
    });

    adjustScrollPosition();
    evt.stopPropagation();
    setShowDocs(true);
  };

  const handleRotateLeft = (evt) => {
    setTransforms((prev) => ({
      ...prev,
      rotation: prev.rotation - 90,
      transformOrigin: "center",
    }));

    evt.stopPropagation();
    setShowDocs(true);
  };

  const handleRotateRight = (evt) => {
    setTransforms((prev) => ({
      ...prev,
      rotation: prev.rotation + 90,
      transformOrigin: "center",
    }));

    evt.stopPropagation();
    setShowDocs(true);
  };

  const handleMouseDown = (e) => {
    setIsPanning(true);
    setStartX(e.clientX);
    setStartY(e.clientY);
    setScrollLeft(e.target.closest(".overlay_content").scrollLeft);
    setScrollTop(e.target.closest(".overlay_content").scrollTop);
  };

  const handleMouseMove = (e) => {
    if (!isPanning) return;
    const x = e.clientX - startX;
    const y = e.clientY - startY;
    const content = e.target.closest(".overlay_content");
    content.scrollLeft = scrollLeft - x;
    content.scrollTop = scrollTop - y;
  };

  const handleMouseUp = () => {
    setIsPanning(false);
  };

  const adjustScrollPosition = () => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    adjustScrollPosition();

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <StyledDoc
      zoomLevel={transforms.zoomLevel}
      rotation={transforms.rotation}
      transformOrigin={transforms.transformOrigin}
    >
      <div
        className="overlay"
        onClick={(evt) => {
          evt.stopPropagation();
          setShowDocs(false);
        }}
      >
        <div
          className="overlay_header"
          onClick={(evt) => {
            evt.stopPropagation();
            setShowDocs(true);
          }}
        >
          <p className="overlay_header-text" onClick={() => setShowDocs(false)}>
            {name}
          </p>
          <p
            className="close-btn"
            onClick={(evt) => {
              evt.stopPropagation();
              setShowDocs(false);
            }}
          >
            &times; &nbsp;
          </p>
        </div>
        <div className="overlay_container">
          <div
            className="overlay_content"
            ref={contentRef}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
            onClick={(evt) => {
              evt.stopPropagation();
              setShowDocs(true);
            }}
          >
            {children}
          </div>
          <div className="controls">
            <button onClick={handleZoomIn} title="Zoom In">
              <div className="icon">
                <ZoomInIcon />
              </div>
              {/* <span>Zoom In</span> */}
            </button>
            <button onClick={handleZoomOut}>
              <div className="icon" title="Zoom Out">
                <ZoomOutIcon />
              </div>
              {/* <span>Zoom Out</span> */}
            </button>
            <button onClick={handleRotateLeft} title="Rotate Left">
              {/* <span>Rotate Left</span> */}
              <div className="icon">
                <RotateLeftIcon />
              </div>
            </button>
            <button onClick={handleRotateRight} title="Rotate Right">
              {/* <span>Rotate Right</span> */}
              <div className="icon">
                <RotateRightIcon />
              </div>
            </button>
          </div>
        </div>
      </div>
    </StyledDoc>
  );
};

Overlay.propTypes = {
  name: PropTypes.string,
  children: PropTypes.element,
  setShowDocs: PropTypes.func, // this function accept boolean argument
};

export default Overlay;

const ZoomInIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
    >
      <path d="M15.853 16.56c-1.683 1.517-3.911 2.44-6.353 2.44-5.243 0-9.5-4.257-9.5-9.5s4.257-9.5 9.5-9.5 9.5 4.257 9.5 9.5c0 2.442-.923 4.67-2.44 6.353l7.44 7.44-.707.707-7.44-7.44zm-6.353-15.56c4.691 0 8.5 3.809 8.5 8.5s-3.809 8.5-8.5 8.5-8.5-3.809-8.5-8.5 3.809-8.5 8.5-8.5zm-4.5 8h4v-4h1v4h4v1h-4v4h-1v-4h-4v-1z" />
    </svg>
  );
};

const ZoomOutIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
    >
      <path d="M15.853 16.56c-1.683 1.517-3.911 2.44-6.353 2.44-5.243 0-9.5-4.257-9.5-9.5s4.257-9.5 9.5-9.5 9.5 4.257 9.5 9.5c0 2.442-.923 4.67-2.44 6.353l7.44 7.44-.707.707-7.44-7.44zm-6.353-15.56c4.691 0 8.5 3.809 8.5 8.5s-3.809 8.5-8.5 8.5-8.5-3.809-8.5-8.5 3.809-8.5 8.5-8.5zm-4.5 8h9v1h-9v-1z" />
    </svg>
  );
};

const RotateLeftIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
    >
      <path d="M12 0c-3.31 0-6.291 1.353-8.459 3.522l-2.48-2.48-1.061 7.341 7.437-.966-2.489-2.488c1.808-1.808 4.299-2.929 7.052-2.929 5.514 0 10 4.486 10 10s-4.486 10-10 10c-3.872 0-7.229-2.216-8.89-5.443l-1.717 1.046c2.012 3.803 6.005 6.397 10.607 6.397 6.627 0 12-5.373 12-12s-5.373-12-12-12z" />
    </svg>
  );
};

const RotateRightIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
    >
      <path d="M12 0c3.31 0 6.291 1.353 8.459 3.522l2.48-2.48 1.061 7.341-7.437-.966 2.489-2.489c-1.808-1.807-4.299-2.928-7.052-2.928-5.514 0-10 4.486-10 10s4.486 10 10 10c3.872 0 7.229-2.216 8.89-5.443l1.717 1.046c-2.012 3.803-6.005 6.397-10.607 6.397-6.627 0-12-5.373-12-12s5.373-12 12-12z" />
    </svg>
  );
};
