import * as types from "./kycOperationTypes";

const initialState = {
  isLoading: false,
  allPendingKYCRequests: [],
  pendingKYCRequest: {},
  totalRecords: 0,
};

const kycOperationsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.KYC_OPERATIONS_START:
      return { ...state, isLoading: true };
    case types.KYC_OPERATIONS_FAIL:
      return { ...state, isLoading: false };
    case types.KYC_OPERATIONS_SUCCESS:
      return { ...state, isLoading: false };
    case types.GET_ALL_PENDING_REQUESTS:
      return { ...state, allPendingKYCRequests: payload };
    case types.GET_PENDING_REQUEST:
      return { ...state, pendingKYCRequest: payload };
    case types.SET_DATA_COUNT:
      return {
        ...state,
        isLoading: false,
        totalRecords: payload,
      };
    default:
      return state;
  }
};

export default kycOperationsReducer;
