// import TableDropdown from "./TableDropdown";

const TableHead = ({ headerGroups, onChange, renderHeaderJsx }) => {
  const { targetHeader, jsx } = renderHeaderJsx;
  return (
    <thead>
      {headerGroups.map((headerGroup) => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => {
            return (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render("Header") === targetHeader
                  ? jsx
                  : column.render("Header")}
                <span>
                  {column.isSorted && column.render("Header") !== targetHeader
                    ? column.isSortedDesc
                      ? " 🔽"
                      : " 🔼"
                    : ""}
                </span>
              </th>
            );
            // TODO: - Add dropdown to table
            // <th {...column.getHeaderProps(column.getSortByToggleProps())}>
            //   <div className="thead-container">
            //     <span>{column.render("Header")} &nbsp;</span>
            //     {column.options ? (
            //       <TableDropdown
            //         options={column.options}
            //         setSelectedOption={(e) => {
            //           const val = e?.value ? e.value : e?.name;
            //           onChange(val);
            //         }}
            //       />
            //     ) : (
            //       <span>
            //         {column.isSorted
            //           ? column.isSortedDesc
            //             ? " 🔽"
            //             : " 🔼"
            //           : ""}
            //       </span>
            //     )}
            //   </div>
            // </th>
          })}
        </tr>
      ))}
    </thead>
  );
};

export default TableHead;
