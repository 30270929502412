import styled, { css } from "styled-components";

export const StyledSelect = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width || "300px"};
  position: relative;

  select {
    display: none;
  }

  .label {
    font-weight: normal;
    font-size: 13.5px;
    line-height: 18px;
    color: ${(props) => (props.disabled ? "#000" : "#000")};
    text-transform: capitalize;
    margin-bottom: 10px;
  }

  .selected {
    display: flex;
    align-items: center;
    text-align: left;
    position: relative;
    min-height: 46px;
    height: 46px;
    background: ${(props) => props.theme.colors.kudaWhite};
    border: 1px solid ${(props) => props.theme.colors.kudaLighterGrey};
    color: ${(props) => (props.disabled ? "#444" : "#000")};
    box-sizing: border-box;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 0 15px 0 40px;
    cursor: pointer;
  }

  .selected:after {
    position: absolute;
    content: "";
    top: 50%;
    transform: translateY(calc(-50% - -3px));
    left: 15px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-color: ${(props) => (props.disabled ? "#999" : "#000000")}
      transparent transparent transparent;
  }

  .select-items {
    position: absolute;
    z-index: 1000;
    width: 100%;
    top: ${(props) => (props.label ? "80px" : "50px")};
    background: #ffffff;
    border: 1px solid rgba(219, 220, 224, 0.5);
    box-shadow: ${(props) =>
      props.showBoxShadow ? "15px 30px 40px rgba(64, 25, 109, 0.07)" : "unset"};
    border-radius: 5px;
    padding: 6px;
    cursor: pointer;
    max-height: ${(props) =>
      props?.selectItemMaxHeight ? props?.selectItemMaxHeight : "208px"};
    overflow: scroll;
  }

  .select-items .select-items-options {
    display: flex;
    padding: 15px;
    min-height: 49px;
    height: fit-content;
    line-height: 1.3;
    border-bottom: 1px solid ${(props) => props.theme.colors.kudaLighterGrey};

    &:hover {
      background: ${(props) => props.theme.colors.kudaLightGrey};
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  .select-items-options {
    align-items: center;
    font-size: 0.925rem;

    img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin-right: 10px;
    }

		.disabled-option {
			color: rgba(151, 151, 151, 1);
			cursor: not-allowed;
		}
  }

  .select-search {
    width: 100%;
    padding: 0px;
    align-items: center;
    border-radius: 5px;
    background: #ffffff;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    height: 100%;
    margin-left: 0;
    padding-left: 0;

    svg {
      width: 20px;
      height: 20px;
      padding-right: 8px;
    }

    input {
      background: transparent;
      color: #000000;
      font-style: normal;
      font-weight: 400;
      outline: none;
      border: none;
      flex: 1;
      line-height: 1.3;
      height: 100%;
    }
  }

	.select-items-options_subtext {
		margin-top: 0.25rem;
		color: rgba(151, 151, 151, 1);
		font-size: 11px;
		line-height: 12.55px;
	}

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.6;
    `};
`;
