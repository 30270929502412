import { ModifiedMain } from "layout/Main";
import React, { useContext, useState } from "react";
import {
  fraudTransactionsReportHeaderLinks,
  manageFlaggedTransactionsHeaderLinks,
  paths,
} from "../HeaderLinks";
import { useParams, useHistory } from "react-router-dom";
import {
  StyledDetails,
  StyledFraudMgmtWrapper,
  StyledTransactionDetailsRow,
} from "../shared/styles/home.styles";
import { CopyIcon } from "../shared/icons";
import Icon from "library/Icons/Icon";
import { ToastContext } from "context/ToastContext";
import moment from "moment";
import { formatToNaira } from "utils/utilityFunctions";
import { getAccountType, getPNDType } from "../shared/utils";

const toastConfig = {
  error: false,
  autoDismiss: true,
};

const ReviewFraudTransactionsReport = () => {
  const { id } = useParams();
  const history = useHistory();
  const locationState = history?.location?.state;
  const toastContext = useContext(ToastContext);

  const handleCopy = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      toastContext.setToast("Account number copied to clipboard", toastConfig);
    } catch (err) {
      toastConfig.error = true;
      toastContext.setToast("Error copying to clipboard", toastConfig);
    }
  };

  const parseDepositData = (jsonString) => {
    try {
      const data = JSON.parse(jsonString);
      return {
        maxDeposit: Number(data.MaxDeposit),
        currentDeposit: Number(data.CurrentDeposit),
      };
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return null;
    }
  };

  return (
    <ModifiedMain
      childSubRoute
      links={[
        ...fraudTransactionsReportHeaderLinks,
        {
          path: paths.reviewFraudTransactionsReport,
          name: "Review",
          pathAction: () => {
            history.push(`/fraud-management/fraud-transactions/review/${id}`, {
              initResponse: locationState?.initResponse,
            });
          },
        },
      ]}
    >
      <StyledFraudMgmtWrapper>
        <div className="top-header no-bp">
          <div className="header no-bp">
            <div className="base-header">
              <h1>{locationState?.initResponse?.accountName} </h1>
              <div className="base-info">
                <p>{locationState?.initResponse?.accountNumber}</p>{" "}
                <div
                  className="copy-svg"
                  onClick={() => {
                    handleCopy(locationState?.initResponse?.accountNumber);
                  }}
                >
                  <Icon svg={CopyIcon} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="divider" />

        <div className="stickyWrapper">
          <StyledDetails>
            <div className="top-header">
              <h1>Account Details</h1>
            </div>

            <div className="divider" />

            <StyledTransactionDetailsRow>
              <div className="info">
                <span>Account Name</span>
                <p>{locationState?.initResponse?.accountName}</p>
              </div>
              <div className="info">
                <span>Account Number</span>
                <p>{locationState?.initResponse?.accountNumber}</p>
              </div>
              <div className="info">
                <span>Transaction Reference</span>
                <p>{locationState?.initResponse?.referenceNumber ?? "N/A"}</p>
              </div>
            </StyledTransactionDetailsRow>

            <div className="divider" />

            <StyledTransactionDetailsRow>
              <div className="info">
                <span>Creation Date</span>
                <p>
                  {moment(locationState?.initResponse?.creationDate).format(
                    "Do MMMM, YYYY"
                  )}
                </p>
              </div>
              <div className="info">
                <span>BVN</span>
                <p>{locationState?.initResponse?.bvn}</p>
              </div>
              <div className="info">
                <span>Email</span>
                <p>{locationState?.initResponse?.email}</p>
              </div>
            </StyledTransactionDetailsRow>

            {/* <div className="divider" />

            <StyledTransactionDetailsRow>
              <div className="info">
                <span>Tier Level</span>
                <p>{locationState?.initResponse?.tierLevel}</p>
              </div>
              <div className="info">
                <span>Account Type</span>
                <p>
                  {getAccountType(locationState?.initResponse?.accountType)}
                </p>
              </div>
              <div className="info">
                <span>Rule Type</span>
                <p>{locationState?.initResponse?.ruleType}</p>
              </div>
            </StyledTransactionDetailsRow> */}

            <div className="divider" />

            <StyledTransactionDetailsRow>
              <div className="info">
                <span>Transaction Amount</span>
                <p>
                  {locationState?.initResponse?.amount
                    ? formatToNaira(locationState?.initResponse?.amount)
                    : locationState?.initResponse?.amount === 0
                    ? "₦0.00"
                    : "N/A"}
                </p>
              </div>
              <div className="info">
                <span>PND Type</span>
                <p>{getPNDType(locationState?.initResponse?.pndType)}</p>
              </div>
              <div className="info">
                <span>Has Lien</span>
                <p>{locationState?.initResponse?.hasLien ? "True" : "False"}</p>
              </div>
            </StyledTransactionDetailsRow>

            <div className="divider" />

            <StyledTransactionDetailsRow>
              <div className="info">
                <span>Status</span>

                <div className="status-holder">
                  <p
                    className={`${
                      locationState?.initResponse?.status?.toLowerCase() ===
                      "open"
                        ? "green-dot"
                        : "red-dot"
                    } status-dot`}
                  />{" "}
                  <p>{locationState?.initResponse?.status}</p>
                </div>
              </div>

              <div className="info">
                <span>Tier Level</span>
                <p>{locationState?.initResponse?.tierLevel}</p>
              </div>

              <div className="info">
                <span>Rule Type</span>
                <p>{locationState?.initResponse?.ruleType}</p>
              </div>
            </StyledTransactionDetailsRow>

            <div className="divider" />

            <div className="top-header mt-32">
              <h1>Transaction Meta Data</h1>
            </div>

            <div className="divider" />

            <StyledTransactionDetailsRow>
              <div className="info">
                <span>Max Deposit</span>
                <p>
                  {formatToNaira(
                    parseDepositData(locationState?.initResponse?.metaData)
                      .maxDeposit
                  )}
                </p>
              </div>
              <div className="info">
                <span>Current Deposit</span>
                <p>
                  {formatToNaira(
                    parseDepositData(locationState?.initResponse?.metaData)
                      .currentDeposit
                  )}
                </p>
              </div>
            </StyledTransactionDetailsRow>

            <div className="divider" />

            <div className="top-header mt-32">
              <h1>Counter Party Details</h1>
            </div>

            <div className="divider" />

            <StyledTransactionDetailsRow>
              <div className="info">
                <span>Bank Name</span>
                <p>
                  {locationState?.initResponse?.counterPartyInstitutionName ??
                    "N/A"}
                </p>
              </div>
              <div className="info">
                <span>Account Name</span>
                <p>
                  {locationState?.initResponse?.counterPartyAccountName ??
                    "N/A"}
                </p>
              </div>
              <div className="info">
                <span>Account Number</span>
                <p>
                  {locationState?.initResponse?.counterPartyAccountNumber ??
                    "N/A"}
                </p>
              </div>
            </StyledTransactionDetailsRow>
          </StyledDetails>
        </div>
      </StyledFraudMgmtWrapper>
    </ModifiedMain>
  );
};

export default ReviewFraudTransactionsReport;
