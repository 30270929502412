import React, { useRef, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import {
  StyledModalBackground,
  StyledModalContent,
  StyledModalMain,
} from "./Modal.styles";
import ModalFooter from "./ModalFooter";
import ModalHeader from "./ModalHeader";

const Modal = ({
  showModal,
  setShowModal,
  children,
  handleClose,
  modalTitle,
  btnPrimary,
  btnSecondary,
  width,
  disableClickOutside,
  bckBtn,
  backAction,
  overflow = false,
  btnEqualWidth = false,
  maxHeight,
  showModalHeader = true,
  showCancelIcon = false,
}) => {
  const modalRef = useRef();
  const closeModal = (e) => {
    if (modalRef.current === e.target && !disableClickOutside) {
      setShowModal(false);
      if (handleClose) {
        handleClose();
      }
    }
  };

  const keyPress = useCallback(
    (e) => {
      if (!disableClickOutside && e.key === "Escape" && showModal) {
        setShowModal(false);
      }
    },
    [setShowModal, showModal, disableClickOutside]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  const handleCloseModal = () => {
    if (!disableClickOutside || showCancelIcon) {
      setShowModal((prev) => !prev);
      if (handleClose) {
        handleClose();
      }
    }
  };

  return (
    <>
      {showModal ? (
        <StyledModalBackground id="modal" onClick={closeModal} ref={modalRef}>
          <StyledModalMain showModal={showModal} width={width}>
            {showModalHeader && (
              <>
                <ModalHeader
                  modalTitle={modalTitle}
                  closeModal={handleCloseModal}
                  disableClickOutside={disableClickOutside}
                  bckBtn={bckBtn}
                  backAction={backAction}
                  showCancelIcon={showCancelIcon}
                />
                <hr className="modal-hr" />
              </>
            )}
            <StyledModalContent overflow={overflow} maxHeight={maxHeight}>
              {children}
            </StyledModalContent>
            {(btnPrimary || btnSecondary) && (
              <ModalFooter
                btnPrimary={btnPrimary}
                btnSecondary={btnSecondary}
                btnEqualWidth={btnEqualWidth}
              />
            )}
          </StyledModalMain>
        </StyledModalBackground>
      ) : null}
    </>
  );
};

Modal.propTypes = {
  width: PropTypes.string,
  modalTitle: PropTypes.any,
  maxHeight: PropTypes.string,
};

Modal.defaultProps = {
  modalTitle: "Default Modal",
  disableClickOutside: false,
  maxHeight: undefined,
};

export default Modal;
