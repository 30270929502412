export const getIncomeSourceValue = (value) => {
  switch (value.toString()) {
    case "1":
      return "Savings";
    case "2":
      return "Salary";
    case "3":
      return "Investment";
    case "4":
      return "Business Income";
    case "5":
      return "Pension";
    case "6":
      return "Will Or Inheritance";
    case "Savings":
      return "Savings";
    case "Salary":
      return "Salary";
    case "Investment":
      return "Investment";
    case "BusinessIncome":
      return "Business Income";
    case "Pension":
      return "Pension";
    case "WillOrInheritance":
      return "Will Or Inheritance";
    default:
      return value;
  }
};

export const getPurposeOfFund = (value) => {
  switch (value.toString()) {
    case "1":
      return "Gift And Rewards";
    case "2":
      return "Purchase Of Item";
    case "3":
      return "Payment For A Service Rendered";
    case "GiftAndRewards":
      return "Gift And Rewards";
    case "PurchaseOfItem":
      return "Purchase Of Item";
    case "PaymentForAServiceRendered":
      return "Payment For A Service Rendered";
    default:
      return value;
  }
};

export const getAccountType = (value) => {
  switch (value.toString()) {
    case "1":
      return "Migrated";
    case "2":
      return "Personal";
    case "3":
      return "Organization";
    case "4":
      return "Joint";
    case "5":
      return "Fixed Deposit";
    case "6":
      return "Loan";
    case "7":
      return "Dynamic Virtual Account";
    case "8":
      return "Smart Overdraft";
    case "Migrated":
      return "Migrated";
    case "Personal":
      return "Personal";
    case "Organization":
      return "Organization";
    case "Joint":
      return "Joint";
    case "FixedDeposit":
      return "Fixed Deposit";
    case "Loan":
      return "Loan";
    case "DynamicVirtualAccount":
      return "Dynamic Virtual Account";
    case "SmartOverdraft":
      return "Smart Overdraft";
    default:
      return value;
  }
};

export const getPNDType= (value) => {
  switch (value.toString()) {
    case "SuspiciousDeposits":
      return "Suspicious Deposits";
    case "SuspiciousOutflow":
      return "Suspicious Out flow";
    default:
      return value;
  }
};
