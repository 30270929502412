import styled from "styled-components";

export const StyledFraudHome = styled.div`
  width: 50%;
  @media screen and (max-width: 80rem) {
    width: 80%;
  }
  @media screen and (max-width: 70rem) {
    width: 100%;
  }
`;

export const StyledFraudMgmtWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.noGap ? "0px" : "24px")};
  position: relative;
  padding: 0;
  margin: 0;

  .heading {
    position: sticky;
    top: 0px;
    width: 100%;
    background-color: #ffffff;
    z-index: 1;
  }

  .top-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 24px;

    &.mt-32 {
      margin-top: 32px;
    }

    &.no-bp {
      padding-bottom: 0px;
    }

    h1 {
      font-style: normal;
      font-weight: 900;
      font-size: 20px;
      line-height: 25px;
      color: #121212;
    }
  }

  .action-holders {
    display: flex;
    align-items: center;

    button {
      &:first-child {
        margin-right: 16px;
      }
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 18px;

    &.no-bp {
      padding-bottom: 0px;
    }

    .base-header,
    .base-info {
      display: flex;
      align-items: baseline;
    }

    .base-info {
      padding-left: 8px;

      p {
        padding-right: 4px;
      }

      .copy-svg {
        cursor: pointer;
      }
    }

    h1 {
      font-style: normal;
      font-weight: 900;
      font-size: 20px;
      line-height: 25px;
      color: #121212;
    }
  }

  .tab-group {
    padding-top: 24px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 18px;

    h1 {
      font-style: normal;
      font-weight: 900;
      font-size: 20px;
      line-height: 25px;
      color: #121212;
    }
  }

  .divider {
    height: 1px;
    background-color: #dbdce0;
  }

  @media screen and (max-width: 450px) {
    max-height: calc(100vh - 180px);
    background-color .top-header {
      flex-direction: column;
      align-items: start;
      gap: 10px;
    }
  }
`;

export const StyledFraudTableFilterExtra = styled.div`
  .table-extras {
    display: flex;
    align-items: center;
    margin-right: 10px;

    .extra-text {
      font-weight: 700;
      font-size: 11px;
      line-height: 13px;
      color: #000000;
      padding-right: 12px;
    }
  }
`;

export const StyledDetails = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid rgba(219, 220, 224, 0.5);
  box-shadow: 0px 0px 25px 0px rgba(64, 25, 109, 0.06);
  border-radius: 5px;
  padding: 32px;
  animation: fadeInUp 1s forwards;
	margin-top: ${(props) => (props.gapTop ? "24px" : "0px")};

  .top-header {
    h1 {
      font-size: 17px;
      font-style: normal;
      font-weight: 900;
      line-height: 20px; /* 117.647% */
      color: #000000;
      padding-bottom: 6px;
    }
  }

  .with-indicator {
    display: flex;
    align-items: center;
    gap: 4px;

    .indicator {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: orange;
    }
  }

  &.mb-18 {
    margin-bottom: 18px;
  }

  &.mt-18 {
    margin-top: 18px;
  }

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(30px);
    }

    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  .business-header {
    p {
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      text-transform: uppercase;
      color: #979797;
      margin-bottom: 8px;
    }

    h1 {
      font-style: normal;
      font-weight: 800;
      font-size: 20px;
      line-height: 25px;
      color: #000000;
    }
  }

  & > h1 {
    font-style: normal;
    font-weight: 900;
    font-size: 17px;
    line-height: 20px;
    color: #000000;
  }

  .mt {
    margin-top: 30px;
  }

  .account-actions {
    display: inline-grid;
    grid-template-columns: repeat(auto-fit, minmax(158px, 180px));
    gap: 20px;
    margin-bottom: 20px;
  }

  .divider {
    height: 1px;
    background-color: #dbdce0;
  }

  @media screen and (max-width: 680px) {
    .main-content {
      flex-direction: column;
      align-items: start;
      gap: 40px;
    }

    .profile-picture {
      width: 160px;
      max-width: 100%;
      text-align: left;
    }

    .details {
      flex: 1;
      width: 100%;
    }
  }

  @media screen and (max-width: 430px) {
    .details .detail {
      display: block !important;
    }

    .details .detail .info span {
      margin: 0px;
    }

    .details .detail :last-child {
      text-align: left !important;
      margin-top: 5px;
    }
  }

  .main-content {
    display: flex;
    align-items: flex-start;
    gap: 40px;
    flex: 1;

    .profile-picture {
      width: 160px;
      max-width: 100%;
      text-align: center;
    }

    .profile-picture > figure {
      width: 100%;
      height: 176px;
      border-radius: 5px;
      background: #d9d9d9;
      display: flex;

      svg {
        margin: auto;
      }

      img {
        width: 100%;
        border-radius: 5px;
      }
    }

    .profile-picture > p {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      margin-top: 5px;
    }

    .details {
      flex: 1;
    }

    .details .detail {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
    }

    .details .detail > :last-child {
      text-align: end;
    }

    .details .detail .info span {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      color: #979797;
      margin-bottom: 5px;
      display: inline-block;
    }

    .details .detail .info p {
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: #000000;
    }

    .details .detail .info p.username i {
      color: #979797;
    }

    .details .divider {
      margin-bottom: 10px;
    }
  }
`;

export const StyledTransactionDetailsRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 5px;
  padding-top: 24px;
  padding-bottom: 16px;

  .info span {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #979797;
    margin-bottom: 5px;
    display: inline-block;
  }

  .info p {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #000000;
    word-break: break-all;
  }

  .status-holder {
    display: flex;
    align-items: center;

    .status-dot {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      margin-right: 6.5px;

      &.green-dot {
        background: #48d38a;
      }

      &.red-dot {
        background: red;
      }
    }
  }

  .link-text {
    color: #000000;
    text-transform: capitalize;
    cursor: pointer;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 15px;
    line-height: 20px;
    text-decoration: none;

    .icon-text {
      color: #000000 !important;
    }

    svg {
      width: 14px;
    }
  }
`;

export const StyledQuickInfo = styled.div`
  display: flex;
  align-items: center;
  background: #eff1ff;
  border-radius: 4px;
  padding: 14px 18px;
  width: fit-content;
  margin: 42px auto;

  .icon-svg {
    padding-right: 11px;
  }

  p {
    font-size: 13px;
    line-height: 20px;
    font-weight: 600;
    color: #000000;
  }

  .cta {
    font-weight: 800;
    text-decoration: underline;
    padding-left: 6px;
    width: fit-content;
    cursor: pointer;
    color: #40196d;
  }
`;

export const StyledSuccessModal = styled.div`
  width: 405px;
  max-width: 100%;
  height: auto;
  background: #ffffff;
  border: 1px solid rgba(219, 220, 224, 0.5);
  box-shadow: 15px 30px 40px rgba(64, 25, 109, 0.07);
  border-radius: 6px;
  padding: 46px 40px;
  display: flex;
  flex-direction: column;
  animation: fadeInUp 0.5s forwards;

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(30px);
    }

    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  .card-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    h2 {
      font-style: normal;
      font-weight: 800;
      font-size: 20px;
      line-height: 25px;
      text-align: center;
      color: #000000;
    }

    .icon {
      margin: 44px 0px;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      text-align: center;
      color: #000000;
      margin-bottom: 30px;

      span {
        font-weight: 800;
      }
    }

    .error-message {
      margin-bottom: 20px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #000000;
    }
  }

  .ticket-title {
    margin-top: 10px;
    font-style: normal;
    font-weight: 800;
    font-size: 13px;
    line-height: 18px;
    color: #000000;
  }

  .ticket-box {
    background: #eff1ff;
    display: flex;
    width: 236.3px;
    margin-inline: auto;
    justify-content: space-between;
    padding: 16px 13px;
    margin: 10px 0 30px;
    border-radius: 5px;

    .ticket-id {
      font-style: normal;
      font-weight: 900;
      font-size: 13px;
      line-height: 25px;
      color: #40196d;
    }

    .icon-box {
      display: flex;
      column-gap: 5px;
      align-items: center;
      cursor: pointer;
      min-height: 29px;

      .text {
        font-style: normal;
        font-weight: 700;
        font-size: 11px;
        line-height: 13px;
        color: #40196d;
      }
    }
  }

  footer {
    display: flex;
    height: 48px;
    gap: 20px;
    justify-content: center;
    margin-top: 20px;

    button {
      background: #f9f9f9;
      border: 1px solid rgba(64, 25, 109, 0.3);
      border-radius: 8px;
      cursor: pointer;
      transition: 0.4s;
      color: #40196d;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      width: 145px;
    }

    button:active {
      transform: scale(0.9);
    }

    button:disabled {
      opacity: 0.5;
      cursor: default;
      transform: none;
    }

    button.btn-primary {
      background: #40196d;
      border-color: #40196d;
      color: #fff;
    }

    button.btn-danger {
      background: #f7685b;
      border-color: #f7685b;
      color: #fff;
    }
  }
`;

export const StyledManageActionModalCard = styled.div`
  width: 415px;
  max-width: 100%;
  height: 424px;
  background: #ffffff;
  border: 1px solid rgba(219, 220, 224, 0.5);
  box-shadow: 15px 30px 40px rgba(64, 25, 109, 0.07);
  border-radius: 6px;
  padding: 46px 40px;
  display: flex;
  flex-direction: column;
  animation: fadeInUp 0.5s forwards;

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(30px);
    }

    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  .card-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    h2 {
      font-style: normal;
      font-weight: 800;
      font-size: 20px;
      line-height: 25px;
      text-align: center;
      color: #000000;
    }

    .icon {
      margin: 44px 0px;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      text-align: center;
      color: #000000;
    }
  }

  footer {
    display: flex;
    height: 48px;
    gap: 20px;

    button {
      flex: 1;
      background: #f9f9f9;
      border: 1px solid rgba(64, 25, 109, 0.3);
      border-radius: 8px;
      cursor: pointer;
      transition: 0.4s;
      color: #40196d;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
    }

    button:active {
      transform: scale(0.9);
    }

    button:disabled {
      opacity: 0.5;
      cursor: default;
      transform: none;
    }

    button.btn-primary {
      background: #40196d;
      border-color: #40196d;
      color: #fff;
    }

    button.btn-danger {
      background: #f7685b;
      border-color: #f7685b;
      color: #fff;
    }
  }
`;
