import styled from "styled-components";

export const StyledDashboard = styled.div`
  position: relative;
  height: 100%;

  .dashboard-header {
    display: flex;
    position: fixed;
    align-items: center;
    height: 70px;
    width: 100%;
    padding: 0 36px;
    background: #40196d;
    z-index: 2;
  }

  .dashboard-body {
    display: flex;
    position: relative;
    width: 100%;
  }
`;
