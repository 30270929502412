export const CARD_START = "CARD_START";
export const CARD_FAIL = "CARD_FAIL";
export const CARD_SUCCESS = "CARD_SUCCESS";

export const CUSTOMER_SEARCH = "CUSTOMER_SEARCH";

export const CARD_SEARCH = "CARD_SEARCH";

export const REQUEST_CARD_BLOCK = "REQUEST_CARD_BLOCK";

export const GET_CARD_BLOCK_LOGGED_FOR_APPROVAL =
  "GET_CARD_BLOCK_LOGGED_FOR_APPROVAL";

export const TREAT_CARD_BLOCK = "TREAT_CARD_BLOCK";

export const REQUEST_CARD_UNBLOCK = "REQUEST_CARD_UNBLOCK";

export const GET_CARD_UNBLOCK_LOGGED_FOR_APPROVAL =
  "GET_CARD_UNBLOCK_LOGGED_FOR_APPROVAL";

export const GET_BATCHES = "GET_BATCHES";

export const GET_BATCH_DETAILS = "GET_BATCH_DETAILS";

export const TREAT_CARD_UNBLOCK = "TREAT_CARD_UNBLOCK";

export const REQUEST_CARD_DEACTIVATE = "REQUEST_CARD_DEACTIVATE";

export const GET_CARD_DEACTIVATES_LOGGED_FOR_APPROVAL =
  "GET_CARD_DEACTIVATES_LOGGED_FOR_APPROVAL";

export const TREAT_CARD_DEACTIVATE = "TREAT_CARD_DEACTIVATE";

export const UPLOAD_NI_FILE = "UPLOAD_NI_FILE";

export const UPLOAD_REISSUANCE_FILE = "UPLOAD_REISSUANCE_FILE";

export const UPLOAD_DEACTIVATION_FILE = "UPLOAD_DEACTIVATION_FILE";

export const UPLOAD_SORTING_FOR_DELIVERY = "UPLOAD_SORTING_FOR_DELIVERY";

export const UPLOAD_SENT_FOR_DELIVERY = "UPLOAD_SENT_FOR_DELIVERY";
export const GET_IMPORT_HISTORY = "GET_IMPORT_HISTORY";

export const CLEAR_RECORD = "CLEAR_RECORD";

export const UPLOAD_CARDUPDATE_FILE = "UPLOAD_CARDUPDATE_FILE";
export const GET_FUNDWITHCARD_TRANSACTION_DETAILS =
  "GET_FUNDWITHCARD_TRANSACTION_DETAILS";
