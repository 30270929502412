import Loader from "library/Loader";
import styled from "styled-components";
import { StyledManageActionModalCard } from "../styles/home.styles";


const StyledModalBackground = styled.div`
  position: fixed;
  overflow: hidden;
  top: 100;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(64, 25, 109, 0.2);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5em 1em;
  z-index: 99999;
  box-sizing: border-box;
  overflow: scroll;
`;

const ManageActionModal = ({
  showModal,
  setShowModal = () => {},
  btnClassName,
  children,
  onSubmit = () => {},
  primaryBtnLabel = "Click me",
  isLoading = false,
}) => {
  const handleClick = (evt) => {
    if (evt.target.id === "modalbg") setShowModal(false);
  };

  return (
    <StyledModalBackground id="modalbg" onClick={handleClick}>
      <StyledManageActionModalCard>
        <div className="card-content">{children}</div>

        <footer>
          <button onClick={() => setShowModal(false)}>
            <span>Cancel</span>
          </button>
          <button
            className={btnClassName ? btnClassName : "btn-primary"}
            onClick={onSubmit}
            disabled={isLoading}
          >
            {isLoading ? (
              <Loader variant="white" />
            ) : (
              <span>{primaryBtnLabel}</span>
            )}
          </button>
        </footer>
      </StyledManageActionModalCard>
    </StyledModalBackground>
  );
};

export default ManageActionModal;
