export const formatReferralData = (data, newData) => {
  const existingData = data[newData.payoutType];
  if (!existingData) {
    const formatData = {
      ...data,
      [newData.payoutType]: {
        ...newData,
        data: newData.data,
      },
    };
    return formatData;
  }
  const formatData = {
    ...data,
    [newData.payoutType]: {
      ...newData,
      data: [...existingData.data, ...newData.data].filter((value, index)=> data.indexOf(value) !==index)
    },
  };
  return formatData;
};

export const formatAllCampaignData = (existingData, newData) => {
  return {
    ...newData,
    data: [...existingData.data, ...newData.data],
  };
};
