export const CREDIT_START = "CREDIT_START";
export const CREDIT_FAIL = "CREDIT_FAIL";
export const CREDIT_SUCCESS = "CREDIT_SUCCESS";

export const OVERDRAFT_HISTORY = "OVERDRAFT_HISTORY";
export const OVERDRAFT_HISTORY_DATA_COUNT = "OVERDRAFT_HISTORY_DATA_COUNT";
export const OVERDRAFT_SEARCH = "OVERDRAFT_SEARCH";

export const UPLOAD_OVERDRAFT_PREQUALIFICATION_FILE =
  "UPLOAD_OVERDRAFT_PREQUALIFICATION_FILE";

export const UPLOAD_BATCH_OVERDRAFT_UPDATE_FILE =
  "UPLOAD_BATCH_OVERDRAFT_UPDATE_FILE";

export const SALARY_LOANS_REQUESTS = "SALARY_LOANS_REQUESTS";
export const SALARY_LOANS_SINGLE_REQUEST = "SALARY_LOANS_SINGLE_REQUEST";

export const LOAN_REQUESTS_WITH_OFFER = "LOAN_REQUESTS_WITH_OFFER";
export const SINGLE_LOAN_REQUEST_WITH_OFFER = "SINGLE_LOAN_REQUEST_WITH_OFFER";

export const COMPANIES_PROFILES_LIST = "COMPANIES_PROFILES_LIST";
export const COMPANY_PROFILE = "COMPANY_PROFILE";

export const LOAN_PRODUCTS = "LOAN_PRODUCTS";
export const SAVE_LOAN_APPLICATIONS = "SAVE_LOAN_APPLICATIONS";
export const SAVE_LOAN_UNDERWRITTING_REVIEW = "SAVE_LOAN_UNDERWRITTING_REVIEW";
export const CLEAR_LOAN_UNDERWRITTING_REVIEW =
  "CLEAR_LOAN_UNDERWRITTING_REVIEW";
