import React, { Fragment } from "react";
import { initializeApp } from "firebase/app";
import { getRemoteConfig } from "firebase/remote-config";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
	apiKey: `${process.env.REACT_APP_FIREBASE_KEY}`,
	authDomain: `${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}`,
	databaseURL: `${process.env.REACT_APP_FIREBASE_URL}`,
	projectId: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
	storageBucket: `${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}`,
	messagingSenderId: `${process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID}`,
	appId: `${process.env.REACT_APP_FIREBASE_APP_ID}`,
	measurementId: `${process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}`,
};

// INITIALIZE FIREBASE
const app = initializeApp(firebaseConfig);

// INITIALIZE ANALYTICS AND REMOTE CONFIG
getAnalytics();
export const remoteConfig = getRemoteConfig();
export const fireStoreDB = getFirestore(app);

remoteConfig.settings = {
	minimumFetchIntervalMillis: 360000,
};

const Bootstrap = ({ children }) => {
	return <Fragment>{children}</Fragment>;
};
export default Bootstrap;
