import * as types from "./terminalMgmtTypes";

const initialState = {
  isLoading: false,
  merchants: [],
  isLoadingMerchant: false,
  uploadBulkMerchantsData: null,
  uploadBulkMerchantsColumnHeaders: [],
  uploadBulkMerchantsErrorRows: [],
  uploadBulkMerchantsErrorRowsSN: [],
  uploadWhitelistTerminalData: null,
  uploadWhitelistTerminalColumnHeaders: [],
  uploadWhitelistTerminalErrorRows: [],
  uploadWhitelistTerminalErrorRowsSN: [],
};

const terminalMgmtReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.TERMINAL_MGMT_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.TERMINAL_MGMT_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case types.TERMINAL_MGMT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case types.TERMINAL_MGMT_MERCHANTS:
      return {
        ...state,
        isLoading: false,
        merchants: payload,
      };
    case types.TERMINAL_MGMT_UPDATE_LOADING_MERCHANT:
      return {
        ...state,
        isLoadingMerchant: payload,
      };
    case types.UPLOAD_BULK_MERCHANTS_FILE:
      return {
        ...state,
        isLoading: false,
        uploadBulkMerchantsData: payload.uploadBulkMerchantsData,
        uploadBulkMerchantsColumnHeaders:
          payload.uploadBulkMerchantsColumnHeaders,
        uploadBulkMerchantsErrorRows: payload.uploadBulkMerchantsErrorRows,
        uploadBulkMerchantsErrorRowsSN: payload.uploadBulkMerchantsErrorRowsSN,
      };
    case types.UPLOAD_WHITELIST_TERMINAL_FILE:
      return {
        ...state,
        isLoading: false,
        uploadWhitelistTerminalData: payload.uploadWhitelistTerminalData,
        uploadWhitelistTerminalColumnHeaders:
          payload.uploadWhitelistTerminalColumnHeaders,
        uploadWhitelistTerminalErrorRows:
          payload.uploadWhitelistTerminalErrorRows,
        uploadWhitelistTerminalErrorRowsSN:
          payload.uploadWhitelistTerminalErrorRowsSN,
      };
    default:
      return state;
  }
};

export default terminalMgmtReducer;
