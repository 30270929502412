const TableBody = ({
  page,
  prepareRow,
  getTableBodyProps,
  errorRowsSN,
  disableKey = "",
	noCapitalizeTd
}) => {
  return (
    <tbody {...getTableBodyProps()}>
      {page.map((row, i) => {
        prepareRow(row);

        const rowProps = row.getRowProps();
        if (errorRowsSN.includes(row.original["S/N"])) {
          rowProps.style = {
            ...rowProps.style,
            backgroundColor: "rgba(255, 219, 219, 0.3)",
          };
        }

        if (disableKey && row?.original?.[disableKey]) {
          rowProps.style = {
            ...rowProps.style,
            opacity: 0.5,
            'pointer-events': 'none'
          };

          rowProps.disabled = "true";
        }

        return (
          <tr {...rowProps}>
            {row.cells.map((cell) => {
              return (
                <td {...cell.getCellProps()} className={`${noCapitalizeTd ? "no-capitalize" : ""}`}>
                  <span>{cell.render("Cell")}</span>
                </td>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );
};

export default TableBody;
