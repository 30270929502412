import styled from "styled-components";
import breakpoint from "../../library/breakpoint";

const StyledCard = styled.div`
  .card-container {
    margin-top: 35px;
    /* width: 45%; */

    @media ${breakpoint.device.tablet} {
      width: 100%;
    }
  }

  .card {
    display: flex;
    align-items: center;
    width: auto;
    background: #ffffff;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    padding: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    transition: all 0.6s;
    position: relative;

    .greyed-out {
      background: rgba(255, 255, 255, 0.4);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:not(.disabled):hover {
      transform: translate3d(0px, -3px, 0px);
      opacity: 0.9;
    }

    .left-icon {
      margin-right: 16px;
    }

    .right-icon {
      margin-left: 10px;
    }

    .content {
      flex-grow: 2;
      h2 {
        font-weight: 900;
        font-size: 15px;
        margin-bottom: 5px;
      }

      p {
        font-weight: normal;
        font-size: 13px;
      }
    }
  }
`;

export default StyledCard;
