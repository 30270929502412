import React, { useState, useEffect, useRef } from 'react';
import { DropdownButton, DropdownContainer, DropdownMenu, DropdownMenuItem, Icon } from './Dropdown.styles';

const IconImage = ({ src }) => (
    <Icon src={src} />
);

const Dropdown = ({ filterName, items, onSelect }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const dropdownRef = useRef(null);
    const handleSelect = (item) => {
        setSelectedItem(item.value);
        onSelect(item.value);
        setIsOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <DropdownContainer ref={dropdownRef}>
            <DropdownButton className="input-field smaller-text flex" onClick={() => setIsOpen(!isOpen)}>
                {selectedItem ? items.find(item => item.value === selectedItem)?.label : `All ${filterName}`}
            </DropdownButton>
            {isOpen && (
                <DropdownMenu>
                    {items.map((item, index) => (
                        <DropdownMenuItem key={item.value} onClick={() => handleSelect(item)} className={`${index === 0 ? "grey-bg" : ""}`}>
                            {item.icon && <item.icon />}
                            {item.label}
                        </DropdownMenuItem>
                    ))}
                </DropdownMenu>
            )}
        </DropdownContainer>
    );
};

export default Dropdown;
