import React, { Suspense, useState } from "react";
import { StyledDashboardLayout } from "./dashboard.layout.styles";
import { KudaSuiteLogo } from "components/SvgPack";
import Burger from "layout/Burger";
import SideBar from "layout/SideBar";
import SuspenseLoader from "library/SuspenseLoader";

const DashboardLayout = ({ children }) => {
  const sideBarWidth = "264px";

  const [mobileNavBar, toggleMobileNavBar] = useState(false);

  const handleNavBar = () => {
    toggleMobileNavBar(!mobileNavBar);
  };

  return (
    <StyledDashboardLayout>
      <div className="dashboard-header">
        <KudaSuiteLogo />
        <Burger onClick={handleNavBar} mobileNavBar={mobileNavBar} />
      </div>
      <main>
        <SideBar
          sideBarWidth={sideBarWidth}
          mobileNavBar={mobileNavBar}
          toggleMobileNavBar={toggleMobileNavBar}
        />

        <Suspense
          fallback={
            <div style={{ marginTop: "120px" }}>
              <SuspenseLoader variant={"purple"} />
            </div>
          }
        >
          {children}
        </Suspense>
      </main>
    </StyledDashboardLayout>
  );
};

export default DashboardLayout;
