import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Dashboard from "layout/Dashboard";
import { routes } from "modules/FraudManagement/routes";

const FraudManagement = () => {
  return (
    <Dashboard>
      <Switch>
        <Route exact path="/terminal">
          <Redirect to="/terminal-management" />
        </Route>

        {routes.map((route, index) => (
          <Route
            key={`${index}-terminal-management`}
            exact={route.exact}
            path={route.path}
            component={route.page}
          />
        ))}
      </Switch>
    </Dashboard>
  );
};

export default FraudManagement;
