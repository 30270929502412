import styled, { css } from "styled-components";

export const StyledInput = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.width ? props.width : "100%")};

  label {
    font-weight: normal;
    font-size: 13.5px;
    line-height: 18px;
    color: ${(props) =>
      props.disabled ? "#000" : props.theme.colors.kudaBlack};
    margin-bottom: 10px;
    text-transform: capitalize;
  }

  .input-wrap {
    height: 46px;
    display: flex;
    width: 100%;
    position: relative;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
  }

  .flex-grow {
    width: 100%;
    flex-grow: 1;
  }

  input {
    background: ${(props) => props.theme.colors.kudaWhite};
    height: 46px;
    width: 100%;
    border: 1px solid ${(props) => props.theme.colors.kudaLighterGrey};
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 15px 10px;
    outline: none;
    transition: all 0.6s;
    width: 100%;
    &:hover,
    &:focus {
      border: 1px solid rgba(64, 25, 109, 0.3);
    }
  }

  input::placeholder {
    color: #cacaca;
  }

  ${(props) =>
    props.size === "lg" &&
    css`
      min-width: ${(props) => (props.width ? props.width : "520px")};
    `}

  ${(props) =>
    props.size === "message" &&
    css`
      text-align: center;
    `}

  ${(props) =>
    props.icon?.length &&
    css`
      input {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
      }
    `};

  ${(props) =>
    props.error &&
    css`
      input {
        border: 1px solid ${(props) => props.theme.colors.kudaRed};
      }
    `};

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.6;
    `};

  ${(props) => props.icon && css``};

  .error {
    margin-top: 5px;
    font-weight: normal;
    font-size: 13px;
    color: ${(props) => props.theme.colors.kudaRed};
  }
`;

export const StyledIconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 100%; */
  padding: 0 20px;
  margin: 0;
  height: 46px;
  /* height: 100%; */
  /* position: absolute; */
  background-color: #eff1ff;
  border: 1px solid #eff1ff;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
`;
