import * as types from "./transferLimitTypes";

const initialState = {
  isLoading: false,
  limitSetting: {},
};

const transferLimitReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.TRANSFER_LIMIT_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.TRANSFER_LIMIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case types.TRANSFER_LIMIT_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case types.GET_LIMIT_SETTINGS:
      return {
        ...state,
        isLoading: false,
        limitSetting: payload,
      };
    default:
      return state;
  }
};

export default transferLimitReducer;
