export const SEARCH_ACCOUNT = "SEARCH_ACCOUNT";
export const SEARCH_ACCOUNT_DETAILS = "SEARCH_ACCOUNT_DETAILS";
export const SEARCH_PARAMS = "SEARCH_PARAMS";
export const CLEAR_SEARCH = "CLEAR_SEARCH";
export const CLEAR_DATA = "CLEAR_DATA";
export const CUSTOMER_START = "CUSTOMER_START";
export const CUSTOMER_FAIL = "CUSTOMER_FAIL";
export const CUSTOMER_SUCCESS = "CUSTOMER_SUCCESS";
export const EDIT_CONTACT_DETAILS = "EDIT_CONTACT_DETAILS";
export const SEARCH_CUSTOMER_DETAILS = "SEARCH_CUSTOMER_DETAILS";
export const SEARCH_VIRTUAL_ACCOUNT_DETAILS = "SEARCH_VIRTUAL_ACCOUNT_DETAILS";
export const GET_CUSTOMER_BY_ID = "GET_CUSTOMER_BY_ID";
export const GET_ACCOUNT_BALANCE = "GET_ACCOUNT_BALANCE";
export const GET_ACCOUNT_MEMO = "GET_ACCOUNT_MEMO";
export const EDIT_CUSTOMER_NAME = "EDIT_CUSTOMER_NAME";
export const EDIT_CUSTOMER_ADDRESS = "EDIT_CUSTOMER_ADDRESS";
export const EDIT_CUSTOMER_EMAIL = "EDIT_CUSTOMER_EMAIL";
export const BLOCK_ACCOUNT = "BLOCK_ACCOUNT";
export const UNBLOCK_ACCOUNT = "UNBLOCK_ACCOUNT";
export const CLOSE_ACCOUNT = "CLOSE_ACCOUNT";
export const GET_OVERDRAFT = "GET_OVERDRAFT";
export const GET_SAVINGS = "GET_SAVINGS";
export const GET_LOAN_ACCOUNT = "GET_LOAN_ACCOUNT";
export const GET_TRANSACTION_HISTORY = "GET_TRANSACTION_HISTORY";
export const GET_TRANSACTION_DETAILS = "GET_TRANSACTION_DETAILS";
export const UPDATE_PAGE_ACTION_DATA = "UPDATE_PAGE_ACTION_DATA";
export const UPDATE_SEARCH_CATEGORY = "UPDATE_SEARCH_CATEGORY";
export const STORE_CUSTOMER_BVN_DATA = "STORE_CUSTOMER_BVN_DATA";
export const GET_CUSTOMER_PASSKEYS = "GET_CUSTOMER_PASSKEYS";
export const GET_FLAGGED_TRANSACTIONS = "GET_FLAGGED_TRANSACTIONS";
export const GET_FLAGGED_TRANSACTIONS_SUCCESS =
  "GET_FLAGGED_TRANSACTIONS_SUCCESS";
export const GET_FLAGGED_TRANSACTIONS_FAIL = "GET_FLAGGED_TRANSACTIONS_FAIL";
export const GET_SINGLE_FLAGGED_TRANSACTIONS =
  "GET_SINGLE_FLAGGED_TRANSACTIONS";
export const GET_SINGLE_FLAGGED_TRANSACTIONS_SUCCESS =
  "GET_SINGLE_FLAGGED_TRANSACTIONS_SUCCESS";
export const GET_SINGLE_FLAGGED_TRANSACTIONS_FAIL =
  "GET_SINGLE_FLAGGED_TRANSACTIONS_FAIL";
export const RESOLVE_FLAGGED_TRANSACTIONS_START =
  "RESOLVE_FLAGGED_TRANSACTIONS_START";
export const RESOLVE_FLAGGED_TRANSACTIONS_SUCCESS =
  "RESOLVE_FLAGGED_TRANSACTIONS_SUCCESS";
export const RESOLVE_FLAGGED_TRANSACTIONS_FAIL =
  "RESOLVE_FLAGGED_TRANSACTIONS_FAIL";
