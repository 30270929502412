import React from "react";
import PropTypes from "prop-types";
import { StyledTextarea } from "./Textarea.styles";

const Textarea = ({
  value,
  placeholder,
  label,
  onChange,
  width,
  extraSlot,
  ...rest
}) => (
  <StyledTextarea width={width}>
    {label && <label>{label}</label>}
    <textarea
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      {...rest}
    />
    {extraSlot}
  </StyledTextarea>
);

Textarea.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  width: PropTypes.string,
};

Textarea.defaultProps = {
  // label: "Text title",
  placeholder: "Enter your paragraph description",
};

export default Textarea;
