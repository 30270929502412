export const poaReviewBaseModuleUrl = "/poa-review";

export const paths = {
  home: poaReviewBaseModuleUrl,
  manageRequests: `${poaReviewBaseModuleUrl}/manage-poa-requests`,
	reviewRequest: `${poaReviewBaseModuleUrl}/review-poa-request`
};

export const mainHeaderLink = [
  {
    path: paths.home,
    name: "POA Review",
  },
];

export const manageRequestHeaderLink = [
  {
    path: paths.home,
    name: "POA Review",
  },
  {
    path: paths.manageRequests,
    name: "Manage POA Requests",
  },
];
