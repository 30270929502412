/* eslint-disable no-unused-vars */
import React, { useReducer, createContext } from "react";
import history from "../layout/Routes/history";
import * as authService from "../api/services/Auth";
import {
  finishAuth,
  deleteToken,
  isSessionExpired,
  getToken,
  getUserData,
  getGoogleProfile,
} from "../utils/tokenConfig";
import * as moduleActions from "./../redux/feature/module/moduleActions";
import { useDispatch } from "react-redux";

const initialState = {
  user: null,
  isAuth: getToken() ? true : false,
  isLoading: false,
  googleProfile: null,
};

const AuthContext = createContext({
  user: null,
  isAuth: getToken() ? true : false,
  isLoading: false,
  googleProfile: null,
  login: () => {},
  refreshLogin: () => {},
  logout: () => {},
  loadAuthUser: () => {},
});

function authReducer(state, action) {
  switch (action.type) {
    case "LOGIN_START":
      return {
        ...state,
        isLoading: true,
      };
    case "LOGIN_END":
      return {
        ...state,
        isLoading: false,
      };
    case "LOGIN":
      return {
        ...state,
        user: action.user,
        isAuth: true,
        isLoading: false,
        googleProfile: action.googleProfile,
      };
    case "LOGOUT":
      return {
        ...state,
        user: null,
        isAuth: false,
        isLoading: false,
        googleProfile: null,
      };
    default:
      return state;
  }
}

function AuthProvider(props) {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const reduxDispatch = useDispatch();
  async function loadAuthUser() {
    try {
      if (!isSessionExpired()) {
        let token = getToken();
        let user = getUserData();
        let googleProfile = getGoogleProfile();
        if (token && user) {
          dispatch({
            type: "LOGIN",
            user,
            googleProfile,
          });
        } else {
          logout();
        }
      } else {
        logout();
      }
    } catch (error) {
      logout();
    }
  }

  async function login(response, errorCallback = () => {}) {
    dispatch({ type: "LOGIN_START" });
    let googleToken = response.tokenId;
    let googleProfile = response.profileObj;
    const data = {
      googleToken,
    };
    try {
      const res = await authService.login(data);
      const user = res.data.data;
      const status = res.data.status;
      const statusCode = res.data.statusCode;
      const message = res.data.message;
      const token = res.headers.token;
      const tokenExpiry = res.headers.tokenexpiry;
      const refreshToken = res.headers.refreshtoken;

      if (status) {
        finishAuth(token, user, tokenExpiry, googleProfile, refreshToken);
        dispatch({
          type: "LOGIN",
          user,
          googleProfile,
        });
        reduxDispatch(moduleActions.getModuleGroup());
      } else {
        dispatch({
          type: "LOGIN_END",
        });
        errorCallback(message, statusCode);
        logout();
      }
    } catch (error) {
      dispatch({
        type: "LOGIN_END",
      });
      errorCallback(error);
    }
  }

  async function refreshLogin(
    refToken,
    onComplete = (successful = false) => {}
  ) {
    let googleProfile = getGoogleProfile();
    dispatch({ type: "LOGIN_START" });

    try {
      const res = await authService.refreshUserSession(refToken);
      const user = JSON.parse(localStorage.getItem("KudaAppUserData"));
      const status = res.data.status;
      const message = res.data.message;
      const token = res.headers.token;
      const tokenExpiry = res.headers.tokenexpiry;
      const refreshToken = res.headers.refreshtoken;

      if (status) {
        finishAuth(token, user, tokenExpiry, googleProfile, refreshToken);
        dispatch({
          type: "LOGIN",
          user,
          googleProfile,
        });
        reduxDispatch(moduleActions.getModuleGroup());
        onComplete(true);
      } else {
        onComplete(false);
      }
    } catch (error) {
      onComplete(false);
    } finally {
    }
  }

  function logout() {
    deleteToken();
    history.push("/login");
    dispatch({ type: "LOGOUT" });
    history.push("/login");
  }

  return (
    <AuthContext.Provider
      value={{
        user: state.user,
        login,
        refreshLogin,
        logout,
        isAuth: state.isAuth,
        isLoading: state.isLoading,
        loadAuthUser,
        googleProfile: state.googleProfile,
      }}
      {...props}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
