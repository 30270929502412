import * as types from "./serverTypes";

const initialState = {
  success: false,
  error: false,
  message: "",
  status: null,
};

export default function serverReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_ERRORS:
      return {
        error: true,
        success: false,
        message: action.message,
        status: action.status,
      };
    case types.GET_SUCCESS:
      return {
        error: false,
        success: true,
        message: action.message,
        status: action.status,
      };
    case types.CLEAR_MESSAGE:
      return {
        error: false,
        success: false,
        message: "",
        status: null,
      };
    default:
      return state;
  }
}
