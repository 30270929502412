export const BILLER_MGMT_START = "BILLER_MGMT_START";
export const BILLER_MGMT_FAIL = "BILLER_MGMT_FAIL";
export const BILLER_MGMT_SUCCESS = "BILLER_MGMT_SUCCESS";
export const BILLER_MGMT_BILLERS = "BILLER_MGMT_BILLERS";
export const BILLER_MGMT_UPDATE_LOADING_BILLER = "BILLER_MGMT_UPDATE_LOADING_BILLER";
export const BILLER_MGMT_UPDATE_LOADING_BILLITEM = "BILLER_MGMT_UPDATE_LOADING_BILLITEM";
export const BILLER_MGMT_UPDATE_LOADING_AGGREGATOR = "BILLER_MGMT_UPDATE_LOADING_AGGREGATOR";
export const BILLER_MGMT_BILLTYPES = "BILLER_MGMT_BILLTYPES";
export const BILLER_MGMT_UPDATE_LOADING_BILLTYPES = "BILLER_MGMT_UPDATE_LOADING_BILLTYPES";
export const UPLOAD_BULK_BILLER_FILE = "UPLOAD_BULK_BILLER_FILE";
export const UPLOAD_BULK_BILLITEM_FILE = "UPLOAD_BULK_BILLITEM_FILE";

export const UPLOAD_AGGREGATOR_BULK_BILLER_FILE = "UPLOAD_AGGREGATOR_BULK_BILLER_FILE"

export const UPLOAD_AGGREGATOR_BULK_BILL_ITEM_FILE = "UPLOAD_AGGREGATOR_BULK_BILL_ITEM_FILE"

export const UPDATE_AGGREGATOR_VENDED_BILLERS  = "UPDATE_AGGREGATOR_VENDED_BILLERS"