import React from "react";
import {
  CustomModifiedStyledMain,
  ModifiedStyledMain,
  StyledMain,
} from "./Main.styles";
import Loader from "../../library/Loader/Loader";
import MainRouteHeader from "./MainRouteHeader";
import SubRouteHeader, { ChildSubRouteHeader } from "./SubRouteHeader";

function Main({
  sideBarWidth,
  moduleName,
  children,
  headerAction,
  isLoading,
  mainRoute,
  subRoute,
  links,
  headerReplacement,
  refProps = undefined,
}) {
  return (
    <StyledMain sideBarWidth={sideBarWidth}>
      {isLoading && <Loader />}
      {refProps && <div ref={refProps} />}
      <div className="main-header">
        {mainRoute && !headerReplacement && (
          <MainRouteHeader
            moduleName={moduleName}
            Upload={headerAction}
            headerAction={headerAction}
            links={links}
          />
        )}
        {subRoute && headerReplacement ? (
          <SubRouteHeader
            moduleName={moduleName}
            headerAction={headerAction}
            links={links}
            headerReplacement={headerReplacement}
          />
        ) : subRoute && !headerReplacement ? (
          <SubRouteHeader
            moduleName={moduleName}
            headerAction={headerAction}
            links={links}
          />
        ) : null}
        {!headerReplacement && <hr />}
      </div>
      <div>{children}</div>
    </StyledMain>
  );
}

export function ModifiedMain({
  sideBarWidth,
  moduleName,
  children,
  headerAction,
  isLoading,
  mainRoute,
  subRoute,
  childSubRoute,
  links,
  isSticky,
  fullScreen,
}) {
  return (
    <ModifiedStyledMain
      sideBarWidth={sideBarWidth}
      isSticky={isSticky}
      fullScreen={fullScreen}
    >
      {isLoading && <Loader />}
      <div className="main-header">
        {mainRoute && (
          <MainRouteHeader
            moduleName={moduleName}
            headerAction={headerAction}
            links={links}
          />
        )}
        {subRoute && (
          <SubRouteHeader
            moduleName={moduleName}
            headerAction={headerAction}
            links={links}
          />
        )}
        {childSubRoute && (
          <ChildSubRouteHeader
            moduleName={moduleName}
            headerAction={headerAction}
            links={links}
          />
        )}
        {!childSubRoute && <hr />}
      </div>
      <div className="main-children">{children}</div>
    </ModifiedStyledMain>
  );
}

export function CustomModifiedMain({
  sideBarWidth,
  moduleName,
  children,
  headerAction,
  isLoading,
  mainRoute,
  subRoute,
  childSubRoute,
  links,
}) {
  return (
    <CustomModifiedStyledMain sideBarWidth={sideBarWidth}>
      {isLoading && <Loader />}
      <div className="main-header">
        {mainRoute && (
          <MainRouteHeader
            moduleName={moduleName}
            headerAction={headerAction}
            links={links}
          />
        )}
        {subRoute && (
          <SubRouteHeader
            moduleName={moduleName}
            headerAction={headerAction}
            links={links}
          />
        )}
        {childSubRoute && (
          <ChildSubRouteHeader
            moduleName={moduleName}
            headerAction={headerAction}
            links={links}
          />
        )}
        {!childSubRoute && <hr />}
      </div>
      <div>{children}</div>
    </CustomModifiedStyledMain>
  );
}

export default Main;
