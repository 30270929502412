import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const StyledNav = styled(NavLink)`
  display: flex;
  align-items: center;
  gap: 15px;
  text-decoration: none;
  width: 100%;
  height: 45px;
  padding: 0px 15px;
  color: #979797;
  margin-bottom: 20px;
  transition: all 0.6s cubic-bezier(0.455, 0.03, 0, 1);
  font-size: 13px;

  &:hover {
    background: #ffffff;
    transform: translate3d(0px, -3px, 0px);
    opacity: 0.9;
    box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
    border-radius: 6px;
  }

  &.${(props) => props.activeClassName} {
    font-weight: bold;
    color: #40196d;
    background: #ffffff;
    box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
    border-radius: 6px;
  }

  .nav-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    flex: 0 0 30px;
    background: #40196d;
    box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
    border-radius: 6px;

    svg {
      display: block;
    }
  }

  :not(.active) .nav-icon {
    background: #ffffff;

    * {
      fill: #40196d;
      // stroke: #40196d;
    }
  }

  .nav-name {
    white-space: nowrap;
  }

  :not(.active) .nav-name {
    font-weight: 600;
  }

  .nav-mobile_count {
    margin-left: auto;
  }
`;
