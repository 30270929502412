import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Input from "library/Input/Input";
import Select from "library/Select/Select";
import Button from "library/Button/Button";
import NumberInput from "library/NumberInput/NumberInput";

import SuccessModal from "modules/BillerManagement/shared/modals/SuccessModal";
import { SuccessModalIcon } from "modules/BillerManagement/shared/icons/SuccessModalIcon";
import { paths } from "modules/RemittanceManagement/HeaderLinks";
import { AdditionFormIcon, EqualsFormIcon, ExchangeFormIcon, MultipleFormIcon } from "modules/RemittanceManagement/shared/icons";
import { currencyCodeMap } from "modules/RemittanceManagement/shared/utils";
import { StyledSingleFxRateTab } from "modules/RemittanceManagement/shared/styles/home.styles";

import EurIcon from "../../shared/icons/eur.svg";
import NGNIcon from "../../shared/icons/ngn.svg";
import USDIcon from "../../shared/icons/usd.svg";
import TZSIcon from "../../shared/icons/tzs.svg";
import GBPIcon from "../../shared/icons/gbp.svg";

import { ToastContext } from "context/ToastContext";
import { createFXRate } from "redux/feature/remittanceManagement/renittabceMgmtActions";


const AddFXRateForm = () => {
  const history = useHistory();
  const toastContext = useContext(ToastContext);
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    sourceCurrency: "",
    destinationCurrency: "",
    imtoRate: "",
    marginFee: "",
    kudaRate: "",
  });
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [errors, setErrors] = useState({});

  const { isLoading, } = useSelector((state) => ({
    isLoading: state.remittanceMgmt.createFxRateLoading,
  }));

  const toastConfig = {
    error: false,
    autoDismiss: true,
  };

  const onError = (message, errorData) => {
    toastConfig.error = true;
    toastContext.setToast(message, toastConfig);

    setErrors({});
    if (errorData !== undefined) {
      let validationErrors = {};

      errorData.forEach((item) => {
        if (item.field) {
          validationErrors[item.field] = item.message;
        }
      });

      setErrors((prevState) => ({
        ...prevState,
        ...validationErrors,
      }));
    }
  };

  const onSuccess = () => {
    setShowSuccessModal(true);
  };

  const handleSubmit = () => {
    setErrors({});

    const { sourceCurrency, destinationCurrency, marginFee, imtoRate } = values || {}
    const data = {
      sourceCurrency: currencyCodeMap[sourceCurrency] || '',
      destinationCurrency: currencyCodeMap[destinationCurrency] || '',
      marginFee,
      imtoRate
    }

    dispatch(createFXRate(data, onSuccess, onError));
  };

  const validate = () => {
    let formValid = true;
    Object.keys(values).forEach((key) => {
      if (!values[key] || values[key] === "") {
        formValid = false;
      }
    });

    return formValid;
  };

  const handleInputChange = (name, value) => {
    const validInput = /^[0-9]*\.?[0-9]*$/;

    if (!validInput.test(value)) {
      return;
    }

    setValues((prevValues) => {
      const updatedValues = {
        ...prevValues,
        [name]: value,
      };

      const imtoRate = parseFloat(updatedValues.imtoRate);
      const marginFee = parseFloat(updatedValues.marginFee);

      if (!isNaN(imtoRate) && !isNaN(marginFee)) {
        updatedValues.kudaRate = (imtoRate + marginFee).toFixed(2);
      } else {
        updatedValues.kudaRate = '';
      }

      return updatedValues;
    });
  };

  const handleDropDownSelection = (name, value) => {
    setValues({
      ...values,
      [name]: value,
    });
  };

  const currencies = [
    {
      imageUrl: EurIcon,
      name: `Euro(€)`,
    },
    {
      imageUrl: NGNIcon,
      name: `Nigerian Naira(₦)`,
    },
    {
      imageUrl: USDIcon,
      name: `United States Dollars($)`,
    },
    {
      imageUrl: TZSIcon,
      name: `Tanzanian Shilling (TZS)`,
    },
    {
      imageUrl: GBPIcon,
      name: `Great Britain Pounds (£)`,
    },
  ];

  return (
    <StyledSingleFxRateTab>
      <div className="form-holder">
        <div className="">
          <Select
            label="Source Currency"
            width="100%"
            placeholder="Choose"
            options={currencies.filter((item) => item.name !== values?.destinationCurrency)}
            onGetSelectValue={(value) =>
              handleDropDownSelection("sourceCurrency", value)
            }
            optionKey={"id"}
            showOptionImage={true}
          />
          {values?.sourceCurrency &&
            <div>
              <ExchangeFormIcon />
            </div>
          }
        </div>
        {values?.sourceCurrency &&
          <div>
            <Select
              label="Destination Currency"
              width="100%"
              placeholder="Choose"
              options={currencies.filter((item) => item.name !== values?.sourceCurrency)}
              onGetSelectValue={(value) =>
                handleDropDownSelection("destinationCurrency", value)
              }
              optionKey={"id"}
              showOptionImage={true}
            />
            {values?.destinationCurrency &&
              <div>
                <AdditionFormIcon />
              </div>
            }
          </div>
        }

        {values?.destinationCurrency && <>
          <div >
            <NumberInput
              decimalScale={2}
              name="imtoRate"
              label="IMTO Rate"
              placeholder="Enter IMTO Rate"
              value={values?.imtoRate}
              onChange={(_, value) => { handleInputChange("imtoRate", value) }}
            />
            <div>
              <AdditionFormIcon />
            </div>
          </div>
          <div >
            <NumberInput
              decimalScale={2}
              name="marginFee"
              label="Margin Fee"
              width="100%"
              placeholder="Enter Margin Fee"
              value={values?.marginFee}
              onChange={(_, value) => { handleInputChange("marginFee", value) }}
            />
            <div>
              <EqualsFormIcon />
            </div>
          </div>
          <div>
            <Input
              name="kudaRate"
              label="Kuda Rate"
              width="100%"
              placeholder="Auto-populate field"
              value={values?.kudaRate}
              disabled
            />
          </div>
        </>}

        <div className="action-container-box margi mt-lg-5">
          <div className="action-container ">
            <Button
              size="lg"
              width="100%"
              variant="secondary"
              onClick={() => history.push(paths.managefxRates)}
            >
              Cancel
            </Button>
          </div>
          <div className="action-container ">
            <Button
              loading={isLoading}
              size="lg"
              width="100%"
              variant="primary"
              onClick={handleSubmit}
              disabled={!validate() || isLoading}
            >
              Create Rate
            </Button>
          </div>
        </div>
      </div>

      {showSuccessModal && (
        <SuccessModal
          setShowModal={setShowSuccessModal}
          primaryBtnLabel="Okay"
          isLoading={false}
          setShowSuccessModal={setShowSuccessModal}
          onDissmiss={() => {
            history.push(paths.managefxRates);
          }}
        >
          <h2>FX Rate Created</h2>

          <div className="icon">
            <SuccessModalIcon />
          </div>

          <p className="error-message">
            You've successfully added a new fx rate. <br />
            {currencyCodeMap[values?.sourceCurrency]} - {currencyCodeMap[values?.destinationCurrency]}
          </p>
        </SuccessModal>
      )}
    </StyledSingleFxRateTab>
  );
};

export default AddFXRateForm;
