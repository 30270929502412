import styled, { css } from "styled-components";

export const StyledAvatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #cacaca;
  width: ${(props) =>
    props.size === "sm"
      ? "40px"
      : props.size === "md"
      ? "80px"
      : props.size === "lg"
      ? "136px"
      : ""};
  height: ${(props) =>
    props.size === "sm"
      ? "40px"
      : props.size === "md"
      ? "80px"
      : props.size === "lg"
      ? "136px"
      : ""};
  min-width: ${(props) =>
    props.size === "sm"
      ? "40px"
      : props.size === "md"
      ? "80px"
      : props.size === "lg"
      ? "136px"
      : ""};
  min-height: ${(props) =>
    props.size === "sm"
      ? "40px"
      : props.size === "md"
      ? "80px"
      : props.size === "lg"
      ? "136px"
      : ""};
  border-radius: ${(props) =>
    props.shape === "square" ? "6px" : props.shape === "circle" ? "50%" : ""};

  ${(props) =>
    props.type === "text" &&
    css`
      background: #ffffff;
      border: 1px solid #dfe3ff;
      box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.06);
    `};

  .text {
    text-align: center;
    span {
      font-size: 34px;
      font-weight: 900;
      color: ${(props) => props.theme.colors.kudaPurple};

      ${(props) =>
        props.size === "sm" &&
        css`
          font-size: 16px;
        `};
    }

    span:first-child {
      margin-right: 2px;
      ${(props) =>
        props.size === "sm" &&
        css`
          margin-right: 1px;
        `};
    }
  }

  img {
    width: 100%;
    height: 100%;
  }
`;
