import { StyledTableFilter } from "./Table.styles";
import SearchField from "../SearchField";

const TableFilter = ({
  pageSize,
  handlePageSize,
  width,
  searchFieldPlaceholder,
  onChange,
  hideSearchField = false,
  customFilterComponent = <></>,
  searchHeight = undefined,
  noSearchError = false,
  showOnlyTableSizeDropdown,
	selectFieldLowerHeight,
	useNeutralPLButtonOnSelectFilter
}) => {
  return (
    <StyledTableFilter>
      <div className="filters">
        <p>Results per page</p>
        <select
          className={`input-field ${selectFieldLowerHeight ? 'smaller-height' : ''}`}
          value={pageSize}
          onChange={(e) => {
            handlePageSize(Number(e.target.value));
          }}
        >
          {[1, 5, 10, 20, 50, 100].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </select>
      </div>
      {!showOnlyTableSizeDropdown && (
        <div className={`extra-components  ${useNeutralPLButtonOnSelectFilter ? "neutral-p-l" : ""}`}>
          {customFilterComponent}
          {!hideSearchField && (
            <SearchField
              noSearchError={noSearchError}
              height={searchHeight ? searchHeight : undefined}
              sm
              width={width ?? "fit-content"}
              placeholder={searchFieldPlaceholder ?? "Search"}
              onChange={(e) => onChange(e.target.value)}
            />
          )}
        </div>
      )}
    </StyledTableFilter>
  );
};

export default TableFilter;
