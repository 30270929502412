import axiosInstance from "./axiosConfig";
import { getToken } from "../utils/tokenConfig";
import { sha256 } from "./security";
import { getFingerPrint } from "utils/utilityFunctions";

export const axiosAbortController = new AbortController();

const constructMACAddress = async (url = "") => {
  const userData = JSON.parse(localStorage.getItem("KudaAppUserData") ?? "{}");
  const macAd = await getFingerPrint();
  const newMacAd = sha256(`${macAd}:${userData?.email}`);

  return !url.includes("login") ? newMacAd : null;
};

export const postCall = async (url, data, params, headerConfig) => {
  const config = {
    headers: {
      ...headerConfig,
      MacAddress: await constructMACAddress(url),
      JiraAuthCode: localStorage.getItem("JiraAuthCode"),
    },
    signal: axiosAbortController.signal,
    ...params,
  };
  return await axiosInstance.post(url, data, { config });
};

export const postCallForJira = async (url, data, params, headerConfig) => {
  const config = {
    headers: {
      ...headerConfig,
    },
    ...params,
  };
  return await axiosInstance.post(url, data, config);
};

export const authPostCall = async (url, data, params, headerConfig) => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      ...headerConfig,
      MacAddress: await constructMACAddress(url),
      JiraAuthCode: localStorage.getItem("JiraAuthCode"),
    },
    signal: axiosAbortController.signal,
    ...params,
  };
  return await axiosInstance.post(url, data, config);
};

export const getCall = async (url, params, extraConfig = {}) => {
  const config = {
    ...extraConfig,
    headers: {
      Authorization: `Bearer ${getToken()}`,
      MacAddress: await constructMACAddress(url),
      JiraAuthCode: localStorage.getItem("JiraAuthCode"),
    },
    signal: axiosAbortController.signal,
    ...params,
  };
  return await axiosInstance.get(url, { ...config });
};

export const authPutCall = async (url, data, params) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      MacAddress: await constructMACAddress(url),
      JiraAuthCode: localStorage.getItem("JiraAuthCode"),
    },
    signal: axiosAbortController.signal,
    ...params,
  };
  return await axiosInstance.put(url, data, config);
};

export const authDeleteCall = async (url, data, params) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      MacAddress: await constructMACAddress(url),
      JiraAuthCode: localStorage.getItem("JiraAuthCode"),
    },
    signal: axiosAbortController.signal,
    ...params,
  };
  return await axiosInstance.delete(url, config);
};

export const postRequest = async ({ url, data }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      MacAddress: await constructMACAddress(url),
      JiraAuthCode: localStorage.getItem("JiraAuthCode"),
    },
    signal: axiosAbortController.signal,
  };
  // ENCRYPT DATA HERE
  const response = await axiosInstance.post(url, data, config);
  return response?.data || response;
};

export const getRequest = async ({ url }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      MacAddress: await constructMACAddress(url),
      JiraAuthCode: localStorage.getItem("JiraAuthCode"),
    },
    signal: axiosAbortController.signal,
  };
  // ENCRYPT DATA HERE
  const response = await axiosInstance.get(url, config);
  return response?.data || response;
};

export const putRequest = async ({ url, data }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      MacAddress: await constructMACAddress(url),
      JiraAuthCode: localStorage.getItem("JiraAuthCode"),
    },
    signal: axiosAbortController.signal,
  };
  // ENCRYPT DATA HERE
  const response = await axiosInstance.put(url, data, config);
  return response?.data || response;
};

export const deleteRequest = async ({ url, data }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      MacAddress: await constructMACAddress(url),
      JiraAuthCode: localStorage.getItem("JiraAuthCode"),
    },
    signal: axiosAbortController.signal,
  };
  // ENCRYPT DATA HERE
  const response = await axiosInstance.delete(url, config);
  return response?.data || response;
};

export const basicGetCall = async (url) => {
  return await axiosInstance.get(url);
};
