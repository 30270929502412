import * as Icon from "../../components/SvgPack";

// ROUTES CAN BE ADDED HERE WHEN WE HAVE NEW MODULES
const getModuleIcon = (moduleRoute) => {
  switch (moduleRoute) {
    case "cards":
      return Icon.CardModuleIcon;
    case "users":
      return Icon.UserModuleIcon;
    case "referrals":
      return Icon.GrowthModuleIcon;
    case "product":
      return Icon.ProductOperationsModuleIcon;
    case "compliance":
      return Icon.ComplianceIcon;
    case "customer-operations":
      return Icon.CustomerOperationsIcon;
    case "developer-dashboard":
      return Icon.DevDashboardIcon;
    case "dispute":
      return Icon.DisputeMangementIcon;
    case "finance":
      return Icon.PayWithBankModuleIcon;
    case "credit":
      return Icon.CreditModuleIcon;
    case "retail-banking":
      return Icon.RetailBankingIcon;
    case "business-banking":
      return Icon.BusinessBankingIcon;
    case "biller-management":
      return Icon.BillerManagementIcon;
    case "none-residents":
      return Icon.NoneResidentOperationsIcon;
    case "bank-management":
      return Icon.BankManagementIcon;
    case "poa-review":
      return Icon.POAReviewIcon;
    case "terminal-management":
      return Icon.TerminalManagementIcon;
    case "business-api-operations":
      return Icon.BusinessAPIOperationsIcon;
    case "fraud-management":
      return Icon.FraudManagementIcon;
    case "address-verification":
      return Icon.AddressVerificationIcon;
    case "refund-management":
      return Icon.RefundManagementIcon;
    default:
      return Icon.DashboardModuleIcon;
  }
};

export default getModuleIcon;
