const userBaseUrl = "/api/v3/User";
const notificationBaseUrl = "/api/v3/Notification";

const userRequest = {
  GET_ALL_USERS: `${userBaseUrl}/GetAllUsers`,
  CREATE_USER: `${userBaseUrl}/CreateUser`,
  UPDATE_USER: `${userBaseUrl}/UpdateUserDetail`,
  DEACTIVATE_USER: `${userBaseUrl}/DeactivateUser`,
  ACTIVATE_USER: `${userBaseUrl}/ActivateUser`,
  REASSIGN_ROLE: `${userBaseUrl}/ReassignRole`,
  GET_A_USER: `${userBaseUrl}/GetUserByEmail`,
  GET_NOTIFICATIONS: `${notificationBaseUrl}/GetNotificationMessages`,
  MARK_AS_READ: `${notificationBaseUrl}/MarkAsRead`,
  MARK_ALL_AS_READ: `${notificationBaseUrl}/MarkAllAsRead`,
  GET_PENDING_APPROVAL: `${notificationBaseUrl}/GetPendingApproval`,
  GET_NOTIFICATION_COUNT: `${notificationBaseUrl}/GetNotificationMessagesCount`,
  GET_ASSIGNED_MODULES: `${notificationBaseUrl}/GetAssignedModules`,
  GET_PENDING_APPROVALS_BY_MODULE: `${notificationBaseUrl}/GetPendingApprovalsByModule`,
  RECORD_SEARCH: `${notificationBaseUrl}/SearchRecordsPendingApproval`,
  GET_PENDING_ADDRESS_UPDATE_REQUESTS: `${notificationBaseUrl}/GetPendingAddressUpdateRequests`,
  GET_PENDING_ADDRESS_UPDATE_REQUESTS_BY_ACCOUNT_NUMBER: `${notificationBaseUrl}/GetPendingAddressUpdateRequestByAccountNumber`,
  GET_PENDING_CREDIT_REQUESTS: `${notificationBaseUrl}/GetPendingCreditRequests`,
  GET_PENDING_SUSPICIOUS_TRANSACTIONS_REQUESTS: `${notificationBaseUrl}/GetPendingSuspiciousTransactionsRequests`,
};

export default userRequest;
