import { useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import moment from "moment";

import TableActions from "library/Tables/TableActions";
import { currencySymbolMap, getCurrencyIcon } from "modules/RemittanceManagement/shared/utils";
import { ExchangeIcon } from "modules/RemittanceManagement/shared/icons";

import { formatBalance } from "utils/utilityFunctions";
import { setFxRateInView } from "redux/feature/remittanceManagement/renittabceMgmtActions";


const UpdateFxRateLink = ({ fxRateInView }) => {
  const dispatch = useDispatch();

  const updateFxRateInView = () => {
    dispatch(setFxRateInView(fxRateInView));
  };

  return (
    <Link
      to={`/remittance-management/update-fx-rate?id=${fxRateInView.id}`}
      className="color-primary text-bold cursor underline-none"
      onClick={updateFxRateInView}
    >
      Update
    </Link>
  );
};
export const fxRateColumns = [
  {
    Header: "DATE",
    accessor: "dateCreated",
    Cell: (value) => (
      <TableActions>
        <div>
          <p>
            {moment(value.row.original?.creationDate
            ).format("Do MMMM, YYYY")}
          </p>
        </div>
      </TableActions>
    ),
  },
  {
    Header: "SOURCE CURRENCY",
    accessor: "sourceCurrency",
    Cell: (value) => (
      <TableActions>
        <div className="d-flex align-items-sm-center ">
          {getCurrencyIcon(value.row.original?.sourceCurrency)}
          <div className="d-flex justify-content-between w-full">
            <p className="ml-sm-2 mr-sm-2">{value.row.original?.sourceCurrency}</p>
            <div className="">
              <ExchangeIcon />
            </div>
          </div>
        </div>

      </TableActions>
    ),
  },
  {
    Header: "DESTINATION CURRENCY",
    accessor: "destinationCurrency",
    Cell: (value) => (
      <TableActions>
        <div className="d-flex align-items-sm-center">
          {getCurrencyIcon(value.row.original?.destinationCurrency)}
          <p className="ml-sm-2">{value.row.original?.destinationCurrency}</p>
        </div>
      </TableActions>
    ),
  },
  {
    Header: "IMTO RATE",
    accessor: "imtoRate",
    Cell: (value) => (
      <TableActions>
        <div>
          <p>{currencySymbolMap[value?.row?.original?.sourceCurrency] || value?.row?.original?.sourceCurrency}{formatBalance(value.row.original?.imtoRate) ?? "N/A"}</p>
        </div>
      </TableActions>
    ),
  },
  {
    Header: "MARGIN FEE",
    accessor: "marginFee",
    Cell: (value) => (
      <TableActions>
        <div>
          <p>{currencySymbolMap[value?.row?.original?.sourceCurrency] || value?.row?.original?.sourceCurrency}{formatBalance(value.row.original?.marginFee) ?? "N/A"}</p>
        </div>
      </TableActions>
    ),
  },
  {
    Header: "KUDA RATE",
    accessor: "kudaRate",
    Cell: (value) => (
      <TableActions>
        <div>
          <p>{currencySymbolMap[value?.row?.original?.sourceCurrency] || value?.row?.original?.sourceCurrency}{formatBalance(value.row.original?.kudaRate) ?? "N/A"}</p>
        </div>
      </TableActions>
    ),
  },
  {
    Header: "ACTION",
    aaccessor: "id",
    Cell: (value) => (
      <TableActions>
        <div>
          <UpdateFxRateLink fxRateInView={value.row.original} />
        </div>
      </TableActions>
    ),
  },
];