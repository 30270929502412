import * as types from "./businessBankingTypes";

const initialState = {
  isLoading: false,
  upgradeRequests: [],
  addressVerificationRequests: [],
  dueDiligenceQuestion: [],
  dueDiligenceAnswer: {},
  searchResult: null,
  additionalSearchResult: null,
  searchParams: null,
};

const businessBankingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.BUSINESS_BANKING_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.BUSINESS_BANKING_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case types.BUSINESS_BANKING_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case types.SEARCH_BUSINESS_ACCOUNT:
      return {
        ...state,
        isLoading: false,
        searchResult: payload,
      };
    case types.SEARCH_ADDITIONAL_BUSINESS_ACCOUNT:
      return {
        ...state,
        isLoading: false,
        additionalSearchResult: payload,
      };
    case types.CLEAR_BUSINESS_SEARCH:
      return {
        ...state,
        searchParams: null,
        searchResult: null,
      };
    case types.SEARCH_BUSINESS_PARAMS:
      return {
        ...state,
        isLoading: false,
        searchParams: payload,
      };

    case types.GET_UPGRADE_REQUEST:
      return {
        ...state,
        isLoading: false,
        upgradeRequests: payload,
      };
    case types.GET_ADDRESS_VERIFICATION_REQUESTS:
      return {
        ...state,
        isLoading: false,
        addressVerificationRequests: payload,
      };
    default:
      return state;
  }
};

export default businessBankingReducer;
