import { keyframes } from "styled-components";

export const fadeIn = keyframes`
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 10px;
    opacity: 1;
  }
`;

export const fadeOut = keyframes`
  from {
    bottom: 50px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
`;
