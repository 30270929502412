import { ModifiedMain } from "layout/Main";
import React, { useContext, useEffect, useState } from "react";
import { manageFlaggedTransactionsHeaderLinks, paths } from "../HeaderLinks";
import {
	StyledFraudMgmtWrapper,
	StyledFraudTableFilterExtra,
	StyledQuickInfo,
} from "../shared/styles/home.styles";
import Table from "library/Tables/Table";
import { LoadMoreSearchIcon } from "components/SvgPack";
import { useDispatch, useSelector } from "react-redux";
import TableActions from "library/Tables/TableActions";
import moment from "moment";
import { formatToNaira } from "utils/utilityFunctions";
import { useHistory, useParams } from "react-router-dom";
import { ToastContext } from "context/ToastContext";
import { GetReviewFlaggedTransactions } from "redux/feature/fraudManagement/fraudMgmtActions";
import Icon from "library/Icons/Icon";
import { InfoIcon, WarningIcon } from "../shared/icons";
import SuccessModal from "../shared/modals/SuccessModal";
import { SuccessModalIcon } from "../shared/icons/SuccessModalIcon";
import ManageActionModal from "../shared/modals/ManageActionModal";


const filters = ["All", "Suites", "SelfHelp", "Retail"];

const toastConfig = {
	error: false,
	autoDismiss: true,
};

const ViewManageFlaggedTransactions = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const toastContext = useContext(ToastContext);
	const { account } = useParams();

	const [data, setData] = useState([
		// {
		// 	creationDate: Date.now(),
		// 	accountName: 'Lateef Raji',
		// 	accountNumber: '1212121212',
		// 	amount: 1000,
		// 	transactionReference: "1223345",
		// 	id: 12
		// }
	]);
	const [totalRecords, setTotalRecords] = useState(undefined);
	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	const [effectPageToDefault, setEffectPageToDefault] = useState(false);
	const [filterValue, setFilterValue] = useState(filters[0]);
	const [isLoading, setIsLoading] = useState(true);
	const [filteredData, setFilteredData] = useState([]);
	const [showDeactivateModal, setShowDeactivateModal] = useState(false)
	const [loadingDeactivate, setLoadingDeactivate] = useState(false)
	const [showSuccessModal, setShowSuccessModal] = useState(false)

	const handleDeactivateFraudPND = () => {
		setShowDeactivateModal(false)
		setShowSuccessModal(true)
	}

	// const { isLoading } = useSelector((state) => ({
	// 	isLoading: state.fraudMgmt.isLoading,
	// }));

	const getRecords = (pageSize, pageNumber) => {
		setPageSize(pageSize);
		setPageNumber(pageNumber);
		window.scroll({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
	};

	const onError = (message, result, totalRecords) => {
		toastConfig.error = true;
		toastContext.setToast(message, toastConfig);
		setIsLoading(false);
		console.log(result)
		//if we want to go ahead to manually lift the pnd
		// if (result.length === 0 && totalRecords === 0) {
		// 	setTotalRecords(0);
		// }
	};

	const onSuccess = (data, totalRecords) => {
		setData(data ?? []);
		setFilteredData(data);
		setTotalRecords(totalRecords);
		setIsLoading(false);
	};

	const formatDateNow = (date) => {
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, "0");
		// Months are 0-indexed
		const day = String(date.getDate()).padStart(2, "0");
		return `${year}-${month}-${day}`;
	};

	const getTransactions = () => {
		setIsLoading(true);
		dispatch(
			GetReviewFlaggedTransactions(
				{
					pageSize,
					pageNumber,
					searchParam: 2,
					value: account,
				},
				onSuccess,
				onError
			)
		);
	};

	useEffect(() => {
		getTransactions();
	}, [pageSize, pageNumber]);

	const columns = [
		{
			Header: "CREATION DATE",
			accessor: "creationDate",
			Cell: (value) => (
				<TableActions>
					<div>
						<p>
							{value.row.original?.creationDate
								? moment(value.row.original?.creationDate).format(
									"Do MMMM, YYYY"
								)
								: "N/A"}
						</p>
					</div>
				</TableActions>
			),
		},
		{
			Header: "ACCOUNT NAME",
			accessor: "accountName",
			Cell: (value) => (
				<TableActions>
					<div>
						<p>{value.row.original?.accountName ?? "N/A"}</p>
					</div>
				</TableActions>
			),
		},
		{
			Header: "ACCOUNT NUMBER",
			accessor: "accountNumber",
			Cell: (value) => (
				<TableActions>
					<div>
						<p>{value.row.original?.accountNumber}</p>
					</div>
				</TableActions>
			),
		},
		{
			Header: "TRANSACTION AMOUNT",
			accessor: "transactionAmount",
			Cell: (value) => (
				<TableActions>
					<div>
						<p>
							{value.row.original?.transactionAmount
								? formatToNaira(value.row.original?.transactionAmount)
								: value.row.original?.transactionAmount === 0
									? "₦0.00"
									: "N/A"}
						</p>
					</div>
				</TableActions>
			),
		},
		{
			Header: "TRANSACTION REFERENCE",
			accessor: "transactionReference",
			Cell: (value) => (
				<TableActions>
					<div>
						<p>{value.row.original?.transactionReference ?? "N/A"}</p>
					</div>
				</TableActions>
			),
		},
		{
			Header: "Action",
			accessor: "",
			Cell: (value) => (
				<TableActions>
					<button
						className="cursor action-btn"
						onClick={() => {
							history.push(
								`${paths.manageFlaggedTransactions}/review/${value.row.original?.id}`,
								{
									initResponse: {
										...value.row.original,
									},
								}
							);
						}}
					>
						Review
					</button>
				</TableActions>
			),
		},
	];

	const effectChannelFilter = (channel) => {
		let filteredData;
		switch (channel) {
			case "Suites":
				filteredData = data.filter(
					(item) => item?.channel === channel || item.channel === 1
				);
				break;
			case "SelfHelp":
				filteredData = data.filter(
					(item) => item?.channel === channel || item.channel === 2
				);
				break;
			case "Retail":
				filteredData = data.filter(
					(item) => item?.channel === channel || item.channel === 3
				);
				break;

			default:
				break;
		}

		setFilteredData(filteredData ?? []);
	};

	useEffect(() => {
		if (!isLoading && data.length > 0) {
			switch (filterValue) {
				case "All":
					setFilteredData(data);
					setEffectPageToDefault(false);
					break;
				case "Suites":
					effectChannelFilter("Suites");
					// setEffectPageToDefault(true)
					break;
				case "SelfHelp":
					effectChannelFilter("SelfHelp");
					// setEffectPageToDefault(true)
					break;

				case "Retail":
					effectChannelFilter("Retail");
					// setEffectPageToDefault(true)
					break;
				default:
					setFilteredData(data);
					break;
			}
		}
	}, [filterValue]);

	useEffect(() => {
		if (account === null || account === undefined || account.trim().length === 0) {
			history.push(paths.manageFlaggedTransactions)
		}
	}, [account])

	return (
		<ModifiedMain
			childSubRoute
			links={[...manageFlaggedTransactionsHeaderLinks, {
				path: `${paths.manageFlaggedTransactions}/view/${account}`,
				name: `Manage Flagged Transactions: ${account}`,
				pathAction: () => {
					history.push(`${paths.manageFlaggedTransactions}/view/${account}`);
				}
			}]}
			path={paths.manageFlaggedTransactions}
			isSticky={false}
		>
			<StyledFraudMgmtWrapper>
				<div className="top-header">
					<h1>Manage Flagged Transactions: {account}</h1>
				</div>
				<div className="divider" />

				<div className="stickyWrapper">
					{/* {
						totalRecords === 0
						&&
						<StyledQuickInfo>
							<div className="icon-svg">
								<Icon svg={InfoIcon} />
							</div>
							<p>All transactions flagged on this account have been successfully resolved</p>
							<p className="cta" onClick={() => {
								setShowDeactivateModal(true)
							}}>
								Deactivate Fraud status
							</p>
						</StyledQuickInfo>
					} */}

					<Table
						getRecords={getRecords}
						totalRecords={totalRecords}
						effectPageToDefault={effectPageToDefault}
						selectFieldLowerHeight={true}
						isLoading={isLoading}
						width="400px"
						columns={columns}
						data={filteredData}
						searchFieldPlaceholder="Search by transaction reference"
						customEmptyStateIcon={<LoadMoreSearchIcon />}
						customEmptyState={
							<div
								className="d-flex flex-column"
								style={{ alignItems: "center", width: 380 }}
							>
								<p className="text">Not Record Found</p>
								{/* <p
									className="sub-text"
									style={{ fontSize: 15, color: "#979797" }}
								>
									We couldn’t find a result for the search query entered.
									Please, check and try again.
								</p> */}
							</div>
						}
						customFilterComponent={
							<StyledFraudTableFilterExtra>
								<div className="table-extras">
									<p className="extra-text">Sort</p>
									<select
										className="input-field smaller-padding smaller-text smaller-height"
										value={filterValue}
										onChange={(e) => {
											setFilterValue(e.target.value);
										}}
									>
										{filters.map((item, index) => (
											<option key={`table-filter-item-${index}`} value={item}>
												{item}
											</option>
										))}
									</select>
								</div>
							</StyledFraudTableFilterExtra>
						}
					/>
				</div>
			</StyledFraudMgmtWrapper>

			{
				showDeactivateModal &&
				<ManageActionModal
					setShowModal={setShowDeactivateModal}
					primaryBtnLabel="Done"
					onSubmit={handleDeactivateFraudPND}
					isLoading={loadingDeactivate}
				>
					<h2>Deactivate Fraud PND</h2>

					<div className="icon">
						<Icon svg={WarningIcon} />
					</div>

					<p>
						You’re about to deactivate fraud PND status on this account.
					</p>
				</ManageActionModal>
			}

			{
				showSuccessModal && (
					<SuccessModal
						setShowModal={setShowSuccessModal}
						primaryBtnLabel="Okay"
						isLoading={false}
						setShowSuccessModal={setShowSuccessModal}
						onDissmiss={() => {

						}}
					>
						<h2>{"Deactivated"}</h2>

						<div className="icon">
							<SuccessModalIcon />
						</div>

						<p className="error-message">{"You’ve successfully deactivate fraud PND status."}</p>
					</SuccessModal>
				)
			}
		</ModifiedMain>
	);
};

export default ViewManageFlaggedTransactions;
