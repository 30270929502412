import * as types from "./cardTypes";

const initialState = {
  isLoading: false,
  customerSearchResult: null,
  cardSearchResult: null,
  cardBlockLoggedForApproval: null,
  cardUnBlockLoggedForApproval: null,
  cardDeactivateLoggedForApproval: null,
  importHistory: [],
  unmatchedSortingRecord: [],
  unmatchedDeliveryRecord: [],
  cardBatches: [],
  cardBatchDetails: [],
  cardReissuanceData: null,
  cardDeactivationData: null,
  cardUpdateData: null,
  fundWithCardTransactionDetails: {},
};

const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.CARD_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.CARD_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case types.CARD_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case types.CUSTOMER_SEARCH:
      return {
        ...state,
        isLoading: false,
        customerSearchResult: payload,
      };
    case types.CARD_SEARCH:
      return {
        ...state,
        isLoading: false,
        cardSearchResult: payload,
      };
    case types.GET_CARD_BLOCK_LOGGED_FOR_APPROVAL:
      return {
        ...state,
        isLoading: false,
        cardBlockLoggedForApproval: payload,
      };
    case types.GET_CARD_UNBLOCK_LOGGED_FOR_APPROVAL:
      return {
        ...state,
        isLoading: false,
        cardUnBlockLoggedForApproval: payload,
      };
    case types.GET_CARD_DEACTIVATES_LOGGED_FOR_APPROVAL:
      return {
        ...state,
        isLoading: false,
        cardDeactivateLoggedForApproval: payload,
      };
    case types.GET_IMPORT_HISTORY:
      return {
        ...state,
        isLoading: false,
        importHistory: payload,
      };
    case types.UPLOAD_SORTING_FOR_DELIVERY:
      return {
        ...state,
        isLoading: false,
        unmatchedSortingRecord: payload,
      };
    case types.UPLOAD_SENT_FOR_DELIVERY:
      return {
        ...state,
        isLoading: false,
        unmatchedDeliveryRecord: payload,
      };
    case types.UPLOAD_REISSUANCE_FILE:
      return {
        ...state,
        isLoading: false,
        cardReissuanceData: payload,
      };
    case types.UPLOAD_CARDUPDATE_FILE:
      return {
        ...state,
        isLoading: false,
        cardUpdateData: payload,
      };
    case types.UPLOAD_DEACTIVATION_FILE:
      return {
        ...state,
        isLoading: false,
        cardDeactivationData: payload,
      };
    case types.CLEAR_RECORD:
      return {
        ...state,
        isLoading: false,
        unmatchedSortingRecord: [],
        unmatchedDeliveryRecord: [],
      };
    case types.GET_BATCHES:
      return {
        ...state,
        isLoading: false,
        cardBatches: payload,
      };
    case types.GET_BATCH_DETAILS:
      return {
        ...state,
        isLoading: false,
        cardBatchDetails: payload,
      };
    case types.GET_FUNDWITHCARD_TRANSACTION_DETAILS:
      return {
        ...state,
        fundWithCardTransactionDetails: payload,
      };
    default:
      return state;
  }
};

export default userReducer;
