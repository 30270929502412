import breakpoint from "library/breakpoint";
import styled from "styled-components";

export const StyledTerminalStatusCell = styled.div`
  display: flex;
  align-items: center;

  .status-dot {
    width: 6px;
    height: 6px;
    margin-right: 4px;
    border-radius: 50%;

    &.active-dot {
      background: #48d38a;
    }

    &.inactive-dot {
      background: #f7685b;
    }
  }
`;

export const StyledMerchantTableFilterExtra = styled.div`
  .table-extras {
    display: flex;
    align-items: center;
    margin-right: 10px;

    .extra-text {
      font-weight: 700;
      font-size: 11px;
      line-height: 13px;
      color: #000000;
      padding-right: 12px;
    }
  }

  .gap-10 {
    display: flex;
    gap: 10px
  }
`;

export const StyledTabContainer = styled.div`
  display: flex;
  margin-top: 24px;
  height: 30px;
  position: relative;

  &:after {
    height: 1px;
    background-color: #dbdce099;
    width: 100%;
    content: "";
    position: absolute;
    left: 0;
    bottom: -6px;
  }

  .tab-item {
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    color: #979797;
    cursor: pointer;
    position: relative;

    &:first-child {
      margin-right: 32px;
    }

    &.active {
      color: #000;

      &:after {
        position: absolute;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #40196d;
        content: "";
        bottom: -6px;
      }
    }
  }
`;

export const StyledSingleMerchantTab = styled.div`
  border-radius: 5px;
  border: 1px solid rgba(219, 220, 224, 0.5);
  background: #fff;
  box-shadow: 0px 0px 25px 0px rgba(64, 25, 109, 0.06);
  margin-top: 32px;
  padding: 24px;

  .title {
    font-size: 17px;
    font-style: normal;
    font-weight: 900;
    line-height: 20px;
    color: #000;
    margin-bottom: 16px;
  }

  .divider {
    height: 1px;
    background-color: #dbdce0;
  }

  .form-holder {
    margin-top: 24px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px 36px;
  }

  .action-holder {
    margin-top: 40px;
  }
`;

export const StyledBulkMerchantTab = styled.div`
  margin-top: 32px;
`;

export const StyledBulkMerchantsRecordDisplay = styled.div`
  margin-top: 24px;

  .tooltip {
    position: relative;
    display: inline-block;
    color: #f7685b !important;
    width: fit-content;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: auto;
    text-align: center;
    border-radius: 6px;
    padding: 12px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 0%;
    margin-left: -40px;
    opacity: 0;
    transition: opacity 0.3s;
    background: #333333;
    border-radius: 5px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #ffffff;
    --box-width: calc(50% - 70px);
    margin-left: var(--box-width);
  }

  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 45%;
    margin-left: -10px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  .last-cell {
    display: flex;
    justify-content: space-between;
  }

  .table-error {
    margin-top: 24px;
    background: #ffffff;
    border: 1px solid #dfe3ff;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    padding: 24px 0px;
    display: flex;
    item-align: center;
    justify-content: center;

    .error-text {
      padding-left: 16px;
      color: #000000;
      font-weight: 700;
      font-size: 16px;
      line-height: 26px;
    }
  }

  .table-error-tab {
    border-bottom: 1px solid rgba(219, 220, 224, 0.6);
    margin-top: 24px;
    display: flex;

    .tab-item {
      padding-right: 24px;
      color: #979797;
      font-weight: 700;
      font-size: 15px;
      line-height: 20px;
      cursor: pointer;
      position: relative;
      padding-bottom: 6px;
      width: fit-content;

      &.active,
      &:hover {
        color: #000000;
      }

      &.active {
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 75%;
          height: 2px;
          background: #40196d;
          border-radius: 5px;
        }
      }
    }
  }

  .record-table {
    margin-top: 27px;
  }
`;

export const StyledMerchantDetailsTab = styled.div`
  border-radius: 5px;
  border: 1px solid rgba(219, 220, 224, 0.5);
  background: #fff;
  box-shadow: 0px 0px 25px 0px rgba(64, 25, 109, 0.06);
  margin-top: 32px;
  padding: 24px;
  animation: fadeInUp 0.5s forwards;

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(30px);
    }

    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  .title {
    font-size: 17px;
    font-style: normal;
    font-weight: 900;
    line-height: 20px;
    color: #000;
    margin-bottom: 16px;
  }

  .divider {
    height: 1px;
    background-color: #dbdce0;
  }

  .access-details {
    margin-top: 48px;
  }

  .access-holder {
    margin: 24px 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .access-item {
      border-radius: 25px;
      border: 1px solid #8369a0;
      margin-right: 16px;
      padding: 10px 16px;
      background: #eff1ff;
      margin-bottom: 16px;

      span {
        font-size: 15px;
        font-style: normal;
        font-weight: 800;
        line-height: 20px;
        color: #40196d;
      }
    }
  }

  .header-actioned {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header-action {
      display: flex;
      align-items: center;
      cursor: pointer;

      .action-text {
        font-size: 13px;
        font-style: normal;
        font-weight: 800;
        line-height: 18px;
        color: #40196d;
        padding-left: 8px;
      }
    }
  }
`;

export const StyledNoAccess = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 10px 0 0;

  p {
    margin-top: 24px;
    font-size: 17px;
    font-style: normal;
    font-weight: 800;
    line-height: 20px;
    color: #121212;
  }
`;

export const StyledCardRecordResult = styled.div`
  // margin-top: 24px;

  .card-cards {
    background: #ffffff;
    border: 1px solid rgba(219, 220, 224, 0.5);
    box-shadow: 0px 0px 25px rgba(64, 25, 109, 0.06);
    border-radius: 5px;
    margin-bottom: 24px;

    .card-cards-top {
      padding: 32px;
      border-bottom: 1px solid rgba(23, 34, 60, 0.1);
      display: flex;
      align-items: center;

      @media ${breakpoint.device.mobileL} {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .card-cards-content {
      padding: 25px 32px;
    }
  }

  .card {
    background: #ffffff;
    border: 1px solid rgba(219, 220, 224, 0.5);
    box-shadow: 0px 0px 25px rgba(64, 25, 109, 0.06);
    border-radius: 5px;
    padding: 32px;
    margin-bottom: 24px;
  }

  .card-top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(23, 34, 60, 0.1);
    align-items: center;

    @media ${breakpoint.device.mobileL} {
      flex-direction: column;
      align-items: flex-start;
    }

    .action-holder {
      @media ${breakpoint.device.mobileL} {
        margin-top: 20px;
      }
    }
  }

  .card-title {
    font-style: normal;
    font-weight: 900;
    font-size: 17px;
    line-height: 20px;
    color: #000000;
  }

  .card-content {
    .card-content-block {
      padding: 24px 0 16px;
      border-bottom: 1px solid rgba(23, 34, 60, 0.1);
      display: grid;
      grid-template-columns: repeat(3, 1fr);

      @media ${breakpoint.device.mobileL} {
        grid-template-columns: 1fr;
      }

      .block-item {
        @media ${breakpoint.device.mobileL} {
          margin-bottom: 24px;
        }

        .block-item-title {
          color: #979797;
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 18px;
          margin-bottom: 4px;
        }
        .block-item-value {
          color: #000000;
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          display: flex;
          align-items: center;
        }

        .with-status {
          display: flex;
          align-items: center;

          .active-card {
            width: 8px;
            height: 8px;
            background: #48d38a;
            border-radius: 50%;
            margin-right: 6px;
            padding-top: 6px;
          }

          .inactive-card {
            width: 8px;
            height: 8px;
            background: #f7685b;
            border-radius: 50%;
            margin-right: 6px;
            padding-top: 6px;
          }
        }
      }
    }
  }
`;

export const StyledEditMerchantDetailsModal = styled.div`
  .form-holder {
    margin-top: 24px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px 36px;

    @media ${breakpoint.device.mobileL} {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .switch-actions {
    margin-top: 32px;

    .switch-action {
      display: flex;
      align-items: center;
      margin-bottom: 24px;

      .switch-text {
        color: #000;
        text-align: right;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        margin-left: 16px;
      }
    }
  }

  .action-holder {
    margin: 40px 0 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      &:first-child {
        margin-right: 24px;
      }
    }
  }
`;

export const StyledEditMerchantAccessModal = styled.div`
  .info {
    padding: 24px 0;
    border-bottom: 1px solid #dbdce0;

    span {
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: #000;
    }
  }

  .actions {
    padding-top: 16px;
    display: flex;
    flex-direction: column;

    .action {
      background: #f9f9f9;
      padding: 12px 20px 18px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 16px;
      transition: background 0.3s;

      &.active {
        background: #dcffec;
        border: 1px solid #48d38a;
      }

      .label {
        color: #000;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        margin-bottom: 0;
        margin-left: 16px;
        margin-top: 10px;
      }
    }
  }
`;

export const StyledMerchantTerminals = styled.div`
  margin-top: 24px;
`;
