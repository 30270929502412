import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Button from "./../Button";
import { SearchIcon } from "../../components/SvgPack";
import {
  StyledSearchField,
  StyledSearchFieldContainer,
  StyledSearchFieldError,
} from "./SearchField.styles";
import Icon from "../Icons/Icon";
import SearchDropdown from "./SearchDropdown";

const SearchField = ({
  sm,
  lg,
  placeholder,
  withBtn,
  width,
  height,
  btnText,
  btnAction,
  btnDisabled,
  onChange,
  value,
  loading,
  name,
  error,
  withDropdown,
  options,
  defaultValue,
  selectedOption,
  setSelectedOption,
  noSearchError,
  searchButtonId,
}) => {
  useEffect(() => {
    const handleEnterSearch = (evt) => {
      if (evt.key === "Enter") {
        const searchBtn = document.getElementById(
          searchButtonId ?? "search-btn"
        );
        searchBtn?.click();
      }
    };
    document.addEventListener("keypress", handleEnterSearch);

    return () => document.removeEventListener("keypress", handleEnterSearch);
  }, []);

  return (
    <StyledSearchFieldContainer>
      <StyledSearchField
        sm={sm}
        lg={lg}
        width={width}
        height={height}
        error={error}
        withDropdown={withDropdown}
      >
        {withDropdown && (
          <div className="dropdown-section">
            <SearchDropdown
              options={options}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              defaultValue={defaultValue}
              disabled={options?.length <= 1}
              showIcon={options?.length >= 1}
            />
            <span className="vertical-line"></span>
          </div>
        )}
        <div className="icon">
          <Icon svg={SearchIcon} />
        </div>
        <input
          type="text"
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          name={name}
        />
        {withBtn && lg && (
          <Button
            loading={loading}
            onClick={btnAction}
            size="md"
            disabled={btnDisabled}
            width={withDropdown ? "90px" : "140px"}
            id={searchButtonId ?? "search-btn"}
          >
            {btnText}
          </Button>
        )}
      </StyledSearchField>
      {!noSearchError && (
        <StyledSearchFieldError>{error}</StyledSearchFieldError>
      )}
    </StyledSearchFieldContainer>
  );
};

SearchField.propTypes = {
  width: PropTypes.string, // The width of the searchfield
  searchButtonId: PropTypes.string, // The searchfield button id
  sm: PropTypes.bool, // The small size type of the searchfield
  lg: PropTypes.bool, // The large size type of the searchfield
  withBtn: PropTypes.bool, //Searchfield with button
  placeholder: PropTypes.string, // The placeholde rof the searchfield input
  btnText: PropTypes.string, //The button text text of the searchfield with button
  btnAction: PropTypes.func, // The action to be triggered when the button is clicked
  onChange: PropTypes.func, // The function to be triggered when an event is carried  out on the searchfield
  loading: PropTypes.bool, // The loading state of the button in the searchfield
};

SearchField.defaultProps = {
  placeholder: "Search",
  btnText: "Search",
  error: false,
};

export default SearchField;
