export const paths = {
  home: "/remittance-management",
  managefxRates: "/remittance-management/manage-fx",
  fxRateHistory: "/remittance-management/fx-rate-history",
  addNewFxRate: "/remittance-management/add-fx-rate",
  updateFxRate: "/remittance-management/update-fx-rate"
};

export const mainHeaderLink = [
  {
    path: paths.home,
    name: "Remittance Management",
  },
];

export const fxRateHistoryHeaderLink = [
  {
    path: paths.home,
    name: "Remittance Management",
  },
  {
    path: paths.fxRateHistory,
    name: "FX Rates",
  },
];

export const manageFxRateHistoryHeaderLink = [
  {
    path: paths.home,
    name: "Remittance Management",
  },
  {
    path: paths.managefxRates,
    name: "Manage FX Rates",
  },
];


export const addNewFxRateHeaderLink = [
  {
    path: paths.home,
    name: "Remittance Management",
  },
  {
    path: paths.managefxRates,
    name: "Manage FX Rates",
  },
  {
    path: paths.addNewFxRate,
    name: "Add FX Rate",
  },
];

export const updateFxRateHeaderLink = [
  {
    path: paths.home,
    name: "Remittance Management",
  },
  {
    path: paths.managefxRates,
    name: "Manage FX Rates",
  },
  {
    path: paths.updateFxRate,
    name: "Update FX Rate",
  },
];

