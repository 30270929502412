import React, { useContext } from "react";
import { StyledButton } from "./Button.styles";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";
import Loader from "../Loader";

function Button({
  id,
  type,
  variant,
  size,
  loading,
  onClick,
  iconLeft,
  iconRight,
  disabled,
  children,
  width,
  height,
}) {
  const theme = useContext(ThemeContext);
  return (
    <StyledButton
      theme={theme}
      type={type}
      variant={variant}
      size={size}
      disabled={disabled || loading}
      id={id}
      width={width}
      height={height}
      onClick={onClick}
    >
      {loading ? (
        <Loader
          variant={
            variant === "primary"
              ? "white"
              : variant === "secondary"
              ? "purple"
              : variant === "danger"
              ? "red"
              : "white"
          }
        />
      ) : (
        <div className="btn-main">
          {iconLeft && <span className="icon-left">{iconLeft}</span>}
          {children}
          {iconRight && <span className="icon-right">{iconRight}</span>}
        </div>
      )}
    </StyledButton>
  );
}

Button.propTypes = {
  type: PropTypes.oneOf(["text", "solid"]),
  variant: PropTypes.string,
  size: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  iconRight: PropTypes.node,
  iconLeft: PropTypes.node,
  disabled: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

Button.defaultProps = {
  type: "solid",
  variant: "primary",
  size: "lg",
};

export default Button;
