import React from "react";
import Button from "../Button";
import { LoadMoreIcon } from "../../components/SvgPack";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #121212;
  padding: 40px 0;
  border-top: transparent;
  min-height: 45vh;
  position: relative;
  top: -2px;

  .text {
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 25px;
    margin: 7px 0;
  }

  .sub-text {
    font-style: normal;
    font-weight: normal;
    padding: 0 10px;
    font-size: 15px;
    line-height: 20px;
  }

  .btn-container {
    margin: 20px 0;
  }
`;

function TableLoadMore({
  customEmptyState,
  requestURL,
  CustomInfo,
  customErrorMessage,
  customEmptyStateIcon,
}) {
  const handleClick = () => {
    if (requestURL) {
      requestURL();
    }
  };
  return (
    <Wrapper>
      <div>
        {customEmptyStateIcon ? customEmptyStateIcon : <LoadMoreIcon />}
      </div>
      {customEmptyState ? (
        <>{customEmptyState}</>
      ) : (
        <>
          {/* <p className="text">{customErrorMessage || "No results found"}</p>
        {requestURL && (
          <>
            <p className="sub-text">
              We couldn’t find that record here. You can try <br /> again by
              clicking <strong>Load More</strong>
            </p>
            <div className="btn-container">
              <Button variant="secondary" onClick={handleClick}>
                Load More
              </Button>
            </div>
          </>
        )} */}
        </>
      )}

      {CustomInfo && <CustomInfo />}
    </Wrapper>
  );
}

export default TableLoadMore;
