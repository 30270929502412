import * as types from "./retailTypes";

const initialState = {
  isLoading: false,
  accountUpgradeRequestsResults: [],
  isPendingLoading: false,
  pendingAccountResult: [],
};

const retailReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RETAIL_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.RETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case types.STORE_DATA:
      return {
        ...state,
        accountUpgradeRequestsResults: action.payload,
        isLoading: false,
      };
    case types.RETAIL_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case types.SINGLE_RETAIL_START:
      return {
        ...state,
        isPendingLoading: true,
        pendingAccountResult: [],
      };
    case types.STORE_SINGLE_DATA:
      return {
        ...state,
        isPendingLoading: false,
        pendingAccountResult: action.payload,
      };
    case types.SINGLE_RETAIL_FAIL:
      return {
        ...state,
        isPendingLoading: false,
      };
    default:
      return state;
  }
};

export default retailReducer;
