export const paths = {
  home: "/fraud-management",
  manageFlaggedTransactions: "/fraud-management/manage-flagged-transactions",
	viewManageFlaggedTransactions: "/fraud-management/manage-flagged-transactions/view/:account",
  fraudTransactionsReport: "/fraud-management/fraud-transactions",
  reviewManageFlaggedTransactions:
    "/fraud-management/manage-flagged-transactions/review/:id",
  reviewFraudTransactionsReport:
    "/fraud-management/fraud-transactions/review/:id",
};

export const mainHeaderLink = [
  {
    path: paths.home,
    name: "Fraud Management",
  },
];

export const manageFlaggedTransactionsHeaderLinks = [
  {
    path: paths.home,
    name: "Fraud Management",
  },
  {
    path: paths.manageFlaggedTransactions,
    name: "Manage Flagged Transactions",
  },
];

export const fraudTransactionsReportHeaderLinks = [
  {
    path: paths.home,
    name: "Fraud Management",
  },
  {
    path: paths.fraudTransactionsReport,
    name: "Fraud Transaction Report",
  },
];
