import * as types from "./creditTypes";

const initialState = {
  isLoading: false,
  overDraftSearchResult: [],
  overdraftPrequalificationData: null,
  batchOverdraftUpdateData: null,
  overdraftHistory: null,
  overdraftHistoryDataCount: null,
  salaryLoanRequests: [],
  salaryLoanRequestDetails: null,
  loanRequestsWithOffer: [],
  singleLoanRequestDetails: null,
  companiesProfiles: [],
  companyProfileDetails: {},
  loanProducts: [],
  loanApplications: [],
  loanApplicationsDataCount: null,
  loanReviewDetails: {
    accountNumber: "",
    underwritingQuestions: [],
    documentBlobUrl: {
      employmentDocBlobUrl: "",
      statementBlobUrl: "",
    },
  },
  transactionReceiptData: {
    name: ``,
    statementData: {},
    address: {
      streetName: ``,
      city: "",
      state: "",
    },
  },
};

const creditReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.CREDIT_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.CREDIT_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case types.CREDIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case types.SALARY_LOANS_REQUESTS:
      return {
        ...state,
        salaryLoanRequests: payload,
        salaryLoanRequestDetails: null,
        isLoading: false,
      };
    case types.SALARY_LOANS_SINGLE_REQUEST:
      return {
        ...state,
        salaryLoanRequestDetails: payload,
        isLoading: false,
      };
    case types.LOAN_REQUESTS_WITH_OFFER:
      return {
        ...state,
        loanRequestsWithOffer: payload,
        singleLoanRequestDetails: null,
        isLoading: false,
      };
    case types.SINGLE_LOAN_REQUEST_WITH_OFFER:
      return {
        ...state,
        singleLoanRequestDetails: payload,
        isLoading: false,
      };
    case types.COMPANIES_PROFILES_LIST:
      return {
        ...state,
        companiesProfiles: payload,
        companyProfileDetails: null,
        isLoading: false,
      };
    case types.COMPANY_PROFILE:
      return {
        ...state,
        companyProfileDetails: payload,
        isLoading: false,
      };
    case types.LOAN_PRODUCTS:
      return {
        ...state,
        loanProducts: payload,
        isLoading: false,
      };
    case types.OVERDRAFT_SEARCH:
      return {
        ...state,
        isLoading: false,
        overDraftSearchResult: payload,
      };
    case types.OVERDRAFT_HISTORY:
      return {
        ...state,
        isLoading: false,
        overdraftHistory: payload,
      };
    case types.OVERDRAFT_HISTORY_DATA_COUNT:
      return {
        ...state,
        isLoading: false,
        overdraftHistoryDataCount: payload,
      };
    case types.UPLOAD_OVERDRAFT_PREQUALIFICATION_FILE:
      return {
        ...state,
        isLoading: false,
        overdraftPrequalificationData: payload,
      };
    case types.UPLOAD_BATCH_OVERDRAFT_UPDATE_FILE:
      return {
        ...state,
        isLoading: false,
        batchOverdraftUpdateData: payload,
      };
    case types.SAVE_LOAN_APPLICATIONS:
      return {
        ...state,
        isLoading: false,
        loanApplications: payload.loanApplications,
        loanApplicationsDataCount: payload.count,
      };
    case types.SAVE_LOAN_UNDERWRITTING_REVIEW:
      return {
        ...state,
        isLoading: false,
        loanReviewDetails: {
          accountNumber: payload.accountNumber,
          underwritingQuestions: payload.underwritingQuestions,
          documentBlobUrl: {
            employmentDocBlobUrl: payload.employmentDocBlobUrl,
            statementBlobUrl: payload.statementBlobUrl,
          },
        },
        transactionReceiptData: payload.transactionReceiptData,
      };
    case types.CLEAR_LOAN_UNDERWRITTING_REVIEW:
      return {
        ...state,
        loanReviewDetails: {
          accountNumber: "",
          underwritingQuestions: [],
          documentBlobUrl: {
            employmentDocBlobUrl: "",
            statementBlobUrl: "",
          },
        },
        transactionReceiptData: {
          name: ``,
          statementData: {},
          address: {
            streetName: ``,
            city: "",
            state: "",
          },
        },
      };
    default:
      return state;
  }
};

export default creditReducer;
