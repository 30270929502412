import * as types from "./fraudMgmtTypes";

const initialState = {
  isLoading: false,
};

const fraudMgmtReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.FRAUD_MGMT_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.FRAUD_MGMT_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case types.FRAUD_MGMT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default fraudMgmtReducer;
