import { mapValues } from "lodash";
import {
  formatDistanceToNow,
  format,
  differenceInMinutes,
  differenceInHours,
} from "date-fns";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import moment from "moment";

export const formatToNaira = (value, currency = "NGN") => {
  const options = {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 2,
  };
  const formatter = new Intl.NumberFormat("en-NG", options);
  const amount = formatter.format(value);
  return amount;
};

export const formatMoney = (
  amount,
  decimalCount,
  decimal = ".",
  thousands = ","
) => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
    const negativeSign = amount < 0 ? "-" : "";
    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;
    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
        Math.abs(amount - i)
          .toFixed(decimalCount)
          .slice(4)
        : "")
    );
  } catch (e) { }
};

export const formatBalance = (
  amount,
  decimalCount = 2,
  decimal = ".",
  thousands = ","
) => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
    const negativeSign = amount < 0 ? "-" : "";
    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;
    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
        Math.abs(amount - i)
          .toFixed(decimalCount)
          .slice(2)
        : "")
    );
  } catch (e) { }
};

export const getReference = () => {
  let text = "";
  let possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-.=";
  for (let i = 0; i < 15; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  return text;
};

export const titleCase = (str) => {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
};

export function camelCaseToSentence(camelCaseString) {
  // Replace capital letters with spaces followed by lowercase letters
  let sentence = camelCaseString.replace(/([A-Z])/g, " $1");

  // Capitalize the first letter and remove leading/trailing spaces
  sentence = sentence.charAt(0).toUpperCase() + sentence.slice(1).trim();

  return sentence;
}

export const hexToBase64 = (str) => {
  return btoa(
    String.fromCharCode.apply(
      null,
      str
        .replace(/\r|\n/g, "")
        .replace(/([\da-fA-F]{2}) ?/g, "0x$1 ")
        .replace(/ +$/, "")
        .split(" ")
    )
  );
};

export const base64ToHex = (str) => {
  for (
    var i = 0, bin = atob(str.replace(/[ \r\n]+$/, "")), hex = [];
    i < bin.length;
    ++i
  ) {
    var tmp = bin.charCodeAt(i).toString(16);
    if (tmp.length === 1) tmp = "0" + tmp;
    hex[hex.length] = tmp;
  }
  return hex.join("");
};

export const getHumanDate = (isoformat) => {
  if (isoformat === null) {
    return null;
  } else {
    var readable = new Date(isoformat); // When we pass the ISO format to the JS Date constructor, the return is "Fri Jul 04 2014 21:06:08 GMT-0400 (Eastern Daylight Time)"
    var m = readable.getMonth(); // returns 6 (note that this number is one less than the number of the month in isoformat)
    var d = readable.getDate(); // returns 15
    var y = readable.getFullYear(); // returns 2012

    // we define an array of the months in a year
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // we get the text name of the month by using the value of m to find the corresponding month name
    var mlong = months[m];
    return mlong + " " + d + ", " + y;
  }
};

export const getDate = (isoformat) => {
  var readable = new Date(isoformat); // When we pass the ISO format to the JS Date constructor, the return is "Fri Jul 04 2014 21:06:08 GMT-0400 (Eastern Daylight Time)"
  var m = readable.getMonth(); // returns 6 (note that this number is one less than the number of the month in isoformat)
  var d = readable.getDate(); // returns 15

  // we define an array of the months in a year
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // we get the text name of the month by using the value of m to find the corresponding month name
  var mlong = months[m];
  return mlong + " " + d + ".";
};

export const getMaturityDate = (days) => {
  var someDate = new Date();
  return new Date(someDate.setDate(someDate.getDate() + days)).toISOString();
};

export const getMonth = (months) => {
  var dateObj = new Date();
  var month = new Date(
    dateObj.setMonth(dateObj.getMonth() + months)
  ).toISOString(); //
  return month;
};

export const getDesireTime = (time) => {
  var newDate = new Date(time);
  newDate.setHours(newDate.getHours() + 1);
  var hours = newDate.getHours();
  var minutes = newDate.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

export const closeModalOnClick = (event) => {
  document.querySelector(".modal-backdrop").addEventListener("click", (e) => {
    const isVisible = (elem) =>
      !!elem &&
      !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);

    Array.from(
      document.querySelectorAll(".modal-backdrop .modal-content")
    ).forEach((element) => {
      if (!element.contains(e.target) && isVisible(element)) {
        event();
      }
    });
  });
};

export const closeMobileModalOnClick = (event) => {
  document
    .querySelector(".kuda-mobile--menu")
    .addEventListener("click", (e) => {
      const isVisible = (elem) =>
        !!elem &&
        !!(
          elem.offsetWidth ||
          elem.offsetHeight ||
          elem.getClientRects().length
        );

      Array.from(document.querySelectorAll(".kuda-menu--wrap")).forEach(
        (element) => {
          if (!element.contains(e.target) && isVisible(element)) {
            event();
          }
        }
      );
    });
};

export const closeMoreOnClick = (event) => {
  document.querySelector("body").addEventListener("click", (e) => {
    const isVisible = (elem) =>
      !!elem &&
      !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);

    Array.from(document.querySelectorAll(".pop")).forEach((element) => {
      if (!element.contains(e.target) && isVisible(element)) {
        event();
      }
    });
  });
};

export const fallbackCopyTextToClipboard = (text) => {
  var textArea = document.createElement("textarea");
  textArea.value = text;
  textArea.style.position = "fixed"; //avoid scrolling to bottom
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand("copy");
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
  }

  document.body.removeChild(textArea);
};

export const copyTextToClipboard = (text) => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () { },
    function (err) {
      console.error("Async: Could not copy text: ", err);
    }
  );
};

export const diff = (date1, date2) => {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const firstDate = date1;
  const secondDate = date2;
  return Math.round(Math.abs((firstDate - secondDate) / oneDay));
};

export const toLocaleTime = (date) => {
  var isoDateTime = new Date(date);
  return isoDateTime.toLocaleDateString();
};

export const getBetweenMonths = (date1, date2) => {
  var year1 = date1.getFullYear();
  var year2 = date2.getFullYear();
  var month1 = date1.getMonth();
  var month2 = date2.getMonth();
  if (month1 === 0) {
    //Have to take into account
    month1++;
    month2++;
  }
  return (year2 - year1) * 12 + (month2 - month1);
};

export const capitalize = (s) => {
  return s.toLowerCase().replace(/\b./g, function (a) {
    return a.toUpperCase();
  });
};

export const daysInThisMonth = () => {
  var date = new Date();
  var time = new Date(date.getTime());
  time.setMonth(date.getMonth() + 1);
  time.setDate(0);
  var days =
    time.getDate() > date.getDate() ? time.getDate() - date.getDate() : 0;
  return days;
};

export const totalDaysInThisMonth = () => {
  var now = new Date();
  return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
};

var currency_symbols = {
  USD: "$", // US Dollar
  EUR: "€", // Euro
  CRC: "₡", // Costa Rican Colón
  GBP: "£", // British Pound Sterling
  ILS: "₪", // Israeli New Sheqel
  INR: "₹", // Indian Rupee
  JPY: "¥", // Japanese Yen
  KRW: "₩", // South Korean Won
  NGN: "₦", // Nigerian Naira
  PHP: "₱", // Philippine Peso
  PLN: "zł", // Polish Zloty
  PYG: "₲", // Paraguayan Guarani
  THB: "฿", // Thai Baht
  UAH: "₴", // Ukrainian Hryvnia
  VND: "₫", // Vietnamese Dong
};

export const formatCurrency = (currency) => {
  if (currency_symbols[currency] !== undefined) {
    return currency_symbols[currency];
  }
  return `${currency} `;
};

export const currentYear = () => {
  var dt = new Date(); //Date constructor
  return dt.getUTCFullYear();
};

export const detectMobile = () => {
  // device detection
  if (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
      navigator.userAgent
    ) ||
    // eslint-disable-next-line no-useless-escape
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      navigator.userAgent.substr(0, 4)
    )
  ) {
    return true;
  } else {
    return false;
  }
};

export const isoStringOffset = (newDate) => {
  var date = new Date(newDate); // Or the date you'd like converted.
  return new Date(
    date.getTime() - date.getTimezoneOffset() * 60000
  ).toISOString();
};

export const inThirty = () => {
  const inThirtyMinutes = new Date(new Date().getTime() + 30 * 60 * 1000);
  return inThirtyMinutes;
};

export const checkEmpty = (obj) => {
  let check = false;
  for (var key in obj) {
    if (obj[key] === null || obj[key] === "") {
      check = true;
      break;
    }
  }
  return check;
};

export const emptyObject = (obj) => {
  return mapValues(obj, () => "");
};

export const dateDistanceToNow = (
  givenDate,
  addSuffix = true,
  includeSeconds = true
) => {
  let formattedDate = isoStringOffset(new Date(givenDate));
  formattedDate = formatDistanceToNow(new Date(formattedDate), {
    addSuffix,
    includeSeconds,
  });
  return formattedDate;
};

// Date and Time: 22 September, 2022    07:22 PM
export const formatDateTime = (str) => {
  const stringOffset = isoStringOffset(new Date(str));
  return format(new Date(stringOffset), "dd MMMM, yyyy  hh:mm a");
};

export const removeEmpty = (obj) => {
  let newObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] === Object(obj[key])) newObj[key] = removeEmpty(obj[key]);
    else if (obj[key] !== undefined) newObj[key] = obj[key];
  });
  return newObj;
};

export const getSearchParams = (search) => {
  const searchParams = new URLSearchParams(search);
  let values = {};
  for (let param of Array.from(searchParams)) {
    values[param[0]] = param[1];
  }

  return values;
};

export const textToUpperCase = (value) => {
  return value.toUpperCase().trim();
};

export const transformObjectKeys = (obj, transformFn) => {
  if (typeof obj !== "object" || obj === null) {
    return obj; // Return the input if it's not an object or null
  }

  const transformedObj = {};

  Object.keys(obj).forEach((key) => {
    const transformedKey = transformFn(key);
    transformedObj[transformedKey] = obj[key];
  });

  return transformedObj;
};

export const convertToLoweCaseAndTrim = (value) => {
  return value.trim().toLowerCase();
};

export const convertToLoweCase = (value) => {
  return value?.toLowerCase();
};

export const getFingerPrint = async () => {
  const fp = await FingerprintJS.load();

  // The FingerprintJS agent is ready.
  // Get a visitor identifier when you'd like to.
  const result = await fp.get();

  // This is the visitor identifier:
  const visitorId = result.visitorId;
  return visitorId;
};

export const addThToNumber = (number) => {
  if (typeof number === "number" && !isNaN(number)) {
    const lastDigit = number % 10;
    const suffix =
      lastDigit === 1 && number !== 11
        ? "st"
        : lastDigit === 2 && number !== 12
          ? "nd"
          : lastDigit === 3 && number !== 13
            ? "rd"
            : "th";

    return number + suffix;
  }
  return null;
};

export const deepEqual = (obj1, obj2) => {
  if (obj1 === obj2) {
    return true;
  }

  if (
    typeof obj1 !== "object" ||
    typeof obj2 !== "object" ||
    obj1 === null ||
    obj2 === null
  ) {
    return false;
  }

  let keys1 = Object.keys(obj1);
  let keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
};

export const groupDataIntoAlphabeticalOrder = (
  dataList,
  alphabetArray,
  keyOfInterest
) => {
  const finalGroupedArray = [];
  alphabetArray?.forEach((alphabet, indx) => {
    const groupLetter = alphabet.toUpperCase();

    const matchingEntries = dataList?.filter((itemInList, idx) => {
      return (
        itemInList[keyOfInterest]?.slice(0, 1)?.toUpperCase() === groupLetter
      );
    });

    //   add item to group array if there are matching entries
    if (matchingEntries?.length) {
      const groupedArrayItem = {
        letter: groupLetter,
        data: matchingEntries,
      };

      finalGroupedArray.push(groupedArrayItem);
    }
  });

  return finalGroupedArray;
};

/**
 * Get File Extension
 * @param {string} fileName
 * @returns string
 */
export const getFileExtension = (fileName) => {
  if (fileName === undefined) {
    return "";
  }
  let value = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
  return value.toLowerCase();
};

/**
 * Converts html to jira comment json	format
 * @param {string} htmlString
 * @returns Object
 */
export const convertHtmlToJson = (htmlString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");

  const parseNode = (node) => {
    if (node.nodeType === Node.TEXT_NODE) {
      return [
        {
          type: "text",
          text: node.textContent,
        },
      ];
    }

    const type = node.tagName.toLowerCase();
    const text = node.textContent.trim();

    switch (type) {
      case "ul":
      case "ol":
        const listItems = Array.from(node.querySelectorAll("li"));
        return [
          {
            type: type === "ul" ? "bulletList" : "orderedList",
            attrs:
              type === "ul"
                ? undefined
                : {
                  order: 1,
                },
            content: listItems.map((item) => ({
              type: "listItem",
              content: [
                {
                  type: "paragraph",
                  content: parseNode(item),
                },
              ],
            })),
          },
        ];

      case "h1":
      case "h2":
      case "h3":
      case "h4":
      case "h5":
      case "h6":
        const headingLevel = parseInt(type[1]);
        return [
          {
            type: "heading",
            attrs: {
              level: headingLevel,
            },
            content: [
              {
                type: "text",
                text: text,
              },
            ],
          },
        ];

      case "strong":
      case "em":
      case "code":
      case "s":
      case "strike":
      case "ins": // Handle underlined text
      case "del": // Handle for strike text
        return [
          {
            type: "text",
            text: text,
            marks: [
              {
                type:
                  type === "strong"
                    ? "strong"
                    : type === "em"
                      ? "em"
                      : type === "code"
                        ? "code"
                        : type === "ins"
                          ? "underline"
                          : "strike",
              },
            ],
          },
        ];

      default:
        const childNodes = Array.from(node.childNodes);
        const content = childNodes.flatMap((item) => parseNode(item));

        if (type === "p") {
          return [
            {
              type: "paragraph",
              content: content,
            },
          ];
        }

        return content;
    }
  };

  const content = Array.from(doc.body.children).flatMap(parseNode);

  return {
    body: {
      version: 1,
      type: "doc",
      content: content,
    },
    isInternal: true,
  };
};

/**
 * Converts number to word
 * @param {number} num
 * @returns string
 */
export const numberToWords = (num) => {
  const ones = [
    "Zero",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
  ];
  const teens = [
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];
  const tens = [
    "",
    "Ten",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];

  if (num >= 0 && num <= 9) {
    return ones[num];
  } else if (num >= 11 && num <= 19) {
    return teens[num - 11];
  } else if (num >= 10 && num <= 99) {
    return (
      tens[Math.floor(num / 10)] + (num % 10 === 0 ? "" : " " + ones[num % 10])
    );
  } else if (num >= 100 && num <= 999) {
    return (
      ones[Math.floor(num / 100)] +
      " Hundred" +
      (num % 100 === 0 ? "" : " and " + numberToWords(num % 100))
    );
  } else {
    return "Number out of range for this function";
  }
};

export function objectToSearchParams(obj) {
  const uri = Object.keys(obj)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]))
    .join("&");

  return decodeURIComponent(uri);
}

export function mergeSearchParams(searchParams1, searchParams2) {
  const mergedParams = new URLSearchParams(searchParams1);
  const paramsToAdd = new URLSearchParams(searchParams2);

  // Remove existing parameters with the same name
  paramsToAdd.forEach((value, key) => {
    mergedParams.delete(key);
  });

  // Add new parameters
  paramsToAdd.forEach((value, key) => {
    mergedParams.append(key, value);
  });

  return decodeURIComponent(mergedParams.toString());
}

export const sortMenuByName = (array, item) => {
  return array.sort((e1, e2) =>
    e1.moduleGroup[item]
      .toLowerCase()
      .localeCompare(e2.moduleGroup[item].toLowerCase())
  );
};

// validates image url Promise<boolean>
export function isImgUrl(url) {
  try {
    const img = new Image();
    img.src = url;
    return new Promise((resolve) => {
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
    });
  } catch (error) {
    console.error(error);
  }
}

export const removeDuplicates = (arr, duplicateAttribute) => {
  const uniqueIds = new Set(); // Using a Set to store unique IDs
  const result = [];

  for (const obj of arr) {
    if (!uniqueIds.has(obj[duplicateAttribute])) {
      // If the ID is not already encountered, add it to the set and include the object in the result
      uniqueIds.add(obj[duplicateAttribute]);
      result.push(obj);
    }
  }

  return result;
};

export const removeNestedDuplicates = (arr, targetObj, duplicateAttribute) => {
  const uniqueIds = new Set(); // Using a Set to store unique IDs
  const result = [];

  // Helper function to recursively traverse nested objects
  const traverse = (obj) => {
    // Check if the object is a moduleGroup
    if (obj.hasOwnProperty(targetObj)) {
      // Check if the moduleGroup has the duplicateAttribute
      if (obj[targetObj].hasOwnProperty(duplicateAttribute)) {
        const id = obj[targetObj][duplicateAttribute];
        if (!uniqueIds.has(id)) {
          // If the ID is not already encountered, add it to the set and include the object in the result
          uniqueIds.add(id);
          result.push(obj);
        }
      }
    }

    // Traverse through nested objects recursively
    for (const key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        traverse(obj[key]);
      }
    }
  };

  // Iterate through each object in the array
  for (const obj of arr) {
    traverse(obj);
  }

  return result;
};

export const convertPascalToSentence = (string) => {
  const converted = string.replace(/[A-Z]/g, (match, offset) => {
    return offset <= 0 ? match : " " + match.toLowerCase();
  });

  return converted;
};

export const compareDates = (dateValue1, dateValue2) => {
  const parseCustomDate = (dateStr) => {
    const months = {
      January: 0,
      February: 1,
      March: 2,
      April: 3,
      May: 4,
      June: 5,
      July: 6,
      August: 7,
      September: 8,
      October: 9,
      November: 10,
      December: 11,
    };

    const parts = dateStr.split(",")?.[1].trim().split(" ");
    const day = parseInt(parts[0], 10);
    const month = months[parts[1]];
    const year = parseInt(parts[2], 10);

    return new Date(year, month, day);
  };

  // Parse the dates
  const parsedDate1 = parseCustomDate(dateValue1);
  const parsedDate2 = new Date(dateValue2);

  // Extract the year, month, and day for both dates
  const year1 = parsedDate1.getFullYear();
  const month1 = parsedDate1.getMonth();
  const day1 = parsedDate1.getDate();

  const year2 = parsedDate2.getFullYear();
  const month2 = parsedDate2.getMonth();
  const day2 = parsedDate2.getDate();

  // Check if the year, month, and day are the same
  return year1 === year2 && month1 === month2 && day1 === day2;
};

export function objectToFormData(
  obj,
  formData = new FormData(),
  parentKey = ""
) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      const formKey = parentKey ? `${parentKey}[${key}]` : key;

      if (Array.isArray(value)) {
        // Handle arrays like SupportingDocuments
        if (value.length > 0 && value[0] instanceof File) {
          value.forEach((file) => {
            formData.append(formKey, file, file.name);
          });
        } else {
          value.forEach((item, index) => {
            objectToFormData(item, formData, `${formKey}[${index}]`);
          });
        }
      } else if (value instanceof Date) {
        formData.append(formKey, value.toISOString());
      } else if (value instanceof File) {
        formData.append(formKey, value, value.name);
      } else if (
        typeof value === "object" &&
        value !== null &&
        !(value instanceof File)
      ) {
        objectToFormData(value, formData, formKey);
      } else {
        formData.append(formKey, value);
      }
    }
  }

  return formData;
}

export const formatDateToString = (date) => {
  const newDate = new Date(date);
  const year = newDate.getFullYear();
  let month = newDate.getMonth() + 1;
  let dt = newDate.getDate();

  if (dt < 10) {
    dt = "0" + dt;
  }
  if (month < 10) {
    month = "0" + month;
  }

  const convertedDate = `${year}-${month}-${dt}`;

  return convertedDate.toString();
};

export function formatDateTOrime(dateString) {
  const inputDate = new Date(dateString);
  const now = new Date();

  const minutesDifference = differenceInMinutes(now, inputDate);
  const hoursDifference = differenceInHours(now, inputDate);

  if (minutesDifference < 60) {
    return `${minutesDifference} min${minutesDifference !== 1 ? "s" : ""} ago`;
  } else if (hoursDifference < 24) {
    const remainingMinutes = minutesDifference % 60;
    return `${hoursDifference} hr${hoursDifference !== 1 ? "s" : ""
      }, ${remainingMinutes} min${remainingMinutes !== 1 ? "s" : ""} ago`;
  } else {
    return format(inputDate, "d MMM yyyy");
  }
}




/**
 * Formats the difference between a given timestamp and now.
 * Shows the difference in days, hours, and minutes.
 * Omits any unit (days, hours, minutes) that is 0.
 *
 * @param {string} timestamp - The timestamp to compare against the current time.
 * @returns {string} - A human-readable string showing the time difference.
 */
export const getDetailedTimeDifference = (timestamp) => {
  const now = moment.utc(); // Get the current time in UTC
  const then = moment.utc(timestamp); // Parse the input timestamp as UTC

  const duration = moment.duration(now.diff(then));

  const days = Math.floor(duration.asDays());
  const hours = Math.floor(duration.asHours() % 24);
  const minutes = Math.floor(duration.asMinutes() % 60);

  // Build the output dynamically
  const parts = [];

  if (days > 0) {
    parts.push(`${days} ${days === 1 ? 'day' : 'days'}`);
  }
  if (hours > 0) {
    parts.push(`${hours} ${hours === 1 ? 'hour' : 'hours'}`);
  }
  if (minutes > 0) {
    parts.push(`${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`);
  }

  return parts.length > 0 ? parts.join(' and ') + ' ago' : 'just now';
};

export const getFullDate = (isoformat) => {
	var readable = new Date(isoformat); // When we pass the ISO format to the JS Date constructor, the return is "Fri Jul 04 2014 21:06:08 Gmargin-top-0400 (Eastern Daylight Time)"
	var m = readable.getMonth(); // returns 6 (note that this number is one less than the number of the month in isoformat)
	var d = readable.getDate(); // returns 15
	var y = readable.getFullYear(); // returns 2012

	// we define an array of the months in a year
	var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

	// we get the text name of the month by using the value of m to find the corresponding month name
	var mlong = months[m];
	return d + " " + mlong + ", " + y;
};
