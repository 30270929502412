import * as types from "./productOpsTypes";

const initialState = {
  isLoading: false,
  channels: [],
  providers: [],
};

const productOpsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.PRODUCT_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.PRODUCT_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case types.PRODUCT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case types.GET_CHANNELS:
      return {
        ...state,
        isLoading: false,
        channels: payload,
      };
    case types.GET_PROVIDERS:
      return {
        ...state,
        isLoading: false,
        providers: payload,
      };
    default:
      return state;
  }
};
export default productOpsReducer;
