import Button from "library/Button";
import styled from "styled-components";

import { StyledSuccessModal } from "../styles/modal.styles";

const StyledModalBackground = styled.div`
  position: fixed;
  overflow: hidden;
  top: 100;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(64, 25, 109, 0.2);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5em 1em;
  z-index: 99999;
  box-sizing: border-box;
  overflow: scroll;
`;

const SuccessModal = ({
  setShowModal = () => {},
  onDissmiss = () => {},
  btnClassName,
  children,
  primaryBtnLabel,
  isLoading = false,
}) => {
  const handleClick = (evt) => {
    if (evt.target.id === "modalbg") {
      setShowModal(false);
      onDissmiss();
    }
  };

  return (
    <StyledModalBackground id="modalbg" onClick={handleClick}>
      <StyledSuccessModal>
        <div className="card-content">{children}</div>

        <footer>
          <Button
            size="lg"
            loading={false}
            width="145px"
            variant="primary"
            disabled={false}
            onClick={() => {
              setShowModal(false);
              onDissmiss();
            }}
          >
            {primaryBtnLabel ?? "Done"}
          </Button>
        </footer>
      </StyledSuccessModal>
    </StyledModalBackground>
  );
};

export default SuccessModal;
