import styled from "styled-components";

export const StyledWarning = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;

  .error__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 30px;
  }

  h1 {
    font-weight: 900;
    font-family: "Muli";
    font-size: 28px;
    text-align: center;
    line-height: 35px;
  }

  Button {
    margin-top: 30px;
  }
`;