import { NavLink } from "react-router-dom";

const MainRouteHeader = ({ moduleName, headerAction, links }) => {
  return (
    <>
      <h1 className="main-header--text">{moduleName}</h1>
      <div className="main-header__actions">
        <div className="tab-nav">
          <ul className="tab-nav__list">
            {links &&
              links.map((link, index) => {
                return (
                  <NavLink
                    key={index}
                    activeClassName="nav-link__active"
                    className="nav-link"
                    exact
                    to={link.path}
                  >
                    {link.name}
                  </NavLink>
                );
              })}
          </ul>
        </div>
        {headerAction}
      </div>
    </>
  );
};

export default MainRouteHeader;
