/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import DefaultLayout from "./layout/Routes";
import { AuthContext } from "./context/AuthContext";
import { ToastProvider } from "./context/ToastContext";

function App() {
  const context = useContext(AuthContext);
  useEffect(() => {
    context.loadAuthUser();
  }, []);

  return (
    <ToastProvider>
      <DefaultLayout />
    </ToastProvider>
  );
}

export default App;
