import { GBPIcon, EURIcon, NGNIcon, USDIcon, TZSIcon, GlobalIcon } from "../icons";

const currencyIconMap = {
    all: GlobalIcon,
    gbp: GBPIcon,
    eur: EURIcon,
    ngn: NGNIcon,
    usd: USDIcon,
    tzs: TZSIcon
};

export const getCurrencyIcon = (currencyCode) => {
    const normalizedCode = currencyCode.toLowerCase();
    const IconComponent = currencyIconMap[normalizedCode] || GlobalIcon;
    return <IconComponent />;
};

export const currencyCodeMap = {
    "Euro(€)": "EUR",
    "Nigerian Naira(₦)": "NGN",
    "United States Dollars($)": "USD",
    "Tanzanian Shilling (TZS)": "TZS",
    "Great Britain Pounds (£)": "GBP",
};

export const currencySymbolMap = {
    "EUR": "€",  // Euro
    "NGN": "₦",  // Nigerian Naira
    "USD": "$",  // US Dollar
    "TZS": "TSh",  // Tanzanian Shilling
    "TZN": "TSh",  // Tanzanian New Shilling (old code)
    "GBP": "£",  // British Pound Sterling
    "JPY": "¥",  // Japanese Yen
    "CNY": "¥",  // Chinese Yuan
    "CAD": "$", // Canadian Dollar
    "AUD": "A$", // Australian Dollar
    "INR": "₹",  // Indian Rupee
    "ZAR": "R",  // South African Rand
    "KES": "KSh", // Kenyan Shilling
    "GHS": "₵",  // Ghanaian Cedi
    "BRL": "R$", // Brazilian Real
    "MXN": "Mex$", // Mexican Peso
    "CHF": "CHF", // Swiss Franc
    "RUB": "₽",  // Russian Ruble
    "AED": "د.إ", // UAE Dirham
    "SAR": "ر.س", // Saudi Riyal
    "KRW": "₩",  // South Korean Won
    "SEK": "kr",  // Swedish Krona
    "NOK": "kr",  // Norwegian Krone
    "DKK": "kr",  // Danish Krone
};

export const fxRateCurrencies = [
    { label: 'All Currencies', value: "ALL", icon: GlobalIcon },
    { label: 'Great Britain Pounds (£)', value: 'GBP', icon: GBPIcon },
    { label: 'Euro (€)', value: 'EUR', icon: EURIcon },
    { label: 'Nigerian Naira (₦)', value: 'NGN', icon: NGNIcon },
    { label: 'United States Dollars ($)', value: 'USD', icon: USDIcon },
    { label: 'Tanzanian Shilling (TZS)', value: 'TZS', icon: TZSIcon }
];