import React, { forwardRef, useRef, useEffect } from "react";
import { StyledTableCustomCheckBox } from "./Table.styles";

const TableCustomCheckBox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <StyledTableCustomCheckBox>
      <input
        className="custom-checkbox"
        type="checkbox"
        ref={resolvedRef}
        {...rest}
      />
    </StyledTableCustomCheckBox>
  );
});

export default TableCustomCheckBox;
