import { NavLink } from "react-router-dom";
import Icon from "../../library/Icons/Icon";
import { ArrowRight } from "../../components/SvgPack";

const SubRouteHeader = ({
  moduleName,
  headerAction,
  links,
  headerReplacement,
}) => {
  return (
    <>
      <div className="main-header__actions subRoute">
        <div className="tab-nav">
          <ul className="tab-nav__list">
            {links &&
              links.map((link, idx) => {
                return (
                  <span key={idx} className="tab-nav__list">
                    {link?.pathAction !== undefined ? (
                      <span
                        className="nav-link"
                        onClick={() => {
                          link?.pathAction();
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {link.name}
                      </span>
                    ) : (
                      <NavLink className="nav-link" exact to={link.path}>
                        {link.name}
                      </NavLink>
                    )}

                    {idx !== links.length - 1 && (
                      <div className="tab-nav__icon">
                        <Icon svg={ArrowRight} className="nav__item--arrow" />
                      </div>
                    )}
                  </span>
                );
              })}
          </ul>
        </div>
      </div>
      {headerReplacement ? (
        headerReplacement
      ) : (
        <div className="subRoute-header">
          <h1 className="main-header--text subRoute">{moduleName}</h1>
          <div className="actions">{headerAction}</div>
        </div>
      )}
    </>
  );
};

export const ChildSubRouteHeader = ({ moduleName, headerAction, links }) => {
  return (
    <>
      <div className="main-header__actions subRoute">
        <div className="tab-nav">
          <ul className="tab-nav__list">
            {links &&
              links.map((link, idx) => {
                return (
                  <span key={idx} className="tab-nav__list">
                    {link?.pathAction !== undefined ? (
                      <span
                        className="nav-link"
                        onClick={() => {
                          link?.pathAction();
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {link.name}
                      </span>
                    ) : (
                      <NavLink className="nav-link" exact to={link.path}>
                        {link.name}
                      </NavLink>
                    )}

                    {idx !== links.length - 1 && (
                      <div className="tab-nav__icon">
                        <Icon svg={ArrowRight} className="nav__item--arrow" />
                      </div>
                    )}
                  </span>
                );
              })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default SubRouteHeader;
