import styled from "styled-components";

export const StyledDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.noGap ? "0px" : "16px")};

  .top-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 18px;

    h1 {
      font-style: normal;
      font-weight: 900;
      font-size: 20px;
      line-height: 25px;
      color: #121212;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;

    h1 {
      font-style: normal;
      font-weight: 900;
      font-size: 20px;
      line-height: 25px;
      color: #121212;
      display: flex;

      .fileName {
        display: inline-block;
        max-width: 300px;
        width: auto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 10px;
      }
    }

    .actions {
      display: flex;
      gap: 20px;
    }
  }

  .divider {
    height: 1px;
    background-color: #dbdce0;
  }

  .account-tabs {
    display: inline-grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 250px));
    gap: 29px;
  }

  @media screen and (max-width: 450px) {
    .top-header {
      flex-direction: column;
      align-items: start;
      gap: 10px;
    }
  }
`;

export const StyledAlertModal = styled.div`
  width: 400px;
  max-width: 100%;
  height: 482px;
  background: #ffffff;
  border: 1px solid rgba(219, 220, 224, 0.5);
  box-shadow: 15px 30px 40px rgba(64, 25, 109, 0.07);
  border-radius: 6px;
  padding: 46px 40px;
  display: flex;
  flex-direction: column;
  animation: fadeInUp 0.5s forwards;

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(30px);
    }

    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  .card-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    h2 {
      font-style: normal;
      font-weight: 800;
      font-size: 20px;
      line-height: 25px;
      text-align: center;
      color: #000000;
    }

    .icon {
      margin: 40px 0px 42px;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      text-align: center;
      color: #000000;
    }
  }

  .ticket-title {
    margin-top: 10px;
    font-style: normal;
    font-weight: 800;
    font-size: 13px;
    line-height: 18px;
    color: #000000;
  }

  .ticket-box {
    width: 142px;
    height: 72px;
    background: #f8f8f8;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    margin-inline: auto;
    justify-content: center;
    padding: 5px 10px;
    margin: 22px 0 40px;

    p {
      font-style: normal;
      font-weight: 800;
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      color: #979797;
    }

    .ticket-id {
      font-style: normal;
      font-weight: 900;
      font-size: 24px;
      line-height: 30px;
      color: #000000;
    }

    .icon-box {
      display: flex;
      column-gap: 5px;
      align-items: center;
      cursor: pointer;
      min-height: 29px;

      .text {
        font-style: normal;
        font-weight: 700;
        font-size: 11px;
        line-height: 13px;
        color: #40196d;
      }
    }
  }

  footer {
    display: flex;
    height: 48px;
    gap: 20px;

    button {
      flex: 1;
      /* background: #f9f9f9;
      border: 1px solid rgba(64, 25, 109, 0.3);
      border-radius: 8px;
      cursor: pointer;
      transition: 0.4s;
      color: #40196d;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px; */
    }

    /* button:active {
      transform: scale(0.9);
    }

    button:disabled {
      opacity: 0.5;
      cursor: default;
      transform: none;
    }

    button.btn-primary {
      background: #40196d;
      border-color: #40196d;
      color: #fff;
    }

    button.btn-danger {
      background: #f7685b;
      border-color: #f7685b;
      color: #fff;
    } */
  }
`;

export const StyledSuccessModal = styled.div`
  width: 415px;
  max-width: 100%;
  height: auto;
  background: #ffffff;
  border: 1px solid rgba(219, 220, 224, 0.5);
  box-shadow: 15px 30px 40px rgba(64, 25, 109, 0.07);
  border-radius: 6px;
  padding: 46px 40px;
  display: flex;
  flex-direction: column;
  animation: fadeInUp 0.5s forwards;

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(30px);
    }

    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  .card-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    h2 {
      font-style: normal;
      font-weight: 800;
      font-size: 20px;
      line-height: 25px;
      text-align: center;
      color: #000000;
    }

    .icon {
      margin: 44px 0px;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #000000;
      margin-bottom: 30px;

      span {
        font-weight: 800;
      }
    }

    .error-message {
      margin-bottom: 20px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #000000;
    }
  }

  .ticket-title {
    margin-top: 10px;
    font-style: normal;
    font-weight: 800;
    font-size: 13px;
    line-height: 18px;
    color: #000000;
  }

  .ticket-box {
    background: #eff1ff;
    display: flex;
    width: 236.3px;
    margin-inline: auto;
    justify-content: space-between;
    padding: 16px 13px;
    margin: 10px 0 30px;
    border-radius: 5px;

    .ticket-id {
      font-style: normal;
      font-weight: 900;
      font-size: 13px;
      line-height: 25px;
      color: #40196d;
    }

    .icon-box {
      display: flex;
      column-gap: 5px;
      align-items: center;
      cursor: pointer;
      min-height: 29px;

      .text {
        font-style: normal;
        font-weight: 700;
        font-size: 11px;
        line-height: 13px;
        color: #40196d;
      }
    }
  }

  footer {
    display: flex;
    height: 48px;
    gap: 20px;
    justify-content: center;

    button {
      width: 101px;
    }
  }
`;

export const StyledFailureModal = styled(StyledAlertModal)`
  height: auto;
  p {
    span {
      font-weight: 800;
    }
  }

  .error-message {
    margin-bottom: 30px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
  }
`;
