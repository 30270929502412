/* eslint-disable no-unused-vars */
import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";

export const intializeDatadog = () => {
  /* Initializing the datadogRum object. */
  // datadogRum.init({
  //   applicationId: `${process.env.REACT_APP_DATADOG_APP_ID}`,
  //   clientToken: `${process.env.REACT_APP_DATADOG_CLIENT_TOKEN}`,
  //   site: "datadoghq.com",
  //   service: 'kuda-suites',
  //   // Specify a version number to identify the deployed version of your application in Datadog
  //   version: `${process.env.REACT_APP_KUDA_APP_VERSION}`,
  //   env: `${process.env.REACT_APP_DATADOG_TAG}`,
  //   sampleRate: 100,
  //   premiumSampleRate: 100,
  //   trackInteractions: true,
  //   defaultPrivacyLevel: "mask-user-input",
  // });

  /* Starting the session replay recording. */
  // datadogRum.startSessionReplayRecording();

  datadogLogs.init({
    clientToken: `${process.env.REACT_APP_DATADOG_CLIENT_TOKEN}`,
    site: "datadoghq.com",
    forwardErrorsToLogs: true,
    sampleRate: 100,
    version: `${process.env.REACT_APP_KUDA_APP_VERSION}`,
    env: `${process.env.REACT_APP_DATADOG_TAG}`,
    service: "Kuda-suites",
  });
};

export const trackUserAction = (event_name, event_properties, loggerType) => {
  /* Adding an action to the datadogRum object. */
  datadogLogs.logger.log(event_name, event_properties, loggerType);
};
