/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import TimeOut from "./Timeout";
import { useHistory } from "react-router-dom";
import useRemoteConfig from "hooks/useRemoteConfig";
import DashboardLayout from "layout/DashboardLayout";

const PrivateRoute = ({ component: Component, ...rest }) => {
  console.log("Private Route Hit at: ", Date.now());
  const history = useHistory();

  useRemoteConfig(`${process.env.REACT_APP_BANKLIST_KEY}`, "kudaBanks");

  const base64Url = btoa(
    encodeURIComponent(history.location.pathname + history.location.search)
  );
  const loginRoute = `/login?refUrl=${base64Url}`;
  localStorage.setItem("ref", base64Url);
  const context = useContext(AuthContext);

  const isAuth = context.isAuth;

  useEffect(() => {
    if (!isAuth) {
      history.push(loginRoute);
    }

    return () => {
      console.log("Login Route Hit at: ", Date.now());
    };
  }, [isAuth]);

  return (
    <>
      <Route
        {...rest}
        render={(props) =>
          isAuth ? (
            <DashboardLayout>
              <Component {...props} {...rest} />
            </DashboardLayout>
          ) : (
            <></>
          )
        }
      />

      <TimeOut />
    </>
  );
};

export default PrivateRoute;
