import { ModifiedMain } from "layout/Main";
import React, { useContext, useState } from "react";
import { manageFlaggedTransactionsHeaderLinks, paths } from "../HeaderLinks";
import { useParams, useHistory } from "react-router-dom";
import {
	StyledDetails,
	StyledFraudMgmtWrapper,
	StyledTransactionDetailsRow,
} from "../shared/styles/home.styles";
import { CopyIcon } from "../shared/icons";
import Icon from "library/Icons/Icon";
import Button from "library/Button";
import RejectionModal from "./RejectionModal";
import { ToastContext } from "context/ToastContext";
import moment from "moment";
import { formatToNaira } from "utils/utilityFunctions";
import MultiDocumentViewer from "components/Document";
import { FileIcon } from "components/SvgPack";
import {
	getAccountType,
	getIncomeSourceValue,
	getPurposeOfFund,
} from "../shared/utils";
import { useDispatch } from "react-redux";
import { TreatFlaggedTransactions } from "redux/feature/fraudManagement/fraudMgmtActions";

const toastConfig = {
	error: false,
	autoDismiss: true,
};

const ReviewManageFlaggedTransactions = () => {
	const { id } = useParams();
	const history = useHistory();
	const locationState = history?.location?.state;
	const toastContext = useContext(ToastContext);
	const dispatch = useDispatch();

	const [showRejection, setShowRejection] = useState(false);
	const [showDocs, setShowDocs] = useState(false);
	const [docUrl, setDocUrl] = useState("");
	const [loading, setLoading] = useState(false);

	const handleCopy = async (text) => {
		try {
			await navigator.clipboard.writeText(text);
			toastContext.setToast("Account number copied to clipboard", toastConfig);
		} catch (err) {
			toastConfig.error = true;
			toastContext.setToast("Error copying to clipboard", toastConfig);
		}
	};

	const handleShowDocs = (doc) => {
		if (doc) {
			setDocUrl(doc);
			setShowDocs(true);
		}
	};

	const onError = (message) => {
		setLoading(false);
		toastConfig.error = true;
		toastContext.setToast(message, toastConfig);
	};

	const onSuccess = (message) => {
		setLoading(false);
		toastContext.setToast(message, toastConfig);
		history.push(`${paths.manageFlaggedTransactions}/view/${locationState?.initResponse?.accountNumber}`);
	};

	const checkSupportingDocument = (value) => {
		if (
			value === undefined ||
			value === null ||
			value.length === 0 ||
			value?.[0] === null
		) {
			return false;
		}
		return true;
	};

	const handleSubmit = () => {
		setLoading(true);
		dispatch(
			TreatFlaggedTransactions(
				{
					id,
					approve: true,
				},
				onSuccess,
				onError
			)
		);
	};

	return (
		<ModifiedMain
			childSubRoute
			links={[
				...manageFlaggedTransactionsHeaderLinks,
				{
					path: `${paths.manageFlaggedTransactions}/view/${locationState?.initResponse?.accountNumber}`,
					name: `Manage Flagged Transactions: ${locationState?.initResponse?.accountNumber}`,
					pathAction: () => {
						history.push(`${paths.manageFlaggedTransactions}/view/${locationState?.initResponse?.accountNumber}`);
					},
				},
				{
					path: `${paths.manageFlaggedTransactions}/review/${id}`,
					name: "Review",
					pathAction: () => {
						history.push(`${paths.manageFlaggedTransactions}/review/${id}`, {
							initResponse: locationState?.initResponse
						});
					},
				},
			]}
		>
			<StyledFraudMgmtWrapper>
				<div className="top-header no-bp">
					<div className="header no-bp">
						<div className="base-header">
							<h1>Review </h1>
							<div
								className="base-info"
								onClick={() => {
									handleCopy(locationState?.initResponse?.accountNumber);
								}}
							>
								<p>{locationState?.initResponse?.accountNumber}</p>{" "}
								<div className="copy-svg">
									<Icon svg={CopyIcon} />
								</div>
							</div>
						</div>
					</div>

					<div className="action-holders">
						<Button
							disabled={loading}
							loading={loading}
							size="md"
							width="120px"
							variant="primary"
							onClick={() => {
								handleSubmit();
							}}
						>
							Accept
						</Button>
						<Button
							disabled={loading}
							size="md"
							width="120px"
							variant="danger"
							onClick={() => {
								setShowRejection(true);
							}}
						>
							Decline
						</Button>
					</div>
				</div>
				<div className="divider" />

				<div className="stickyWrapper">
					<StyledDetails>
						<div className="top-header">
							<h1>Flagged Details</h1>
						</div>

						<div className="divider" />

						<StyledTransactionDetailsRow>
							<div className="info">
								<span>Account Name</span>
								<p>{locationState?.initResponse?.accountName}</p>
							</div>
							<div className="info">
								<span>Account Number</span>
								<p>{locationState?.initResponse?.accountNumber}</p>
							</div>

							<div className="info">
								<span>BVN</span>
								<p>{locationState?.initResponse?.bvn ?? "N/A"}</p>
							</div>
							{/* <div className="info">
								<span>Account Type</span>
								<p>
									{getAccountType(locationState?.initResponse?.accountType)}
								</p>
							</div> */}
						</StyledTransactionDetailsRow>

						<div className="divider" />

						<StyledTransactionDetailsRow>
							<div className="info">
								<span>Date Created</span>
								<p>
									{moment(locationState?.initResponse?.creationDate).format(
										"Do MMMM, YYYY"
									)}
								</p>
							</div>

							<div className="info">
								<span>Email</span>
								<p>{locationState?.initResponse?.email ?? "N/A"}</p>
							</div>

							<div className="info">
								<span>Transaction Amount</span>
								<p>
									{locationState?.initResponse?.transactionAmount
										? formatToNaira(
											locationState?.initResponse?.transactionAmount
										)
										: locationState?.initResponse?.transactionAmount === 0
											? "₦0.00"
											: "N/A"}
								</p>
							</div>
						</StyledTransactionDetailsRow>

						<div className="divider" />

						<StyledTransactionDetailsRow>
							<div className="info">
								<span>Transaction Reference</span>
								<p>{locationState?.initResponse?.transactionReference}</p>
							</div>

							<div className="info">
								<span>Rule Type</span>
								<p>{locationState?.initResponse?.ruleType}</p>
							</div>
						</StyledTransactionDetailsRow>

						<div className="divider" />

						<div className="top-header mt-32">
							<h1>Counter Party Details</h1>
						</div>

						<div className="divider" />

						<StyledTransactionDetailsRow>
							<div className="info">
								<span>Bank Name</span>
								<p>
									{locationState?.initResponse?.counterPartyInstitutionName ??
										"N/A"}
								</p>
							</div>
							<div className="info">
								<span>Account Name</span>
								<p>
									{locationState?.initResponse?.counterPartyAccountName ??
										"N/A"}
								</p>
							</div>
							<div className="info">
								<span>Account Number</span>
								<p>
									{locationState?.initResponse?.counterPartyAccountNumber ??
										"N/A"}
								</p>
							</div>
						</StyledTransactionDetailsRow>
					</StyledDetails>

					<StyledDetails gapTop>
						<div className="top-header">
							<h1>Customer's Feedback</h1>
						</div>

						<div className="divider" />

						<StyledTransactionDetailsRow>
							<div className="info">
								<span>Source Funds</span>
								<p>
									{getIncomeSourceValue(
										locationState?.initResponse?.sourceOfFund
									)}
								</p>
							</div>
							<div className="info">
								<span>Purpose of Funds</span>
								<p>
									{getPurposeOfFund(locationState?.initResponse?.purposeOfFund)}
								</p>
							</div>
							<div className="info">
								<span>Supporting Document</span>

								<a
									href={locationState?.initResponse?.supportingDocument}
									className={`${checkSupportingDocument(
										locationState?.initResponse?.supportingDocument
									)
										? "link-text cursor file"
										: ""
										}`}
									onClick={(e) => {
										e.preventDefault();
										if (
											checkSupportingDocument(
												locationState?.initResponse?.supportingDocument
											)
										) {
											handleShowDocs(
												locationState?.initResponse?.supportingDocument
											);
										}
									}}
								>
									{checkSupportingDocument(
										locationState?.initResponse?.supportingDocument
									) ? (
										<>
											<FileIcon className="icon-image" />
											<span className="icon-text">Upload.jpg</span>
										</>
									) : (
										"N/A"
									)}
								</a>
							</div>
						</StyledTransactionDetailsRow>

						<div className="divider" />

						<StyledTransactionDetailsRow>
							<div className="info">
								<span>
									Have you clicked any link that you’re not sure about?
								</span>
								<p>
									{locationState?.initResponse?.hasClickedSuspiciousLink
										? "Yes"
										: "No"}
								</p>
							</div>
							<div className="info">
								<span>Enter Url (clicked link)</span>
								<p>{locationState?.initResponse?.suspiciousLink ?? "N/A"}</p>
							</div>
							<div className="info">
								<span>
									Did you share your login details or OTP verification code with
									anyone?
								</span>
								<p>
									{locationState?.initResponse?.hasSharedCredentials
										? "Yes"
										: "No"}
								</p>
							</div>
						</StyledTransactionDetailsRow>
					</StyledDetails>
				</div>
			</StyledFraudMgmtWrapper>

			<MultiDocumentViewer
				showDocs={showDocs}
				setShowDocs={setShowDocs}
				docUrl={docUrl}
			/>

			{showRejection && (
				<RejectionModal
					showModal={showRejection}
					setShowModal={setShowRejection}
					reasons={["Wrong input", "Other"]}
					redirectUrl={`${paths.manageFlaggedTransactions}/view/${locationState?.initResponse?.accountNumber}`}
					id={id}
				/>
			)}
		</ModifiedMain>
	);
};

export default ReviewManageFlaggedTransactions;
