import React, { useReducer, createContext } from "react";
import Toast from "../library/Toast";

const initialState = {
  open: false,
  tokenConfig: {
    error: false,
    autoDismiss: true,
  },
  message: "",
};

const ToastContext = createContext({});

function toastReducer(state, action) {
  switch (action.type) {
    case "OPEN":
      return {
        ...state,
        open: true,
        message: action.message,
        tokenConfig: action.tokenConfig,
      };
    case "CLOSE":
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
}

function ToastProvider(props) {
  const [state, dispatch] = useReducer(toastReducer, initialState);

  //time out toast

  const setToast = (message, tokenConfig) => {
    dispatch({
      type: "OPEN",
      message,
      tokenConfig,
    });

    if (tokenConfig.autoDismiss) {
      setTimeout(removeToast, 6000);
    }
  };

  const removeToast = () => {
    dispatch({
      type: "CLOSE",
    });
  };

  return (
    <ToastContext.Provider
      value={{
        setToast,
      }}
      {...props}
    >
      <Toast
        open={state.open}
        message={state.message}
        error={state.tokenConfig.error}
        autoDismiss={state.tokenConfig.autoDismiss}
      />
      {props.children}
    </ToastContext.Provider>
  );
}

export { ToastContext, ToastProvider };
