import * as types from "./noneResTypes";

const initialState = {
  isLoading: false,
  allPendingKYCRequests: [],
  pendingKYCRequest: {},
};

const noneResOperationsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.NONE_RES_START:
      return { ...state, isLoading: true };
    case types.NONE_RES_FAIL:
      return { ...state, isLoading: false };
    case types.NONE_RES_SUCCESS:
      return { ...state, isLoading: false };
    case types.GET_ALL_PENDING_REQUESTS:
      return { ...state, allPendingKYCRequests: payload };
    case types.GET_PENDING_REQUEST:
      return { ...state, pendingKYCRequest: payload };
    default:
      return state;
  }
};

export default noneResOperationsReducer;
