import * as types from "./financeOpsTypes";

const initialState = {
  isLoading: false,
  batchRecord: { data: [], totalRecords: 0 },
  bulkEnquiry: null,


	uploadPayslipData: null,
  uploadPayslipColumnHeaders: [],
  uploadPayslipErrorRows: [],
  uploadPayslipErrorRowsSN: [],
};

const financeOpsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.FIN_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.PWD_RECORD:
      return {
        ...state,
        isLoading: false,
        batchRecord: payload,
      };
    case types.FIN_SUCCESS:
    case types.FIN_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case types.CLEAR_SEARCH:
      return {
        ...state,
        isLoading: false,
        batchRecord: [],
      };
    case types.BULK_ENQUIRY:
      return {
        ...state,
        isLoading: false,
        bulkEnquiry: payload,
      };
    case types.UPLOAD_PAYSLIP_FILE:
      return {
        ...state,
        isLoading: false,
        uploadPayslipData: payload.uploadPayslipData,
        uploadPayslipColumnHeaders:
          payload.uploadPayslipColumnHeaders,
        uploadPayslipErrorRows:
          payload.uploadPayslipErrorRows,
        uploadPayslipErrorRowsSN:
          payload.uploadPayslipErrorRowsSN,
      };
    default:
      return state;
  }
};

export default financeOpsReducer;
