import * as types from "./customerOpsTypes";

const initialState = {
  isLoading: false,
  searchResult: null,
  moreDetails: null,
  searchParams: null,
  contactDetails: {},
  userDetails: {},
  userAccountDetails: [],
  userAccountBalance: {},
  accountMemo: [],
  overdraftSubAccountDetails: [],
  transactionList: [],
  transactionDetails: {},
  savingsSubAccountDetails: {},
  loanSubAccountDetails: {},
  documentUrls: {
    poaDocUrl: "",
    idDocUrl: "",
  },
  pageActionsData: {
    title: "",
    links: [],
    path: "",
    headerAction: null,
    pageTopContent: null,
    noSideBar: false,
    disabled: false,
  },
  searchCategory: null,
  bvnData: {},
  customerPassKeys: [],
};

const customerOpsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.CUSTOMER_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.SEARCH_ACCOUNT:
      return {
        ...state,
        isLoading: false,
        searchResult: payload,
      };
    case types.SEARCH_ACCOUNT_DETAILS:
      return {
        ...state,
        isLoading: false,
        moreDetails: payload,
      };
    case types.SEARCH_CUSTOMER_DETAILS:
      return {
        ...state,
        isLoading: false,
        userDetails: payload,
      };
    case types.GET_ACCOUNT_BALANCE:
      return {
        ...state,
        isLoading: false,
        userAccountBalance: payload,
      };
    case types.GET_ACCOUNT_MEMO:
      return {
        ...state,
        isLoading: false,
        accountMemo: payload,
      };
    case types.SEARCH_PARAMS:
      return {
        ...state,
        isLoading: false,
        searchParams: payload,
      };
    case types.CLEAR_SEARCH:
      return {
        ...state,
        searchParams: null,
        searchResult: null,
      };
    case types.CLEAR_DATA:
      return {
        isLoading: false,
        searchResult: null,
        moreDetails: null,
        searchParams: null,
        contactDetails: {},
        userDetails: {},
        userAccountDetails: [],
        userAccountBalance: {},
        accountMemo: [],
      };
    case types.EDIT_CONTACT_DETAILS:
      return {
        ...state,
        isLoading: false,
        contactDetails: payload,
      };
    case types.GET_CUSTOMER_BY_ID:
      return {
        ...state,
        isLoading: false,
        userAccountDetails: payload,
      };
    case types.CUSTOMER_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case types.CUSTOMER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case types.GET_OVERDRAFT:
      return {
        ...state,
        isLoading: false,
        overdraftSubAccountDetails: payload,
      };
    case types.GET_SAVINGS:
      return {
        ...state,
        isLoading: false,
        savingsSubAccountDetails: payload,
      };
    case types.GET_LOAN_ACCOUNT:
      return {
        ...state,
        isLoading: false,
        loanSubAccountDetails: payload,
      };
    case types.GET_TRANSACTION_HISTORY:
      return {
        ...state,
        transactionList: payload,
      };

    case types.GET_TRANSACTION_DETAILS:
      return {
        ...state,
        transactionDetails: payload,
      };
    case types.UPDATE_PAGE_ACTION_DATA: {
      return {
        ...state,
        pageActionsData: {
          ...state.pageActionsData,
          ...payload,
        },
      };
    }
    case types.UPDATE_SEARCH_CATEGORY: {
      return {
        ...state,
        searchCategory: payload,
      };
    }
    case types.STORE_CUSTOMER_BVN_DATA: {
      return {
        ...state,
        bvnData: payload,
      };
    }
    case types.GET_CUSTOMER_PASSKEYS: {
      return {
        ...state,
        customerPassKeys: payload,
      };
    }
    case types.GET_FLAGGED_TRANSACTIONS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case types.GET_FLAGGED_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case types.GET_FLAGGED_TRANSACTIONS_FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case types.GET_SINGLE_FLAGGED_TRANSACTIONS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case types.GET_SINGLE_FLAGGED_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case types.GET_SINGLE_FLAGGED_TRANSACTIONS_FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case types.RESOLVE_FLAGGED_TRANSACTIONS_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case types.RESOLVE_FLAGGED_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case types.RESOLVE_FLAGGED_TRANSACTIONS_FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default:
      return state;
  }
};

export default customerOpsReducer;
