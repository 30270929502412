import styled from "styled-components";

export const StyledNavBar = styled.div`
  padding: 22px 15px;
  position: relative;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: auto;
  }

  .count {
    background: #f7685b;
    border-radius: 25px;
    font-weight: 900;
    font-size: 11px;
    line-height: 13px;
    color: #fff;
    padding: 5px 9px;
  }
`;
