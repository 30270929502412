import { authPostCall, getCall } from "api/apiCalls";

const creditBaseUrl = "/api/v3";

export const GetFlaggedTransactions = (data) => {
  return authPostCall(`${creditBaseUrl}/Account/flagged-transactions/search`, data);
};

export const GetReviewFlaggedTransactions = (data) => {
  return authPostCall(`${creditBaseUrl}/Account/flagged-transactions/review`, data);
};

export const TreatFlaggedTransactions = (data) => {
  return authPostCall(`${creditBaseUrl}/Account/flagged-transactions/treat`, data);
};