import React from "react";

const LoadingIcon = () => {
  return (
    <svg
      width="65"
      height="64"
      viewBox="0 0 65 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M54.6148 63.8904H13.3795C7.932 63.8904 3.47656 59.435 3.47656 53.9875V12.7702C3.47656 7.32263 7.932 2.86719 13.3795 2.86719H54.5968C60.0443 2.86719 64.4998 7.32263 64.4998 12.7702V53.9875C64.5178 59.435 60.0624 63.8904 54.6148 63.8904Z"
        fill="#DFE3FF"
      />
      <path
        d="M51.6202 61.0623H10.403C4.95543 61.0623 0.5 56.6082 0.5 51.1623V9.93911C0.5 4.49318 4.95543 0.0390625 10.403 0.0390625H51.6202C57.0678 0.0390625 61.5232 4.49318 61.5232 9.93911V51.1442C61.5412 56.6082 57.0858 61.0623 51.6202 61.0623Z"
        fill="#40196D"
      />
      <path
        d="M24.5604 39.4396C23.9829 40.0171 23.978 40.9619 24.6222 41.464C25.9022 42.4619 27.3757 43.193 28.9555 43.6074C31.0138 44.1472 33.1787 44.1301 35.2282 43.5576C37.2777 42.9852 39.1381 41.878 40.6188 40.3497C42.0994 38.8213 43.147 36.9267 43.6542 34.8601C44.1614 32.7934 44.1099 30.6291 43.505 28.5889C42.9001 26.5488 41.7636 24.7061 40.2119 23.2499C38.6603 21.7937 36.7493 20.7763 34.6749 20.3019C33.0827 19.9379 31.4382 19.9038 29.8417 20.1957C29.0382 20.3426 28.6052 21.1823 28.8497 21.9616C29.0943 22.7409 29.9247 23.1613 30.7334 23.0469C31.8227 22.8928 32.9351 22.9382 34.0156 23.1853C35.5787 23.5427 37.0187 24.3093 38.1879 25.4066C39.3571 26.5039 40.2134 27.8924 40.6692 29.4297C41.125 30.967 41.1638 32.5979 40.7817 34.1551C40.3995 35.7124 39.6101 37.14 38.4944 38.2916C37.3787 39.4433 35.9769 40.2775 34.4325 40.7089C32.8882 41.1403 31.2569 41.1532 29.7059 40.7464C28.6338 40.4652 27.6267 39.9904 26.7326 39.3496C26.0687 38.8738 25.138 38.862 24.5604 39.4396Z"
        fill="white"
      >
        <animateTransform
          attributeType="xml"
          attributeName="transform"
          type="rotate"
          from="360 32.5 32"
          to="0 32.5 32"
          dur="0.5s"
          additive="sum"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  );
};

export default LoadingIcon;
