import React, { useEffect } from "react";
import { StyledNavBar } from "./NavBar.styles";
import { DashboardModuleIcon } from "../../components/SvgPack";
import Nav from "../Nav";
import Icon from "../../library/Icons/Icon";
import { useDispatch, useSelector } from "react-redux";
import { getNotificationCount } from "../../redux/feature/user/userActions";
import getModuleIcon from "./modules";
import { removeNestedDuplicates, sortMenuByName } from "utils/utilityFunctions";

function NavBar({ toggleMobileNavBar }) {
  const dispatch = useDispatch();
  const notificationCount = useSelector(
    (state) => state.users.notificationCount
  );

  useEffect(() => {
    if (!notificationCount) {
      dispatch(getNotificationCount());
    }
  }, [notificationCount, dispatch]);

  const userData = JSON.parse(window.localStorage.getItem("KudaAppUserData"));

  let sortedMenu = sortMenuByName(userData.rolePermissions, "name");

  sortedMenu = removeNestedDuplicates(sortedMenu, "moduleGroup", "id");

  sortedMenu = sortedMenu.filter(
    (menu) => menu.moduleGroup.isDeleted === false
  );

  const renderModules = (rolePermissions) => {
    let rolesToUse =
      process.env.REACT_APP_ENVIRONMENT === "Test"
        ? rolePermissions
        : rolePermissions.filter((item) => item?.moduleGroup?.id !== 64);

    return (
      rolesToUse
        .filter((item) => item?.moduleGroup?.id !== 89) // Remove Refund Management
        // .filter((item) => item?.moduleGroup?.id !== 56) // Remove Growth and Marketing
        .map((item, idx) => (
          <Nav
            toggleMobileNavBar={toggleMobileNavBar}
            key={idx}
            link={item.moduleGroup.url}
            name={item.moduleGroup.name}
            icon={<Icon svg={getModuleIcon(item.moduleGroup.url.substr(1))} />}
          />
        ))
    );
  };

  return (
    <StyledNavBar>
      <Nav
        toggleMobileNavBar={toggleMobileNavBar}
        link="/dashboard"
        icon={<Icon svg={DashboardModuleIcon} />}
        name={
          <div className="container">
            <span>Dashboard</span>
          </div>
        }
        count={
          <span className="count">
            {notificationCount > 250 ? "250+" : notificationCount}
          </span>
        }
      />
      {userData && userData.rolePermissions && renderModules(sortedMenu)}
    </StyledNavBar>
  );
}

export default NavBar;
