import React from "react";
import PropTypes from "prop-types";
import { StyledAvatar } from "./Avatar.styles";

const Avatar = ({ shape, size, src, name, icon, type, text }) => {
  let firstText;
  let secondText;
  if (text) {
    let splitText = text.split(" ");
    firstText = splitText[0] ? splitText[0].charAt(0) : "";
    secondText = splitText[1] ? splitText[1].charAt(0) : "";
  }

  return (
    <StyledAvatar shape={shape} size={size} src={src} name={name} type={type}>
      {(text || type === "text") && (
        <div className="text">
          <span>{firstText}</span>
          <span>{secondText}</span>
        </div>
      )}
      {(src || type === "image") && <img src={src} alt={name} />}
    </StyledAvatar>
  );
};

Avatar.propTypes = {
  shape: PropTypes.string,
  size: PropTypes.string,
  src: PropTypes.string,
  name: PropTypes.string,
  icon: PropTypes.node,
};

Avatar.defaultProps = {
  name: "Avatar",
  shape: "square",
  size: "sm",
};

export default Avatar;
