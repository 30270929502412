import styled from "styled-components";
import breakpoint from "library/breakpoint";

export const StyledRemmitanceHome = styled.div`
  width: 50%;
  @media screen and (max-width: 80rem) {
    width: 80%;
  }
  @media screen and (max-width: 70rem) {
    width: 100%;
  }
`;

export const StyledRemmitanceMgmtWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.noGap ? "0px" : "18px")};
  min-height: 100vh;

  .top-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 18px;

    .top-header-actions {
      display: flex;
      align-items: center;

      .loader-holder {
        padding-top: 3px;
      }

      button,
      .loader-holder {
        margin-left: 8px;
      }
    }

    h1 {
      font-style: normal;
      font-weight: 900;
      font-size: 20px;
      line-height: 25px;
      color: #121212;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 18px;

    h1 {
      font-style: normal;
      font-weight: 900;
      font-size: 20px;
      line-height: 25px;
      color: #121212;
    }
  }

  .tab-group {
    padding-top: 24px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 18px;

    h1 {
      font-style: normal;
      font-weight: 900;
      font-size: 20px;
      line-height: 25px;
      color: #121212;
    }
  }

  .divider {
    height: 1px;
    background-color: #dbdce0;
  }

  .gap-4 {
    gap: 4px
  }

  .underline-none {
    text-decoration: none
  }

  @media screen and (max-width: 450px) {
    .top-header {
      flex-direction: column;
      align-items: start;
      gap: 10px;
    }
  }
`;

export const StyledSingleFxRateTab = styled.div`
  width: 574px;
  margin-top: 32px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.06);
  max-width: 100%;
  background: #ffffff;
  padding: 24px;
  flex-direction: column;

  @media ${breakpoint.device.mobileL} {
    width: 100%;
  }

  @media ${breakpoint.device.tablet} {
    width: 100%;
  }

  .label {
    font-weight: normal;
    font-size: 13.5px;
    line-height: 18px;
    color: ${(props) => (props.disabled ? "#000" : "#000")};
    color: "#000";
    text-transform: capitalize;
    margin-bottom: 10px;
  }

  .biller-top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .biller-top-action {
      color: #979797;
      font-weight: 800;
      font-size: 13px;
      line-height: 18px;
      cursor: pointer;
      display: flex;
      align-items: center;

      .active {
        color: #40196d;
      }

      .switch-text {
        padding-right: 14px;
        cursor: initial;
      }
    }
  }

  .biller-img-holder {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;

    svg {
      width: 80px;
      height: 80px;
    }

    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }

    .upload-holder {
      position: relative;

      .upload-input {
        opacity: 0;
      }

      p {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;

        svg {
          width: 10px;
          height: 10px;
          margin-right: 4px;
        }
      }
    }

    p {
      color: #40196d;
      font-style: normal;
      font-weight: 800;
      line-height: 20px;
      font-size: 13px;
      margin-top: 12px;
      cursor: pointer;
    }
  }

  .form-holder {
    border-top: 1px solid #dbdce0;
    padding-top: 24px;

    &.top-padded {
      margin-top: 24px;
    }

    &.no-border {
      border-top: 0px;
      padding-top: 0px;
    }

    .input-container {
      margin-bottom: 25px;
    }

    .action-containers {
      display: flex;
      align-items: center;

      button {
        &:last-child {
          margin-left: 20px;
        }
      }
    }

    .action-container {
      width: 172px;

      @media ${breakpoint.device.mobileL} {
        width: 100%;
      }

      @media ${breakpoint.device.tablet} {
        width: 100%;
      }
    }

    .action-container-box {
      display: flex;
      gap: 10px;
    }
  }
`;