import React from "react";
import CardItem from "./CardItem";
import StyledCard from "./HomeModuleCard.styles";

const HomeModuleCard = ({ items }) => {
  return (
    <StyledCard>
      <div className="card-container">
        {items.map((item, idx) => (
          <CardItem
            key={idx}
            icon={item.icon}
            head={item.head}
            description={item.description}
            handleClick={item.isActive === false ? () => {} : item.handleClick}
            // if isActive is undefined, default isActive to true
            isActive={item.isActive === undefined ? true : item.isActive}
            enabled={item.enabled}
          />
        ))}
      </div>
    </StyledCard>
  );
};

export default HomeModuleCard;
