import { ModifiedMain } from "layout/Main";

import UpDateFXRateForm from "./UpdateRateForm";
import { updateFxRateHeaderLink } from "modules/RemittanceManagement/HeaderLinks";
import { StyledRemmitanceMgmtWrapper } from "modules/RemittanceManagement/shared/styles/home.styles";

const UpdateFXRate = () => {

    return (
        <ModifiedMain
            childSubRoute
            links={updateFxRateHeaderLink}
            path={"/remittance-management/update-fx-rate"}
            isSticky={false}
        >
            <StyledRemmitanceMgmtWrapper noGap>
                <div className="top-header">
                    <h1>Update Fx Rate</h1>
                </div>
                <div className="divider" />
                <UpDateFXRateForm />
            </StyledRemmitanceMgmtWrapper>
        </ModifiedMain>
    );
};

export default UpdateFXRate;
