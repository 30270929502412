import React, { useContext } from "react";
import PropTypes from "prop-types";
import { StyledInput, StyledIconWrapper } from "./NumberInput.styles";
import { ThemeContext } from "styled-components";
import NumberFormat from "react-number-format";

function NumberInput({
  name,
  value,
  type,
  placeholder,
  size,
  message,
  label,
  error,
  onChange,
  variant,
  width,
  disabled,
  icon,
  decimalScale
}) {
  const theme = useContext(ThemeContext);
  return (
    <StyledInput
      theme={theme}
      variant={variant}
      error={error}
      width={width}
      disabled={disabled}
      size={size}
      icon={icon}
      type={type}
    >
      {label && <label>{label}</label>}
      <div className="input-wrap">
        {icon && <StyledIconWrapper>{icon}</StyledIconWrapper>}
        <NumberFormat
          decimalScale={decimalScale}
          disabled={disabled}
          thousandSeparator={true}
          placeholder={placeholder}
          value={value}
          onValueChange={(values) => {
            const { value } = values;
            onChange(name, value);
          }}
        />
      </div>
      {error && <span className="error">{message}</span>}
    </StyledInput>
  );
}

NumberInput.propTypes = {};

NumberInput.defaultProps = {
  decimalScale: undefined
};

export default NumberInput;
