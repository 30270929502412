import * as types from "./moduleTypes";

const initialState = {
  isLoading: false,
  moduleGroup: [],
};

const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.MODULE_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.MODULE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case types.MODULE_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case types.GET_ALL_MODULE_GROUP:
      return {
        ...state,
        isLoading: false,
        moduleGroup: payload,
      };
    default:
      return state;
  }
};

export default userReducer;
