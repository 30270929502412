import FXRateHistory from "./FXRateHistory";
import { paths } from "./HeaderLinks";
import RemittanceMgmtHome from "./Home";
import ManageFXRates from "./ManageFXRates";
import AddFXRate from "./ManageFXRates/AddFXRate";
import UpdateFXRate from "./ManageFXRates/UpdateFxRate";

export const routes = [
  {
    path: paths.home,
    page: RemittanceMgmtHome,
    exact: true,
  },
  {
    path: paths.fxRateHistory,
    page: FXRateHistory,
    exact: true
  },
  {
    path: paths.managefxRates,
    page: ManageFXRates,
    exact: true
  },
  {
    path: paths.managefxRates,
    page: ManageFXRates,
    exact: true
  },
  {
    path: paths.addNewFxRate,
    page: AddFXRate,
    exact: true
  },
  {
    path: paths.updateFxRate,
    page: UpdateFXRate,
    exact: true
  }
]