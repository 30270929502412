import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Input from "library/Input/Input";
import Select from "library/Select/Select";
import Button from "library/Button/Button";
import NumberInput from "library/NumberInput/NumberInput";

import { StyledSingleFxRateTab } from "modules/RemittanceManagement/shared/styles/home.styles";
import SuccessModal from "modules/BillerManagement/shared/modals/SuccessModal";
import { SuccessModalIcon } from "modules/BillerManagement/shared/icons/SuccessModalIcon";
import { paths } from "modules/RemittanceManagement/HeaderLinks";
import { AdditionFormIcon, EqualsFormIcon, ExchangeFormIcon, MultipleFormIcon } from "modules/RemittanceManagement/shared/icons";
import { currencyCodeMap } from "modules/RemittanceManagement/shared/utils";

import EurIcon from "../../shared/icons/eur.svg";
import NGNIcon from "../../shared/icons/ngn.svg";
import USDIcon from "../../shared/icons/usd.svg";
import TZSIcon from "../../shared/icons/tzs.svg";
import GBPIcon from "../../shared/icons/gbp.svg";

import { ToastContext } from "context/ToastContext";
import { updateFxRate } from "redux/feature/remittanceManagement/renittabceMgmtActions";
import { formatBalance } from "utils/utilityFunctions";


const UpDateFXRateForm = () => {
    const history = useHistory();
    const toastContext = useContext(ToastContext);
    const dispatch = useDispatch();

    const queryParams = new URLSearchParams(history.location.search);
    const inViewItemId = parseFloat(queryParams.get('id'));

    const { fxRateInView, isLoading } = useSelector((state) => ({
        fxRateInView: state?.remittanceMgmt?.fxRateInView,
        isLoading: state.remittanceMgmt.updateFxRateLoading,
    }));

    const [values, setValues] = useState({
        sourceCurrency: fxRateInView?.sourceCurrency || "",
        destinationCurrency: fxRateInView?.destinationCurrency || "",
        imtoRate: formatBalance(fxRateInView?.imtoRate) || "",
        marginFee: formatBalance(fxRateInView?.marginFee) || "",
        kudaRate: formatBalance(fxRateInView?.kudaRate) || "",
    });

    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [errors, setErrors] = useState({});

    const toastConfig = {
        error: false,
        autoDismiss: true,
    };

    const onError = (message, errorData) => {
        toastConfig.error = true;
        toastContext.setToast(message, toastConfig);

        setErrors({});
        if (errorData !== undefined) {
            let validationErrors = {};

            errorData.forEach((item) => {
                if (item.field) {
                    validationErrors[item.field] = item.message;
                }
            });

            setErrors((prevState) => ({
                ...prevState,
                ...validationErrors,
            }));
        }
    };

    const onSuccess = () => {
        setShowSuccessModal(true);
    };

    const handleSubmit = () => {
        setErrors({});

        const { marginFee, imtoRate } = values || {}
        const data = {
            fxRateId: fxRateInView?.id,
            marginFee,
            imtoRate
        }

        dispatch(updateFxRate(data, onSuccess, onError));
    };

    const validate = () => {
        let formValid = true;
        Object.keys(values).forEach((key) => {
            if (!values[key] || values[key] === "") {
                formValid = false;
            }
        });
        if (
            parseFloat(values.imtoRate) === parseFloat(fxRateInView?.imtoRate) &&
            parseFloat(values.marginFee) === parseFloat(fxRateInView?.marginFee)
        ) {
            formValid = false;
        }

        return formValid;
    };

    const handleInputChange = (name, value) => {
        const validInput = /^[0-9]*\.?[0-9]*$/;

        if (!validInput.test(value)) {
            return;
        }
        setValues((prevValues) => {
            const updatedValues = {
                ...prevValues,
                [name]: value,
            };

            const imtoRate = parseFloat(updatedValues.imtoRate);
            const marginFee = parseFloat(updatedValues.marginFee);

            if (!isNaN(imtoRate) && !isNaN(marginFee)) {
                updatedValues.kudaRate = (imtoRate + marginFee).toFixed(2);
            } else {
                updatedValues.kudaRate = '';
            }

            return updatedValues;
        });
    };

    const handleDropDownSelection = (name, value) => {
        setValues({
            ...values,
            [name]: value,
        });
    };

    const currencies = [
        {
            imageUrl: EurIcon,
            name: `Euro(€)`,
        },
        {
            imageUrl: NGNIcon,
            name: `Nigerian Naira(₦)`,
        },
        {
            imageUrl: USDIcon,
            name: `United States Dollars($)`,
        },
        {
            imageUrl: TZSIcon,
            name: `Tanzanian Shilling (TZS)`,
        },
        {
            imageUrl: GBPIcon,
            name: `Great Britain Pounds (£)`,
        },
    ];

    const getCurrentCurrencyObject = (currencyCode) => {
        const currencyName = Object.keys(currencyCodeMap).find(
            (key) => currencyCodeMap[key] === currencyCode
        );

        if (currencyName) {
            return currencies.find((currency) => currency.name === currencyName);
        }

        return null;
    };

    useLayoutEffect(() => {
        if (!fxRateInView?.sourceCurrency || inViewItemId !== fxRateInView?.id) {
            history.push(paths?.managefxRates)
        }
    }, [])
    return (
        <StyledSingleFxRateTab>
            <div className="form-holder">
                <div className="">
                    <Select
                        label="Source Currency"
                        width="100%"
                        placeholder="Choose"
                        options={currencies.filter((item) => item.name !== values?.destinationCurrency)}
                        onGetSelectValue={(value) =>
                            handleDropDownSelection("sourceCurrency", value)
                        }
                        optionKey={"id"}
                        showOptionImage={true}
                        value={getCurrentCurrencyObject(values?.sourceCurrency)?.name ?? ""}
                        disabled
                    />

                    <div>
                        <ExchangeFormIcon />
                    </div>

                </div>

                <div>
                    <Select
                        label="Destination Currency"
                        width="100%"
                        placeholder="Choose"
                        options={currencies.filter((item) => item.name !== values?.sourceCurrency)}
                        onGetSelectValue={(value) =>
                            handleDropDownSelection("destinationCurrency", value)
                        }
                        optionKey={"id"}
                        showOptionImage={true}
                        value={getCurrentCurrencyObject(values?.destinationCurrency)?.name ?? ""}
                        disabled
                    />
                    <div>
                        <AdditionFormIcon />
                    </div>

                </div>

                <div >
                    <NumberInput
                        decimalScale={2}
                        label="IMTO Rate"
                        name="imtoRate"
                        placeholder="Enter IMTO Rate"
                        value={values?.imtoRate}
                        onChange={(_, value) => { handleInputChange("imtoRate", value) }}
                    />
                    <div>
                        <AdditionFormIcon />
                    </div>
                </div>
                <div >
                    <NumberInput
                        decimalScale={2}
                        name="marginFee"
                        label="Margin Fee"
                        placeholder="Enter Margin Fee"
                        value={values?.marginFee}
                        onChange={(_, value) => { handleInputChange("marginFee", value) }}
                    />
                    <div>
                        <EqualsFormIcon />
                    </div>
                </div>
                <div>
                    <Input
                        name="kudaRate"
                        label="Kuda Rate"
                        width="100%"
                        placeholder="Auto-populate field"
                        value={values?.kudaRate}
                        disabled
                    />
                </div>


                <div className="action-container-box margi mt-lg-5">
                    <div className="action-container ">
                        <Button
                            size="lg"
                            width="100%"
                            variant="secondary"
                            onClick={() => history.push(paths.managefxRates)}
                        >
                            Cancel
                        </Button>
                    </div>
                    <div className="action-container ">
                        <Button
                            loading={isLoading}
                            size="lg"
                            width="100%"
                            variant="primary"
                            onClick={handleSubmit}
                            disabled={!validate() || isLoading}
                        >
                            Update Rate
                        </Button>
                    </div>
                </div>
            </div>

            {showSuccessModal && (
                <SuccessModal
                    setShowModal={setShowSuccessModal}
                    primaryBtnLabel="Okay"
                    isLoading={false}
                    setShowSuccessModal={setShowSuccessModal}
                    onDissmiss={() => {
                        history.push(paths.managefxRates);
                    }}
                >
                    <h2>{values?.sourceCurrency} - {values?.destinationCurrency} Rate Updated</h2>

                    <div className="icon">
                        <SuccessModalIcon />
                    </div>

                    <p className="error-message">
                        You've successfully updated the rate for <br />{values?.sourceCurrency} - {values?.destinationCurrency}.
                    </p>
                </SuccessModal>
            )}
        </StyledSingleFxRateTab>
    );
};

export default UpDateFXRateForm;
