import React, { useContext, useEffect, useState } from "react";
import Avatar from "../../library/Avatar";
import NavBar from "../NavBar";
import { StyledSideBar } from "./SideBar.styles";
import { AuthContext } from "../../context/AuthContext";
import { useHistory } from "react-router-dom";

function SideBar({ sideBarWidth, mobileNavBar, toggleMobileNavBar }) {
  const context = useContext(AuthContext);
  const logout = context.logout;
  const googleProfile = context.googleProfile;
  let history = useHistory();

  const [isMobile, setMobile] = useState(window.innerWidth < 800);
  const closeNavbar = () => {
    setMobile(window.innerWidth < 800);

    toggleMobileNavBar(false);
  };

  useEffect(() => {
    window.addEventListener("resize", closeNavbar);
    return () => window.removeEventListener("resize", closeNavbar);
  });

  const handleLogout = () => {
    history.push("/dashboard");
    logout();
  };

  return (
    <>
      <StyledSideBar sideBarWidth={sideBarWidth} mobileNavBar={mobileNavBar}>
        <div className="userinfo">
          <div className="userinfo__nav">
            <Avatar
              shape="square"
              size="sm"
              src={googleProfile?.imageUrl}
              text={googleProfile?.imageUrl ? null : googleProfile?.name}
            />
            <div className="userinfo__nav-text">
              <h4>Hi, {googleProfile?.name}</h4>
              <p className="cursor" onClick={handleLogout}>
                Log Out
              </p>
            </div>
          </div>
        </div>

        <NavBar toggleMobileNavBar={toggleMobileNavBar} />
      </StyledSideBar>
    </>
  );
}

export default SideBar;
