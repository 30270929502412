import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { StyledToast } from "./Toast.styles";

const Toast = ({
  success,
  information,
  message,
  error,
  open,
  removeToast,
  autoDismiss,
}) => {
  const renderToast = () => {
    if (open) {
      return (
        <StyledToast
          success={success}
          information={information}
          message={message}
          error={error}
          // style={!autoDismiss ? { width: 224, padding: "15px 22px" } : {}}
        >
          <span>{message}</span>
          {!autoDismiss && (
            <button
              style={{
                marginLeft: "auto",
                background: "transparent",
                border: "none",
                cursor: "pointer",
              }}
              onClick={removeToast}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  fill="#4D4D4D"
                  fillRule="evenodd"
                  d="M.364.636c-.48.48-.48 1.256 0 1.736L4.992 7 .364 11.628A1.227 1.227 0 102.1 13.364l4.628-4.628 4.628 4.628a1.227 1.227 0 101.736-1.736L8.464 7l4.628-4.628A1.227 1.227 0 1011.356.636L6.728 5.264 2.1.636a1.227 1.227 0 00-1.736 0z"
                  clipRule="evenodd"
                  style={{ mixBlendMode: "color-burn" }}
                ></path>
                <path
                  fill="#000"
                  fillOpacity="0.4"
                  fillRule="evenodd"
                  d="M.364.636c-.48.48-.48 1.256 0 1.736L4.992 7 .364 11.628A1.227 1.227 0 102.1 13.364l4.628-4.628 4.628 4.628a1.227 1.227 0 101.736-1.736L8.464 7l4.628-4.628A1.227 1.227 0 1011.356.636L6.728 5.264 2.1.636a1.227 1.227 0 00-1.736 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          )}
        </StyledToast>
      );
    }
  };

  return ReactDOM.createPortal(renderToast(), document.body);
};

Toast.propTypes = {
  success: PropTypes.bool,
  information: PropTypes.string,
  error: PropTypes.bool,
  message: PropTypes.string,
  open: PropTypes.bool,
};

Toast.defaultProps = {
  message: "",
  success: true,
};

export default Toast;
