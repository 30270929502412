import FraudTransactionsReport from "./FraudTransactionsReport";
import ReviewFraudTransactionsReport from "./FraudTransactionsReport/Review";
import { paths } from "./HeaderLinks";
import FraudManagementHome from "./Home";
import ManageFlaggedTransactions from "./ManageFlaggedTransactions";
import ReviewManageFlaggedTransactions from "./ManageFlaggedTransactions/Review";
import ViewManageFlaggedTransactions from "./ManageFlaggedTransactions/ViewTransactions";

export const routes = [
  {
    path: paths.home,
    page: FraudManagementHome,
    exact: true,
  },
	{
    path: paths.manageFlaggedTransactions,
    page: ManageFlaggedTransactions,
    exact: true,
  },
	{
    path: paths.viewManageFlaggedTransactions,
    page: ViewManageFlaggedTransactions,
    exact: true,
  },
	{
    path: paths.fraudTransactionsReport,
    page: FraudTransactionsReport,
    exact: true,
  },
	{
    path: paths.reviewManageFlaggedTransactions,
    page: ReviewManageFlaggedTransactions,
    exact: true,
  },
	{
    path: paths.reviewFraudTransactionsReport,
    page: ReviewFraudTransactionsReport,
    exact: true,
  },
];
