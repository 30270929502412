import * as types from "./moduleTypes";
import * as moduleApi from "./moduleApi";

export const getModuleGroup = (onSuccess, onError) => async (dispatch) => {
  try {
    dispatch({ type: types.MODULE_START });
    const res = await moduleApi.GetAllModuleGroup();
    const status = res.data.status;
    const allData = res.data.data ? res.data.data : [];
    const data =
      process.env.REACT_APP_ENVIRONMENT === "Test"
        ? allData.filter((data) => data.isDeleted === false)
        : allData;
    const message = res.data.message || "Server Error Please Reload the page";
    if (status && data.length > 0) {
      localStorage.setItem("KudaAppModules", JSON.stringify(data));
      dispatch({ type: types.GET_ALL_MODULE_GROUP, payload: data });
      onSuccess?.(message);
    } else {
      dispatch({ type: types.GET_ALL_MODULE_GROUP, payload: [] });
      onError?.(message);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Server Error Please Reload the page";
    dispatch({ type: types.MODULE_FAIL });
    onError?.(message);
  }
};

export const createModuleGroup =
  (data, onSuccess = null, onError = null) =>
  async (dispatch) => {
    try {
      dispatch({ type: types.MODULE_START });
      const res = await moduleApi.CreateModuleGroup(data);
      const status = res.data.status;
      const message = res.data.message || "Server Error Please Reload the page";
      if (status) {
        dispatch({ type: types.MODULE_SUCCESS });
        onSuccess?.(message);
      } else {
        dispatch({ type: types.MODULE_FAIL });
        onError?.(message);
      }
    } catch (error) {
      const message =
        error?.response?.data?.message || "Server Error Please Reload the page";
      dispatch({ type: types.MODULE_FAIL });
      onError?.(message);
    }
  };

export const editModuleGroup =
  (data, onSuccess, onError) => async (dispatch) => {
    try {
      dispatch({ type: types.MODULE_START });
      const res = await moduleApi.EditModuleGroup(data);
      const status = res.data.status;
      const message = res.data.message || "Server Error Please Reload the page";
      if (status) {
        dispatch({ type: types.MODULE_SUCCESS });
        onSuccess?.(message);
      } else {
        dispatch({ type: types.MODULE_FAIL });
        onError?.(message);
      }
    } catch (error) {
      const message =
        error?.response?.data?.message || "Server Error Please Reload the page";
      dispatch({ type: types.MODULE_FAIL });
      onError?.(message);
    }
  };

export const deleteModuleGroup =
  (data, onSuccess, onError) => async (dispatch) => {
    try {
      dispatch({ type: types.MODULE_START });
      const res = await moduleApi.DeleteModuleGroup(data);
      const status = res.data.status;
      const message = res.data.message || "Server Error Please Reload the page";
      if (status) {
        dispatch({ type: types.MODULE_SUCCESS });
        onSuccess?.(message);
      } else {
        dispatch({ type: types.MODULE_FAIL });
        onError?.(message);
      }
    } catch (error) {
      const message =
        error?.response?.data?.message || "Server Error Please Reload the page";
      dispatch({ type: types.MODULE_FAIL });
      onError?.(message);
    }
  };

export const createModule = (data, onSuccess, onError) => async (dispatch) => {
  try {
    dispatch({ type: types.MODULE_START });
    const res = await moduleApi.CreateModule(data);
    const status = res.data.status;
    const message = res.data.message || "Server Error Please Reload the page";
    if (status) {
      dispatch({ type: types.CREATE_MODULE });
      onSuccess?.(message);
    } else {
      dispatch({ type: types.CREATE_MODULE });
      onError?.(message);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Server Error Please Reload the page";
    dispatch({ type: types.CREATE_MODULE });
    onError?.(message);
  }
};

export const editModule = (data, onSuccess, onError) => async (dispatch) => {
  try {
    dispatch({ type: types.MODULE_START });
    const res = await moduleApi.EditModule(data);
    const status = res.data.status;
    const message = res.data.message || "Server Error Please Reload the page";
    if (status) {
      dispatch({ type: types.MODULE_SUCCESS });
      onSuccess?.(message);
    } else {
      dispatch({ type: types.MODULE_FAIL });
      onError?.(message);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Server Error Please Reload the page";
    dispatch({ type: types.MODULE_FAIL });
    onError?.(message);
  }
};

export const deleteModule = (data, onSuccess, onError) => async (dispatch) => {
  try {
    dispatch({ type: types.MODULE_START });
    const res = await moduleApi.DeleteModule(data);
    const status = res.data.status;
    const message = res.data.message || "Server Error Please Reload the page";
    if (status) {
      dispatch({ type: types.MODULE_SUCCESS });
      onSuccess?.(message);
    } else {
      dispatch({ type: types.MODULE_FAIL });
      onError?.(message);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Server Error Please Reload the page";
    dispatch({ type: types.MODULE_FAIL });
    onError?.(message);
  }
};

export const getAllModule = () => async (dispatch) => {
  try {
    dispatch({ type: types.MODULE_START });
    const res = await moduleApi.GetAllModuleGroup();
    const data = res.data.data;
    const status = res.data.status;
    const message = res.data.message || "Server Error Please Reload the page";
    if (status) {
      dispatch({ type: types.GET_ALL_MODULE, payload: data });
    } else {
      dispatch({ type: types.MODULE_FAIL });
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Server Error Please Reload the page";
    dispatch({ type: types.MODULE_FAIL });
  }
};
