import React, { useContext } from "react";
import PropTypes from "prop-types";
import { StyledInput, StyledIconWrapper } from "./Input.styles";
import { ThemeContext } from "styled-components";
import Icon from "library/Icons/Icon";
import { MarkIcon } from "components/SvgPack";

function Input({
  id,
  value,
  type,
  placeholder,
  size,
  name,
  message,
  label,
  error,
  onChange,
  width,
  disabled,
  icon,
  success,
  successMessage,
  customChild = undefined,
  noLabel = false,
  ...rest
}) {
  const theme = useContext(ThemeContext);
  return (
    <StyledInput
      theme={theme}
      error={error}
      width={width}
      disabled={disabled}
      size={size}
      icon={icon}
      type={type}
      success={success}
      noLabel={noLabel}
    >
      {label && <label>{label}</label>}
      <div className="input-wrap">
        {customChild ? (
          customChild()
        ) : (
          <>
            {icon && <StyledIconWrapper>{icon}</StyledIconWrapper>}
            <input
              id={id}
              type={type}
              value={value}
              name={name}
              onChange={onChange}
              placeholder={placeholder}
              size={size}
              disabled={disabled}
              {...rest}
            />
          </>
        )}
      </div>
      {error && <span className="error">{message}</span>}
      {success && (
        <div className="success-container">
          <Icon svg={MarkIcon} />{" "}
          <span className="success">{successMessage}</span>
        </div>
      )}
    </StyledInput>
  );
}

Input.propTypes = {
  id: PropTypes.string, // This ID for input
  type: PropTypes.string, // The type of input: Text or String
  name: PropTypes.string, // The name of the input
  message: PropTypes.string, // The message by the input
  value: PropTypes.string, // The input content value
  size: PropTypes.string, // The size of the input group
  placeholder: PropTypes.string, // The placeholder value for the input
  label: PropTypes.string, // The label tag of an input
  onChange: PropTypes.func, // The callback function trigerred when user inputs
  error: PropTypes.bool, // Triggered when a user inputs an error in the input
  icon: PropTypes.any, //The icon value for input with icons,
  success: PropTypes.bool,
  successMessage: PropTypes.string,
};

Input.defaultProps = {
  placeholder: "e.g Senior Designer",
  success: false,
  successMessage: undefined,
};

export default Input;
