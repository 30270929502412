import * as types from "./fraudMgmtTypes";
import * as fraudMgmtApi from "./fraudMgmtApi";

export function GetFlaggedTransactions(data, onSuccess, onError) {
  return async (dispatch) => {
    try {
      dispatch({ type: types.FRAUD_MGMT_START });
      const res = await fraudMgmtApi.GetFlaggedTransactions(data);
      const status = res?.data?.status;
      const result = res.data.data ?? [];
      const totalRecords = res.data.dataCount;
      const message =
        res?.data?.message || "Server Error Please Reload the page";
      if (status) {
        onSuccess?.(result, totalRecords);
        dispatch({ type: types.FRAUD_MGMT_SUCCESS });
      } else {
        dispatch({ type: types.FRAUD_MGMT_FAIL });
        onError?.(message);
      }
    } catch (err) {
      console.log(err);
      const message =
        err?.response?.data?.message || "Server Error Please Reload the page";
      dispatch({ type: types.FRAUD_MGMT_FAIL });
      onError?.(message);
    }
  };
}


export function GetReviewFlaggedTransactions(data, onSuccess, onError) {
  return async (dispatch) => {
    try {
      dispatch({ type: types.FRAUD_MGMT_START });
      const res = await fraudMgmtApi.GetReviewFlaggedTransactions(data);
      const status = res?.data?.status;
      const result = res.data.data ?? [];
      const totalRecords = res.data.dataCount;
      const message =
        res?.data?.message || "Server Error Please Reload the page";
      if (status) {
        onSuccess?.(result, totalRecords);
        dispatch({ type: types.FRAUD_MGMT_SUCCESS });
      } else {
        dispatch({ type: types.FRAUD_MGMT_FAIL });
        onError?.(message, result, totalRecords);
      }
    } catch (err) {
      console.log(err);
      const message =
        err?.response?.data?.message || "Server Error Please Reload the page";
      dispatch({ type: types.FRAUD_MGMT_FAIL });
      onError?.(message);
    }
  };
}


export function TreatFlaggedTransactions(data, onSuccess, onError) {
  return async (dispatch) => {
    try {
      dispatch({ type: types.FRAUD_MGMT_START });
      const res = await fraudMgmtApi.TreatFlaggedTransactions(data);
      const status = res?.data?.status;
      const message =
        res?.data?.message || "Server Error Please Reload the page";
      if (status) {
        onSuccess?.(message);
        dispatch({ type: types.FRAUD_MGMT_SUCCESS });
      } else {
        dispatch({ type: types.FRAUD_MGMT_FAIL });
        onError?.(message);
      }
    } catch (err) {
      console.log(err);
      const message =
        err?.response?.data?.message || "Server Error Please Reload the page";
      dispatch({ type: types.FRAUD_MGMT_FAIL });
      onError?.(message);
    }
  };
}
