import React from "react";
import { StyledSuspenseLoader } from "./SuspenseLoader.styles";
import PropTypes from "prop-types";

const SuspenseLoader = ({ variant }) => {
  return (
    <StyledSuspenseLoader variant={variant}>
      <div className="spinner-wrap">
        <span className="spinner"></span>
      </div>
    </StyledSuspenseLoader>
  );
};

SuspenseLoader.propTypes = {
  variant: PropTypes.string,
  spinning: PropTypes.bool,
  speed: PropTypes.string,
};

SuspenseLoader.defaultProps = {
  variant: "white",
  spinning: true,
};

export default SuspenseLoader;
