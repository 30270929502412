import { useEffect, useState } from "react";
import CookieManager from "./CookieManager";

const useCookieManager = (key = null) => {
  const [cookieManager] = useState(new CookieManager());
  const [cookieValue, setCookieValue] = useState(null);

  useEffect(() => {
    if (key) {
      const value = cookieManager.getCookie(key);
      setCookieValue(value);
    }
  }, [cookieManager, key]);

  const getAllCookies = () => cookieManager.getAllCookies();
  const getDomainCookies = (domain) => cookieManager.getDomainCookies(domain);

  return key
    ? cookieValue
    : { ...cookieManager, getAllCookies, getDomainCookies };
};

export default useCookieManager;
