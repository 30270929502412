import * as types from "./userTypes";
import userRequest from "./userApi";
import * as requests from "../../../api/apiCalls";
import { getAllRolesWithUsers } from "../role/roleActions";
import _ from "lodash";

export const getAllUsers =
  (pageSize = 100, pageNumber = 1) =>
  async (dispatch) => {
    try {
      dispatch({ type: types.USER_START });
      const result = await requests.postRequest({
        url: `${userRequest.GET_ALL_USERS}?PageSize=${pageSize}&PageNumber=${pageNumber}`,
      });
      const status = result.status;
      if (status) {
        const users = result.data;
        dispatch({ type: types.GET_ALL_USERS, payload: users });
      } else {
        dispatch({ type: types.USER_FAIL });
      }
    } catch (error) {
      dispatch({ type: types.USER_FAIL });
    }
  };

export const createUser = (data, onSuccess, onError) => async (dispatch) => {
  try {
    dispatch({ type: types.USER_START });
    const result = await requests.postRequest({
      data,
      url: userRequest.CREATE_USER,
    });
    const status = result.status;
    const message = result.message || "Server Error Please Reload The Page";
    if (status) {
      dispatch(getAllRolesWithUsers());
      dispatch({ type: types.USER_SUCCESS });
      onSuccess(message);
    } else {
      dispatch({ type: types.USER_FAIL });
      onError(message);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Server Error Please Try Again";
    dispatch({ type: types.USER_FAIL });
    onError(message);
  }
};

export const updateUserDetails =
  (data, onSuccess, onError) => async (dispatch) => {
    try {
      dispatch({ type: types.USER_START });
      const result = await requests.putRequest({
        data,
        url: userRequest.UPDATE_USER,
      });
      const status = result.status;
      const message = result.message || "Server Error Please Reload The Page";
      if (status) {
        dispatch(getAllRolesWithUsers());
        dispatch({ type: types.USER_SUCCESS });
        onSuccess(message);
      } else {
        dispatch({ type: types.USER_FAIL });
        onError(message);
      }
    } catch (error) {
      const message =
        error?.response?.data?.message || "Server Error Please Try Again";
      dispatch({ type: types.USER_FAIL });
      onError(message);
    }
  };

export const deactivateUser =
  (data, onSuccess, onError) => async (dispatch) => {
    try {
      dispatch({ type: types.USER_START });
      const result = await requests.putRequest({
        data,
        url: userRequest.DEACTIVATE_USER,
      });
      const status = result.status;
      const message = result.message || "Server Error Please Try Again";
      if (status) {
        dispatch(getAllRolesWithUsers());
        dispatch({ type: types.USER_SUCCESS });
        onSuccess(message);
      } else {
        dispatch({ type: types.USER_FAIL });
        onError(message);
      }
    } catch (error) {
      const message =
        error?.response?.data?.message || "Server Error Please Try Again";
      dispatch({ type: types.USER_FAIL });
      onError(message);
    }
  };

export const activateUser = (data, onSuccess, onError) => async (dispatch) => {
  try {
    dispatch({ type: types.USER_START });
    const result = await requests.putRequest({
      data,
      url: userRequest.ACTIVATE_USER,
    });
    const status = result.status;
    const message = result.message;
    if (status) {
      dispatch(getAllRolesWithUsers());
      dispatch({ type: types.USER_SUCCESS });
      onSuccess(message);
    } else {
      dispatch({ type: types.USER_FAIL });
      onError(message);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Server Error Please Try Again";
    dispatch({ type: types.USER_FAIL });
    onError(message);
  }
};

export const reassignRole = (data, onSuccess, onError) => async (dispatch) => {
  try {
    dispatch({ type: types.USER_START });
    const result = await requests.putRequest({
      data,
      url: userRequest.REASSIGN_ROLE,
    });
    const status = result.status;
    const message = result.message || "Server Error Please Try Again";
    if (status) {
      dispatch(getAllRolesWithUsers());
      dispatch({ type: types.USER_SUCCESS });
      onSuccess(message);
    } else {
      dispatch({ type: types.USER_FAIL });
      onError(message);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Server Error Please Try Again";
    dispatch({ type: types.USER_FAIL });
    onError(message);
  }
};

export const getUserByMail = (email, onSuccess) => async (dispatch) => {
  try {
    dispatch({ type: types.USER_START });
    const result = await requests.getRequest({
      url: `${userRequest.GET_A_USER}?email=${email}`,
    });
    const status = result.status;
    if (status) {
      const user = result.data;
      dispatch({ type: types.GET_A_USER, payload: user });
      onSuccess();
    } else {
      dispatch({ type: types.USER_FAIL });
    }
  } catch (error) {
    dispatch({ type: types.USER_FAIL });
  }
};

export const getUserNotifications =
  (pageSize, pageNumber) => async (dispatch) => {
    try {
      dispatch({ type: types.USER_START });
      const result = await requests.postRequest({
        url: `${userRequest.GET_NOTIFICATIONS}`,
        data: {
          pageNumber,
          pageSize,
        },
      });
      const status = result.status;
      if (status) {
        const data = result.data;
        const sortData = data.sort(
          (a, b) => new Date(b.creationDate) - new Date(a.creationDate)
        );
        dispatch({ type: types.GET_NOTIFICATIONS, payload: sortData });
      } else {
        dispatch({ type: types.GET_NOTIFICATIONS_FAIL });
      }
    } catch (error) {
      dispatch({ type: types.GET_NOTIFICATIONS_FAIL });
    }
  };

export const getNotificationCount = () => async (dispatch) => {
  try {
    dispatch({ type: types.USER_START });
    const result = await requests.getRequest({
      url: `${userRequest.GET_NOTIFICATION_COUNT}`,
    });
    const status = result.status;
    if (status) {
      const data = result.data;
      dispatch({ type: types.GET_NOTIFICATION_COUNT, payload: data });
    } else {
      dispatch({ type: types.GET_NOTIFICATION_COUNT_FAIL });
    }
  } catch (error) {
    dispatch({ type: types.GET_NOTIFICATION_COUNT_FAIL });
  }
};

export const markNotificationAsRead = (id, data) => async (dispatch) => {
  try {
    dispatch({ type: types.USER_START });
    const result = await requests.postRequest({
      url: `${userRequest.MARK_AS_READ}?id=${id}`,
    });
    const status = result.status;
    if (status) {
      dispatch({ type: types.GET_NOTIFICATIONS, payload: data });
      dispatch(getNotificationCount());
    } else {
      dispatch({ type: types.USER_FAIL });
    }
  } catch (error) {
    dispatch({ type: types.USER_FAIL });
  }
};

export const markAllNotificationsAsRead = (onSuccess) => async (dispatch) => {
  try {
    dispatch({ type: types.USER_START });
    const result = await requests.postRequest({
      url: `${userRequest.MARK_ALL_AS_READ}`,
    });
    const status = result.status;
    if (status) {
      onSuccess();
      dispatch(getNotificationCount());
    } else {
      dispatch({ type: types.USER_FAIL });
    }
  } catch (error) {
    dispatch({ type: types.USER_FAIL });
  }
};

export const getPendingApproval = () => async (dispatch) => {
  try {
    dispatch({ type: types.USER_START });
    const result = await requests.postRequest({
      url: `${userRequest.GET_PENDING_APPROVAL}`,
    });
    const status = result.status;
    if (status) {
      const data = result.data;
      dispatch({ type: types.GET_PENDING_APPROVAL, payload: data });
    } else {
      dispatch({ type: types.GET_PENDING_APPROVAL_FAIL });
    }
  } catch (error) {
    dispatch({ type: types.GET_PENDING_APPROVAL_FAIL });
  }
};

export const getAssignedModules =
  (onSuccess = () => {}, onError = () => {}) =>
  async (dispatch) => {
    try {
      dispatch({ type: types.USER_START });
      const result = await requests.getRequest({
        url: `${userRequest.GET_ASSIGNED_MODULES}`,
      });
      const status = result.status;
      if (status) {
        const data = result.data;
        dispatch({ type: types.GET_USER_MODULES, payload: data });
        onSuccess();
      } else {
        dispatch({ type: types.GET_USER_MODULES, payload: [] });
        onError(result.message || "Server Error. Please reload the page.");
      }
    } catch (error) {
      dispatch({ type: types.GET_USER_MODULES, payload: [] });
      onError(
        error?.response?.data?.message ||
          "Server Error. Please reload the page."
      );
    }
  };

export const getPendingApprovalsByModule =
  (data, onSuccess, onError) => async (dispatch) => {
    try {
      dispatch({ type: types.USER_START });
      const result = await requests.postRequest({
        url: `${userRequest.GET_PENDING_APPROVALS_BY_MODULE}`,
        data,
      });
      const status = result.status;
      if (status) {
        const data = result.data;
        dispatch({ type: types.GET_PENDING_USER_MODULES, payload: data });
        dispatch({ type: types.SET_DATA_COUNT, payload: result?.dataCount });
        onSuccess?.(data);
      } else {
        dispatch({ type: types.GET_PENDING_USER_MODULES, payload: [] });
        onError?.(
          result?.data?.message ||
            result?.message ||
            "Server Error. Please reload the page."
        );
      }
    } catch (error) {
      dispatch({ type: types.GET_PENDING_USER_MODULES, payload: [] });
      onError?.(
        error?.response?.data?.message ||
          "Server Error. Please reload the page."
      );
    }
  };

export const getPendingCreditRequests =
  (data, onSuccess, onError) => async (dispatch) => {
    try {
      dispatch({ type: types.USER_START });
      const result = await requests.postRequest({
        url: `${userRequest.GET_PENDING_CREDIT_REQUESTS}`,
        data,
      });
      const status = result.status;
      if (status) {
        const data = result.data;
        dispatch({ type: types.GET_PENDING_USER_MODULES, payload: data });
        dispatch({ type: types.SET_DATA_COUNT, payload: result?.dataCount });
        onSuccess?.(data);
      } else {
        dispatch({ type: types.GET_PENDING_USER_MODULES, payload: [] });
        onError?.(
          result?.data?.message ||
            result?.message ||
            "Server Error. Please reload the page."
        );
      }
    } catch (error) {
      dispatch({ type: types.GET_PENDING_USER_MODULES, payload: [] });
      onError?.(
        error?.response?.data?.message ||
          "Server Error. Please reload the page."
      );
    }
  };

export const GetPendingAddressUpdateRequests =
  (data, onSuccess, onError) => async (dispatch) => {
    try {
      dispatch({ type: types.USER_START });
      const result = await requests.postRequest({
        url: `${userRequest.GET_PENDING_ADDRESS_UPDATE_REQUESTS}`,
        data,
      });
      const status = result.status;
      if (status) {
        const data = result.data;
        dispatch({ type: types.GET_PENDING_USER_MODULES, payload: data });
        dispatch({ type: types.SET_DATA_COUNT, payload: result?.dataCount });
        onSuccess?.(data);
      } else {
        dispatch({ type: types.GET_PENDING_USER_MODULES, payload: [] });
        onError?.(
          result?.data?.message ||
            result?.message ||
            "Server Error. Please reload the page."
        );
      }
    } catch (error) {
      dispatch({ type: types.GET_PENDING_USER_MODULES, payload: [] });
      onError?.(
        error?.response?.data?.message ||
          "Server Error. Please reload the page."
      );
    }
  };

export const GetPendingAddressUpdateRequestByAccountNumber =
  (data, onSuccess, onError) => async (dispatch) => {
    try {
      dispatch({ type: types.USER_START });
      const result = await requests.postRequest({
        url: `${userRequest.GET_PENDING_ADDRESS_UPDATE_REQUESTS_BY_ACCOUNT_NUMBER}?accountNumber=${data?.accountNumber}`,
      });
      const status = result.status;
      if (status) {
        const data = result?.data ? [result?.data] : [];
        dispatch({ type: types.GET_PENDING_USER_MODULES, payload: data });
        // dispatch({ type: types.SET_DATA_COUNT, payload: result?.dataCount });
        onSuccess?.(data);
      } else {
        dispatch({ type: types.GET_PENDING_USER_MODULES, payload: [] });
        onError?.(
          result?.data?.message ||
            result?.message ||
            "Server Error. Please reload the page."
        );
      }
    } catch (error) {
      dispatch({ type: types.GET_PENDING_USER_MODULES, payload: [] });
      onError?.(
        error?.response?.data?.message ||
          "Server Error. Please reload the page."
      );
    }
  };

export const GetPendingSuspiciousTransactionsRequests =
  (data, onSuccess, onError) => async (dispatch) => {
    try {
      dispatch({ type: types.USER_START });
      const result = await requests.postRequest({
        url: userRequest.GET_PENDING_SUSPICIOUS_TRANSACTIONS_REQUESTS,
        data,
      });
      const status = result.status;
      const pendingFraudRequests = result.data;
      const message = result.message;
      if (status) {
        dispatch({
          type: types.GET_PENDING_USER_MODULES,
          payload: pendingFraudRequests,
        });
        dispatch({ type: types.SET_DATA_COUNT, payload: result?.dataCount });
        onSuccess?.(pendingFraudRequests);
        dispatch({ type: types.USER_SUCCESS });
      } else {
        dispatch({
          type: types.GET_PENDING_USER_MODULES,
          payload: [],
        });
        onError?.(message);
        dispatch({ type: types.USER_FAIL });
      }
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        "Server Error. Please reload the page.";
      onError?.(message);
      dispatch({ type: types.USER_FAIL });
    }
  };

export const SearchRecordsPendingApproval = (data, onSuccess, onError) =>
  _.debounce(async (dispatch) => {
    try {
      dispatch({ type: types.USER_START });
      const result = await requests.postRequest({
        url: `${userRequest.RECORD_SEARCH}`,
        data,
      });
      const status = result.status;
      if (status) {
        const data = result.data;
        dispatch({ type: types.GET_PENDING_USER_MODULES, payload: data });
        onSuccess?.(data);
      } else {
        dispatch({ type: types.GET_PENDING_USER_MODULES, payload: [] });
        onError?.(
          result.data.message || "Server Error. Please reload the page."
        );
      }
    } catch (error) {
      dispatch({ type: types.GET_PENDING_USER_MODULES, payload: [] });
      onError?.(
        error?.response?.data?.message ||
          "Server Error. Please reload the page."
      );
    }
  }, 200);

export const updatedPendingApproval = (result) => async (dispatch) => {
  dispatch({ type: types.GET_PENDING_APPROVAL, payload: result });
};
