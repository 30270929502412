import React from "react";
import PropTypes from "prop-types";
import { IconBox } from "./Icon.styles";

const Icon = ({ svg, size, viewBox, height, width, onClick, style }) => (
    <IconBox size={size} viewBox={viewBox} height={height} width={width} onClick={onClick}>
        <span style={style} dangerouslySetInnerHTML={{ __html: svg }}></span>
    </IconBox>
);

Icon.propTypes = {
    icon: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    viewBox: PropTypes.string,
    label: PropTypes.string,
    size: PropTypes.string,
    style: PropTypes.object,
};

Icon.defaultProps = {
    size: "16",
    width: "2em",
    height: "2em",
    className: "",
    style: {},
    viewBox: "0 0 24 24",
};

export default Icon;
