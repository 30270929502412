import { ModifiedMain } from "layout/Main";

import AddFXRateForm from "./AddFXRateForm";
import { addNewFxRateHeaderLink } from "modules/RemittanceManagement/HeaderLinks";
import { StyledRemmitanceMgmtWrapper } from "modules/RemittanceManagement/shared/styles/home.styles";

const AddFXRate = () => {
  return (
    <ModifiedMain
      childSubRoute
      links={addNewFxRateHeaderLink}
      path={"/remittance-management/add-fx-rate"}
      isSticky={false}
    >
      <StyledRemmitanceMgmtWrapper noGap>
        <div className="top-header">
          <h1>Add Fx Rate</h1>
        </div>
        <div className="divider" />
        <AddFXRateForm />
      </StyledRemmitanceMgmtWrapper>
    </ModifiedMain>
  );
};

export default AddFXRate;
