import styled, { css } from "styled-components";

export const StyledSearchField = styled.div`
  display: flex;
  align-items: center;

  .icon {
    margin-right: 10px;
  }

  &:focus-within {
    border: ${(props) => (props.error ? "1px solid  #f7685b" : "1px solid rgba(64, 25, 109, 0.6)")};
  }

  input {
    flex-grow: 1;
    border: none;
    outline: none;
    height: 100%;
  }

  ${(props) =>
    props.lg &&
    css`
      width: ${(props) => (props.width ? props.width : "675px")};
      height: ${(props) => (props.height ? props.height : "66px")};
      max-width: 100%;
      border-radius: 8px;
      padding: 0 15px;
      input {
        font-size: 16px;
      }
      .icon {
        margin-right: 10px;
      }
    `};

  ${(props) =>
    props.sm &&
    css`
      width: ${(props) => (props.width ? props.width : "200px")};
      height: ${(props) => (props.height ? props.height : "36px")};
      border-radius: 4px;
      padding: 0 10px;
      input {
        font-size: 13px;
      }
    `};

  border: ${(props) => (props.error ? "1px solid  #f7685b" : "1px solid #dbdce0")};

  .dropdown-section {
    display: flex;
  }

  .vertical-line {
    border-left: 1px solid #979797;
    margin: 0 20px;
  }
`;

export const StyledSearchFieldContainer = styled.div``;

export const StyledSearchFieldError = styled.div`
  :empty {
    display: none;
  }
  margin-top: 10px;
  color: #f7685b;
`;

export const DropDownContainer = styled("div")`
  min-width: 190.29px;
  margin: 0 auto;
`;

export const DropDownHeader = styled("div")`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #000000;

  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const DropDownListContainer = styled("div")`
  position: absolute;
  z-index: 100;
  min-width: 230.29px;
  background: white;
  margin-top: 40px;
  left: 0;
`;

export const DropDownList = styled("ul")`
  padding: 5px;
  background: #ffffff;
  border: 1px solid rgba(219, 220, 224, 0.5);
  box-shadow: 0px 0px 25px rgba(64, 25, 109, 0.07);
  cursor: pointer;
  border-radius: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #000000;
`;

export const ListItem = styled("li")`
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 12.5px 10px;
  :not(:last-child) {
    border-bottom: 1px solid #f4f4f4;
  }
  &:hover {
    background: #f9f9f9;
  }
`;
