export const USER_START = "USER_START";
export const USER_SUCCESS = "USER_SUCCESS";
export const USER_FAIL = "USER_FAIL";

export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_A_USER = "GET_A_USER";
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_NOTIFICATIONS_FAIL = "GET_NOTIFICATIONS_FAIL";
export const GET_PENDING_APPROVAL = "GET_PENDING_APPROVAL";
export const GET_PENDING_APPROVAL_FAIL = "GET_PENDING_APPROVAL_FAIL";
export const GET_NOTIFICATION_COUNT = "GET_NOTIFICATION_COUNT";
export const GET_NOTIFICATION_COUNT_FAIL = "GET_NOTIFICATION_COUNT_FAIL";
export const GET_USER_MODULES = "GET_USER_MODULES";
export const GET_PENDING_USER_MODULES = "GET_PENDING_USER_MODULES";
export const SET_DATA_COUNT = "SET_DATA_COUNT";
