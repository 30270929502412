import styled, { css } from "styled-components";
import { fadeIn } from "./../../styles/Animations.styles";
import breakpoint from "../breakpoint";

export const StyledToast = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 10px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  max-width: 700px;
  min-width: 480px;
  padding: 15px 25px;
  box-sizing: border-box;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  transition: transform 0.6s ease-in-out;
  animation: ${fadeIn} 0.2s;
  &:hover {
    transform: translate3d(0px, -3px, 0px);
    opacity: 0.9;
  }
  z-index: 10000;

  ${(props) =>
    props.success &&
    css`
      background: ${(props) => props.theme.colors.kudaDarkPurple};
      border: 0.4px solid ${(props) => props.theme.colors.kudaPurple};
      color: ${(props) => props.theme.colors.kudaPurple};
    `};

  ${(props) =>
    props.error &&
    css`
      background: ${(props) => props.theme.colors.kudaDarkRed};
      border: 1px solid ${(props) => props.theme.colors.kudaRed};
      color: ${(props) => props.theme.colors.kudaRed};

      @media ${breakpoint.device.tablet} {
        width: 166px;
      }
    `};

  ${(props) =>
    props.information &&
    css`
      background: ${(props) => props.theme.colors.kudaLightBlue};
      border: 1px solid ${(props) => props.theme.colors.kudaLightBlue};
      color: ${(props) => props.theme.colors.kudaBlue};
    `};

  ${(props) =>
    props.message &&
    css`
      font-weight: 900;
      font-size: 15px;
      line-height: 20px;
    `};
`;
