import styled from "styled-components";

export const StyledTableFilter = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    margin-bottom: 5px;
    background: #ffffff;
    flex-wrap: wrap;

    .filters {
        display: flex;
        align-items: center;
        justify-content: center;

        p {
            font-size: 13px;
            font-weight: 600;
            margin-right: 10px;
            white-space: nowrap;
        }

        select {
            font-size: 12px;
            line-height: 13px;
            border: 1px solid #dbdce0;
            border-radius: 4px;
            height: 36px;
            min-height: 36px;
            width: fit-content;
        }
    }

    .extra-components {
        display: flex;
        column-gap: 10px;
        align-items: center;
        justify-content: space-between;
    }

    .extra-components button {
        height: 36px !important;
        min-height: 36px !important;
        font-size: 13px;
        justify-content: space-between;
        padding-right: 12px;

    }

		.extra-components.neutral-p-l button {
			padding-left: 40px;
		}

    .input-field {
        padding: 8px 14px;
        background-color: #ffffff;
        border: 1px solid #dfe3e9;
        box-sizing: border-box;
        border-radius: 5px;
        min-height: 46px;
        outline: none;
        font-size: 14px;
        font-weight: 600;
        text-transform: capitalize;

        appearance: none;
        background: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='5' viewBox='0 0 8 5' fill='none'%3e%3crect y='0.707031' width='1' height='5' rx='0.5' transform='rotate(-45 0 0.707031)' fill='%23BEBEBE'/%3e%3crect x='6.36328' width='1' height='5' rx='0.5' transform='rotate(45 6.36328 0)' fill='%23BEBEBE'/%3e%3c/svg%3e") no-repeat;

        background-size: 12px;
        background-position: 90% 50%;
        padding-right: 55px;
        width: fit-content;

        &.smaller-padding {
            padding: 11px 11px;
        }

        &.smaller-text {
            font-size: 11px;
        }

        &.smaller-height {
            margin-top: 0.5px;
            padding: 11px 11px;
            min-height: 36px;
            height: 36px;
            background-position: right 9px top 14px;
        }
    }

    .input-field--wrap {
        margin-bottom: 0;
    }
`;

export const TableStyle = styled.div`
    .container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: ${(props) => (props.height ? props.height : "auto")};
    }

    .w-100 {
        width: 100%;
    }

    table {
        background: #ffffff;
        border: 1px solid #dfe2e5;
        box-sizing: border-box;
        border-radius: 8px;
        width: 100%;
    }
    .thead-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        /* width: 0; */
        position: relative;
    }

    thead {
        font-weight: bold;
        font-size: 10px;
        line-height: 18px;
        text-transform: uppercase;
        color: #121212;
        background: #eff1ff;
    }

    tbody {
        tr {
            font-size: 13px;
            line-height: 20px;
            color: #000000;
        }
    }

    th {
        font-weight: bold;
        font-size: 11px;
        line-height: 13px;
        /* text-transform: uppercase; */
        color: #000000;
    }

    td {
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: #000000;
    }

    td,
    th {
        text-align: left;
        padding: 16px 30px;
    }

    .table-filter {
        display: flex;
        margin-bottom: 20px;
    }

    .flex-between {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .data-table.dropdown {
        min-height: 374px;
    }

    .margin-top {
        margin-top: 15px;
    }

    .pagination {
        display: flex;
        padding: 0px 5px;
        margin: 0px 10px;
        color: #40196d;
        font-size: 14px;

        .page-input {
            border: 0;
            display: inline-block;
            width: 27px;
            height: 26px;
            border: 1px solid #f4f4f4;
            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            text-align: center;
        }
    }

    .pagination-text {
        font-weight: bold;
        font-size: 14px;
        line-height: 13px;

        color: #000;
    }

    .arrow {
        display: flex;
        align-items: center;
        height: 25px;

        cursor: pointer;
    }

    .disabled {
        opacity: 0.5;
    }

    .right {
        border-right: 1px solid #cacaca;
        padding-right: 10px;
    }

    .left {
        border-left: 1px solid #cacaca;
        padding-left: 10px;
    }

    td:last-child {
        width: 1px;
        white-space: nowrap;
    }
`;

export const StyledTableCustomCheckBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    /* Hide the default checkbox */
    .custom-checkbox {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        outline: none;
        width: 16px;
        height: 16px;
        background-color: transparent; /* Transparent background by default */
        border: 1px solid #979797; /* Border style */
        border-radius: 2px;
        position: relative;
        cursor: pointer;
        outline: none;
    }

    /* Style the tick icon */
    .custom-checkbox::after {
        content: "";
        width: 5px; /* Adjusted size */
        height: 9px; /* Adjusted size */
        border: solid white;
        border-width: 0 1.6px 1.6px 0;
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(50deg);
        opacity: 0;
        border-radius: 1px;
    }

    /* Show the tick icon and update background when checkbox is checked */
    .custom-checkbox:checked::after {
        opacity: 1;
        background-color: #40196d; /* Background color of the tick when checked */
    }

    /* Change background of the container when checkbox is checked */
    .custom-checkbox:checked {
        background-color: #40196d; /* Background color of the container when checked */
        border: 1px solid #40196d; /* Border style */
    }
`;
