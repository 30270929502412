import {
  getCall,
  authPostCall,
  postRequest,
  putRequest,
  deleteRequest,
  authPutCall,
  authDeleteCall,
} from "../../../api/apiCalls";
import { data } from "../../../utils/addresses";

const moduleBaseUrl = "/api/v3/Module";
const hubConnectionUrl = `${process.env.REACT_APP_SUITE_API_URL}/hubs/approval`;
const moduleRequest = {
  GET_MODULE_GROUP: `${moduleBaseUrl}/GetAllModuleGroup`,
  HUB_CONNECTION: hubConnectionUrl,
};

export default moduleRequest;

// Module Group APIs
export const GetAllModuleGroup = () => {
  return getCall(`${moduleBaseUrl}/GetAllModuleGroup`);
};

export const CreateModuleGroup = (data) => {
  return authPostCall(`${moduleBaseUrl}/CreateModuleGroup`, data);
};

// export const DeleteModuleGroup = (id) => {
//   return deleteRequest({
//     url: `${moduleBaseUrl}/DeleteModuleGroup?id=${id}`,
//   });
// };

export const DeleteModuleGroup = (id) => {
  return authDeleteCall(`${moduleBaseUrl}/DeleteModuleGroup?id=${id}`);
};

export const EditModuleGroup = (data) => {
  return authPutCall(`${moduleBaseUrl}/EditModuleGroup`, data);
};

// Module APIs

// export const CreateModule = (data) => {
//   return postRequest({ url: `${moduleBaseUrl}/CreateModule`, data });
// };

export const CreateModule = (data) => {
  return authPostCall(`${moduleBaseUrl}/CreateModule`, data);
};

export const DeleteModule = (id) => {
  return authDeleteCall(`${moduleBaseUrl}/DeleteModule?id=${id}`);
};

export const EditModule = (data) => {
  return authPutCall(`${moduleBaseUrl}/EditModule`, data);
};
