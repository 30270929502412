import styled from "styled-components";

export const StyledTextarea = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width || "200px"};

  label {
    font-weight: normal;
    font-size: 13.5px;
    line-height: 18px;
    color: #000000;
    margin-bottom: 10px;
  }

  textarea {
    background: #ffffff;
    height: 88px;
    border: 1px solid #f4f4f4;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    resize: none;
    padding: 10px 10px;
    outline: none;
    transition: all 0.6s;
    &:hover,
    &:focus {
      border: 1px solid rgba(64, 25, 109, 0.3);
    }
  }

  textarea::placeholder {
    color: #cacaca;
  }
`;
