import styled from "styled-components";

export const StyledDoc = styled.div`
  .overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 99999;

    .overlay_header {
      background-color: #fff;
      padding: 10px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .overlay_header-text {
      font-weight: 600;
      line-height: 22px;
      color: #000;
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    .overlay_container {
      display: flex;
      justify-content: center;
      align-items: center; /* Center the content vertically */
      overflow: auto;
      height: calc(100% - 60px); /* Full height minus the header height */
      padding: 20px; /* Add padding around the container */
      box-sizing: border-box;
    }

    .overlay_content {
      width: 100%;
      height: 100%;
      max-width: 1000px; /* Use up to 800px of the viewport width */
      max-height: 88vh; /* Use up to 90% of the viewport height */
      transform: scale(${(props) => props.zoomLevel})
        rotate(${(props) => props.rotation}deg);
      transition: transform 0.3s ease;
      transform-origin: ${(props) => props.transformOrigin};
      overflow: auto;
      cursor: ${(props) => (props.isPanning ? "grabbing" : "grab")};
      display: flex;
      align-items: center;

      @media only screen and (max-width: 1300px) {
        max-width: 1020px; /* Use up to 88% of the viewport width */
        max-height: 88vh; /* Use up to 88% of the viewport height */
      }

      ::-webkit-scrollbar {
        display: none;
      }
      /* Hide scrollbar for IE, Edge and Firefox */
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      & > * {
        margin-top: ${(props) =>
          props.zoomLevel < 0.5 ? 0 : props.zoomLevel * 0}px;
      }

      img {
        width: 100%;
        max-height: 100%; /* Ensures the image fits within the container height */
        object-fit: contain; /* Maintains aspect ratio and ensures full visibility */
      }

      iframe,
      embed {
        width: 100%;
        height: 100%;
        object-fit: contain; /* Maintains aspect ratio and ensures full visibility */
      }
    }

    .controls {
      position: fixed;
      top: 10vh;
      right: 20px;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .controls button {
      padding: 8px 14px;
      background-color: #000;
      color: #fff;
      cursor: pointer;
      font-size: 14px;
      display: flex;
      align-items: center;
      gap: 6px;
      border-radius: 50px;
      border: none;

      .icon {
        width: 14px;
        svg {
          fill: #fff;
        }
      }
    }

    .close-btn {
      font-size: 25px;
      cursor: pointer;
    }
  }
`;

export const StyledSuccessModal = styled.div`
  width: 415px;
  max-width: 100%;
  height: auto;
  background: #ffffff;
  border: 1px solid rgba(219, 220, 224, 0.5);
  box-shadow: 15px 30px 40px rgba(64, 25, 109, 0.07);
  border-radius: 6px;
  padding: 46px 40px;
  display: flex;
  flex-direction: column;
  animation: fadeInUp 0.5s forwards;

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(30px);
    }

    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  .card-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    h2 {
      font-style: normal;
      font-weight: 800;
      font-size: 20px;
      line-height: 25px;
      text-align: center;
      color: #000000;
    }

    .icon {
      margin: 44px 0px;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #000000;
      margin-bottom: 30px;

      span {
        font-weight: 800;
      }
    }

    .error-message {
      margin-bottom: 20px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #000000;
    }
  }

  .ticket-title {
    margin-top: 10px;
    font-style: normal;
    font-weight: 800;
    font-size: 13px;
    line-height: 18px;
    color: #000000;
  }

  .ticket-box {
    background: #eff1ff;
    display: flex;
    width: 236.3px;
    margin-inline: auto;
    justify-content: space-between;
    padding: 16px 13px;
    margin: 10px 0 30px;
    border-radius: 5px;

    .ticket-id {
      font-style: normal;
      font-weight: 900;
      font-size: 13px;
      line-height: 25px;
      color: #40196d;
    }

    .icon-box {
      display: flex;
      column-gap: 5px;
      align-items: center;
      cursor: pointer;
      min-height: 29px;

      .text {
        font-style: normal;
        font-weight: 700;
        font-size: 11px;
        line-height: 13px;
        color: #40196d;
      }
    }
  }

  footer {
    display: flex;
    height: 48px;
    gap: 20px;
    justify-content: center;

    button {
      width: 145px;
    }
  }
`;
