export const ACCOUNT_START = "ACCOUNT_START";
export const ACCOUNT_FAIL = "ACCOUNT_FAIL";
export const ACCOUNT_SUCCESS = "ACCOUNT_SUCCESS";
export const ACCOUNT_ENQUIRY = "ACCOUNT_ENQUIRY";
export const GET_DUE_DILIGENCE_QUESTION = "GET_DUE_DILIGENCE_QUESTION";
export const GET_DUE_DILIGENCE_ANSWER = "GET_DUE_DILIGENCE_ANSWER";
export const ACCOUNT_MEMO = "ACCOUNT_MEMO";
export const ACCOUNT_LIEN_MEMO = "ACCOUNT_LIEN_MEMO";

// Account Activity Types
export const SET_CARD_ACTIVITY = "SET_CARD_ACTIVITY";
export const SET_LOGIN_ACTIVITY = "SET_LOGIN_ACTIVITY";
export const SET_SAVINGS_ACTIVITY = "SET_SAVINGS_ACTIVITY";
export const SET_PAYMENT_ACTIVITY = "SET_PAYMENT_ACTIVITY";
export const SET_OVERDRAFT_ACTIVITY = "SET_OVERDRAFT_ACTIVITY";
export const SET_ONBOARDING_ACTIVITY = "SET_ONBOARDING_ACTIVITY";
export const SET_TRADING_STOCK_ACTIVITY = "SET_TRADING_STOCK_ACTIVITY";
