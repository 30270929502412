import roleRequest from "./roleApi";
import * as requests from "../../../api/apiCalls";
import * as types from "./roleTypes";

export const getAllPermission = () => async (dispatch) => {
  try {
    dispatch({ type: types.ROLE_START });
    const result = await requests.getRequest({
      url: roleRequest.GET_ALL_PERMISSION,
    });
    const status = result.status;
    if (status) {
      const roles = result.data;
      dispatch({ type: types.GET_ALL_ROLES, payload: roles });
    }
  } catch (error) {
    dispatch({ type: types.ROLE_FAIL });
  }
};

export const getAllRoleWithPermissions = () => async (dispatch) => {
  try {
    dispatch({ type: types.ROLE_START });
    const result = await requests.getRequest({
      url: roleRequest.GET_ROLES_WITH_PERMISSION,
    });
    const status = result.status;
    if (status) {
      const roles = result.data;
      dispatch({ type: types.GET_ROLES_WITH_PERMISSION, payload: roles });
    } else {
      dispatch({ type: types.ROLE_FAIL });
    }
  } catch (error) {
    dispatch({ type: types.ROLE_FAIL });
  }
};

export const createUserRole = (data, onSuccess, onError) => async (
  dispatch
) => {
  try {
    dispatch({ type: types.ROLE_START });
    const result = await requests.postRequest({
      url: roleRequest.CREATE_USER_ROLE,
      data,
    });
    const status = result.status;
    const message = result.message;
    if (status) {
      dispatch(getAllRoleWithPermissions());
      onSuccess(message);
    } else {
      dispatch({ type: types.ROLE_FAIL });
      onError(message);
    }
  } catch (error) {
    dispatch({ type: types.ROLE_FAIL });
    onError("Failed to get response from the server");
  }
};

export const editUserRole = (data, onSuccess, onError) => async (dispatch) => {
  try {
    dispatch({ type: types.ROLE_START });
    const result = await requests.postRequest({
      url: roleRequest.EDIT_USER_ROLE,
      data,
    });
    const status = result.status;
    const message = result.message;
    if (status) {
      dispatch(getAllRoleWithPermissions());
      onSuccess(message);
    } else {
      dispatch({ type: types.ROLE_FAIL });
      onError(message);
    }
  } catch (error) {
    dispatch({ type: types.ROLE_FAIL });
    onError("Failed to get response from the server");
  }
};

export const getAllRolesWithUsers = () => async (dispatch) => {
  try {
    dispatch({ type: types.ROLE_START });
    const result = await requests.getRequest({
      url: roleRequest.GET_ROLES_WITH_USERS,
    });
    const status = result.status;
    if (status) {
      const roles = result.data;
      dispatch({ type: types.GET_ROLES_WITH_USERS, payload: roles });
    } else {
      dispatch({ type: types.ROLE_FAIL });
    }
  } catch (error) {
    dispatch({ type: types.ROLE_FAIL });
  }
};

export const deleteUserRole = (data, onSuccess, onError) => async (
  dispatch
) => {
  try {
    dispatch({ type: types.ROLE_START });
    const result = await requests.deleteRequest({
      url: `${roleRequest.DELETE_USER_ROLE}?roleId=${data}`,
      data,
    });
    const status = result.status;
    const message = result.message;
    if (status) {
      dispatch(getAllRoleWithPermissions());
      onSuccess(message);
    } else {
      dispatch({ type: types.ROLE_FAIL });
      onError(message);
    }
  } catch (error) {
    dispatch({ type: types.ROLE_FAIL });
    onError("Failed to get response from the server");
  }
};
