import moment from "moment";

import TableActions from "library/Tables/TableActions";

import { currencySymbolMap } from "modules/RemittanceManagement/shared/utils";
import { ArrowIcon } from "modules/RemittanceManagement/shared/icons";
import { formatBalance } from "utils/utilityFunctions";

export const fxRateColumns = [
    {
        Header: "DATE",
        accessor: "lastModifiedDate",
        Cell: (value) => (
            <TableActions>
                <div>
                    <p>
                        {moment(value.row.original?.lastModifiedDate).format("Do MMMM, YYYY")}
                    </p>
                    <p className="mt-1 color-alt--black  ">
                        {moment(value.row.original?.lastModifiedDate).format("h:mm A")}
                    </p>
                </div>
            </TableActions>
        ),
    },
    {
        Header: <p> Previous <br />MARGIN FEE (%)</p>,
        accessor: "previousMarginFee",
        Cell: (value) => (
            <TableActions>
                <div>
                    <p>{formatBalance(value.row.original?.previousMarginFee) ?? "N/A"}</p>
                </div>
            </TableActions>
        ),
    },
    {
        Header: <p> Current <br />MARGIN FEE (%)</p>,
        accessor: "currentMarginFee",
        Cell: (value) => (
            <TableActions>
                <div>
                    <p>{formatBalance(value.row.original?.currentMarginFee) ?? "N/A"}</p>
                </div>
            </TableActions>
        ),
    },
    {
        Header: <p> PrevIous KUDA <br />FX Rate DETAILS</p>,
        accessor: "previousRate",
        Cell: (value) => (
            <TableActions>
                <div>
                    <div className="gap-4 d-flex">
                        <p>
                            {`${value?.row?.original?.sourceCurrency} (${currencySymbolMap[value?.row?.original?.sourceCurrency] || ""})`}
                        </p>
                        <ArrowIcon />
                        <p>
                            {`${value?.row?.original?.destinationCurrency} (${currencySymbolMap[value?.row?.original?.destinationCurrency] || ""})`}
                        </p>
                    </div>
                    <p className="text-xbold mt-1">{formatBalance(value.row.original?.previousRate) ?? "N/A"}</p>
                </div>
            </TableActions>
        ),
    },
    {
        Header: <p> PrevIous KUDA <br />FX Rate DETAILS</p>,
        accessor: "currentRate",
        Cell: (value) => (
            <TableActions>
                <div>
                    <div className="gap-4 d-flex">
                        <p>
                            {`${value?.row?.original?.sourceCurrency} (${currencySymbolMap[value?.row?.original?.sourceCurrency] || ""})`}
                        </p>
                        <ArrowIcon />
                        <p>
                            {`${value?.row?.original?.destinationCurrency} (${currencySymbolMap[value?.row?.original?.destinationCurrency] || ""})`}
                        </p>
                    </div>
                    <p className="text-xbold mt-1">{formatBalance(value.row.original?.currentRate) ?? "N/A"}</p>
                </div>
            </TableActions>
        ),
    },
    {
        Header: "CHANGED BY",
        accessor: "changedBy",
        Cell: (value) => (
            <TableActions>
                <div>
                    <p>{value.row.original?.modifiedBy ?? "N/A"}</p>
                </div>
            </TableActions>
        ),
    },
];