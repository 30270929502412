// FX RATES
export const FETCH_FXRATE_STARTED = "FETCH_FXRATE_STARTED";
export const FETCH_FXRATE_SUCCESS = "FETCH_FXRATE_SUCCESS";
export const FETCH_FXRATE_FAILED = "FETCH_FXRATE_FAILED";

//FX RATE HISTORY
export const FETCH_FXRATEHISTORY_STARTED = "FETCH_FXRATEHISTORY_STARTED";
export const FETCH_FXRATEHISTORY_SUCCESS = "FETCH_FXRATEHISTORY_SUCCESS";
export const FETCH_FXRATEHISTORY_FAILED = "FETCH_FXRATEHISTORY_FAILED";

//CREATE FX RATE
export const CREATE_FX_RATE_STARTED = "CREATE_FX_RATE_STARTED";
export const CREATE_FX_RATE_SUCCESS = "CREATE_FX_RATE_SUCCESS";
export const CREATE_FX_RATE_FAILED = "CREATE_FX_RATE_FAILED";

//SET FX RATE
export const SET_FX_RATE_INVIEW = "SET_FX_RATE_INVIEW";

//CREATE FX RATE
export const UPDATE_FX_RATE_STARTED = "UPDATE_FX_RATE_STARTED";
export const UPDATE_FX_RATE_SUCCESS = "UPDATE_FX_RATE_SUCCESS";
export const UPDATE_FX_RATE_FAILED = "UPDATE_FX_RATE_FAILED";
