import axios from "axios";
import { deleteToken } from "../utils/tokenConfig";
import { sha256 } from './security'
import { trackUserAction } from './datadog'
import { removeEmpty } from "utils/utilityFunctions";

const length = 512

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SUITE_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// DELETE EXPIRED TOKEN
axiosInstance.interceptors.response.use(
  function (response) {
    if (response.status === 401) {
      return deleteToken();
    } else {
      let editedConfigData = null;

      const { data, config, status, request } = response;

      if (config?.data instanceof FormData) {
        const objectData = {};

        for (const pair of config?.data?.entries()) {
          objectData[pair[0]] = pair[1];
        }

        config.data = JSON.stringify(objectData);
      }

      const parsedData = config?.data !== undefined && JSON.parse(config?.data);

      if (
        parsedData["password"] ||
        parsedData["pin"] ||
        parsedData.hasOwnProperty("authorization") ||
        parsedData["pan"] ||
        parsedData["cvv2"] ||
        parsedData["expiryDate"]
      ) {
        if (parsedData.hasOwnProperty("authorization")) {
          editedConfigData = {
            ...parsedData,
            authorization: {
              ...parsedData.authorization,
              pin:
                parsedData.authorization.pin &&
                sha256(parsedData.authorization.pin),
            },
            password: parsedData["password"] && sha256(parsedData["password"]),
            pin: parsedData["pin"] && sha256(parsedData["pin"]),
            pan: parsedData["pan"] && sha256(parsedData["pan"]),
            cvv2: parsedData["cvv2"] && sha256(parsedData["cvv2"]),
            expiryDate:
              parsedData["expiryDate"] && sha256(parsedData["expiryDate"]),
          };
        } else {
          editedConfigData = {
            ...parsedData,
            password: parsedData["password"] && sha256(parsedData["password"]),
            pin: parsedData["pin"] && sha256(parsedData["pin"]),
            pan: parsedData["pan"] && sha256(parsedData["pan"]),
            cvv2: parsedData["cvv2"] && sha256(parsedData["cvv2"]),
            expiryDate:
              parsedData["expiryDate"] && sha256(parsedData["expiryDate"]),
          };
        }
      }

      const event = {
        "Request URL": request.responseURL,
        "Request Method": config.method,
        "Request Body": editedConfigData
          ? JSON.stringify(removeEmpty(editedConfigData)).substring(
            0,
            length
          ) || JSON.stringify(config?.data).substring(0, length)
          : null,
        "Response Code": status,
        "Response Message": data.message,
        "Response Body": data?.data
          ? JSON.stringify(data?.data).substring(0, length)
          : null,
      };

      /* A string that is used to track the user action. */
      const eventName = `Network Event: Success Response: ${config.method.toUpperCase()} ${request.responseURL
        }, Response: ${status} ${data.message}: ${data?.data ? JSON.stringify(data?.data).substring(0, length) : null
        }`;

      /* A function that is called on every response. It is used to track the user action. */
      trackUserAction(eventName, event, "info");

      return response;
    }
  },
  function (error) {
    if (error.response.status === 401) {
      deleteToken();
      window.location = "/login";
    } else {
      return Promise.reject(error);
    }
  }
);

export default axiosInstance;
