import { useEffect, useRef, useState } from "react";
import { GreaterThan, LessThan } from "../../components/SvgPack";

const TableFooter = ({
  data,
  pageIndex,
  pageSize,
  previousPage,
  canPreviousPage,
  nextPage,
  canNextPage,
  totalRecords,
  gotoPage,
  setPageIndexNumber,
}) => {
  const isServerPaginated = totalRecords && totalRecords > data.length;
  const noOfRecords = isServerPaginated ? totalRecords : data.length;
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = pageIndex + 1;
    }
  }, [pageIndex]);

  return (
    <div className="flex-between margin-top">
      <p className="pagination-text">
        Showing{" "}
        {noOfRecords === 0 ? (
          <span>1 - {pageSize}</span>
        ) : (
          <span>
            {pageIndex * pageSize + 1} -{" "}
            {pageSize * (pageIndex + 1) < noOfRecords
              ? pageSize * (pageIndex + 1)
              : noOfRecords}
          </span>
        )}{" "}
        of {noOfRecords} results
      </p>
      {!canPreviousPage && !canNextPage ? (
        <></>
      ) : (
        <div className="flex-between">
          <div
            className="arrow right"
            onClick={() => {
              previousPage();
              setPageIndexNumber(pageIndex - 1);
            }}
            disabled={!canPreviousPage}
          >
            <span className={`${!canPreviousPage && "disabled"}`}>
              <LessThan />
            </span>
          </div>
          <p className="pagination">
            {/* <PageInput></PageInput> &nbsp; out of 10 */}
            {noOfRecords === 0 ? (
              <span>{pageSize}</span>
            ) : (
              // <span>{pageIndex + 1}</span>
              <input
                className="page-input"
                type="text"
                ref={inputRef}
                defaultValue={pageIndex + 1}
                placeholder={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                  setPageIndexNumber(page);
                }}
              />
            )}{" "}
            &nbsp; out of{" "}
            {Number.isInteger(noOfRecords / pageSize)
              ? noOfRecords / pageSize
              : parseInt(noOfRecords / pageSize) + 1}
          </p>
          <div
            className="arrow left"
            onClick={() => {
              nextPage();
              setPageIndexNumber(pageIndex + 1);
            }}
            disabled={!canNextPage}
          >
            <span className={`${!canNextPage && "disabled"}`}>
              <GreaterThan />
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default TableFooter;
