import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/modern-normalize.css";
import "./assets/css/misc.css";
import "./assets/css/style.css";
import "./assets/css/media.css";
import 'react-perfect-scrollbar/dist/css/styles.css';
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App.js";
import { createBrowserHistory } from "history";
import Theme from "./theme";
import GlobalStyle from "./theme/globalStyles";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { AuthProvider } from "./context/AuthContext";
// import { intializeDatadog } from "api/datadog";
import Bootstrap from "./bootstrap";

let history = createBrowserHistory();

// intializeDatadog();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Bootstrap>
        <AuthProvider>
          <Router history={history}>
            <Theme>
              <GlobalStyle />
              <App />
            </Theme>
          </Router>
        </AuthProvider>
      </Bootstrap>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
