import * as types from "./accountTypes";

const initialState = {
  isLoading: false,
  dueDiligenceQuestion: [],
  dueDiligenceAnswer: {},
  accountMemo: [],
  lienMemo: [],
  cardActivity: [],
  loginActivity: [],
  savingsActivity: [],
  paymentActivity: [],
  overdraftActivity: [],
  onboardingActivity: [],
  traddingStockActivity: [],
};

const accountReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.ACCOUNT_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.ACCOUNT_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case types.GET_DUE_DILIGENCE_QUESTION:
      return {
        ...state,
        isLoading: false,
        dueDiligenceQuestion: payload,
      };
    case types.SET_CARD_ACTIVITY:
      return {
        ...state,
        isLoading: false,
        cardActivity: payload,
      };
    case types.SET_LOGIN_ACTIVITY:
      return {
        ...state,
        isLoading: false,
        loginActivity: payload,
      };
    case types.SET_SAVINGS_ACTIVITY:
      return {
        ...state,
        isLoading: false,
        savingsActivity: payload,
      };
    case types.SET_PAYMENT_ACTIVITY:
      return {
        ...state,
        isLoading: false,
        paymentActivity: payload,
      };
    case types.SET_OVERDRAFT_ACTIVITY:
      return {
        ...state,
        isLoading: false,
        overdraftActivity: payload,
      };
    case types.SET_ONBOARDING_ACTIVITY:
      return {
        ...state,
        isLoading: false,
        onboardingActivity: payload,
      };
    case types.SET_TRADING_STOCK_ACTIVITY:
      return {
        ...state,
        isLoading: false,
        traddingStockActivity: payload,
      };
    case types.GET_DUE_DILIGENCE_ANSWER:
      return {
        ...state,
        isLoading: false,
        dueDiligenceAnswer: payload,
      };
    case types.ACCOUNT_MEMO:
      return {
        ...state,
        isLoading: false,
        accountMemo: payload,
      };
    case types.ACCOUNT_LIEN_MEMO:
      return {
        ...state,
        isLoading: false,
        lienMemo: payload,
      };
    case types.ACCOUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default accountReducer;
