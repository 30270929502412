import * as types from "./roleTypes";

const initialState = {
  isLoading: false,
  allPermission: [],
  rolesWithPermission: [],
  rolesWithUsers: [],
};

const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.ROLE_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_ALL_ROLES:
      return {
        ...state,
        allPermission: payload,
        isLoading: false,
      };
    case types.GET_ROLES_WITH_PERMISSION:
      return {
        ...state,
        isLoading: false,
        rolesWithPermission: payload,
      };
    case types.GET_ROLES_WITH_USERS:
      return {
        ...state,
        isLoading: false,
        rolesWithUsers: payload,
      };
    case types.ROLE_FAIL:
      return {
        ...state,
        allPermission: [],
        isLoading: false,
      };
    default:
      return state;
  }
};

export default userReducer;
