const roleApi = "/api/v3/Role";

const roleRequest = {
  GET_ALL_ROLES: `${roleApi}/GetRoles`,
  GET_ALL_PERMISSION: `${roleApi}/GetAllPermission`,
  GET_ROLES_WITH_PERMISSION: `${roleApi}/GetRolesWithPermission`,
  CREATE_USER_ROLE: `${roleApi}/CreateUserRole`,
  EDIT_USER_ROLE: `${roleApi}/EditUserRole`,
  GET_ROLES_WITH_USERS: `${roleApi}/GetRolesWithUser`,
  DELETE_USER_ROLE: `${roleApi}/DeleteUserRole`,
};

export default roleRequest;
