export const SuccessModalIcon = () => (
  <svg
    width="217"
    height="64"
    viewBox="0 0 217 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.55173 52.7753C1.33915 52.7753 0.34375 51.7828 0.34375 50.5737C0.34375 49.3646 1.33915 48.3721 2.55173 48.3721C3.76431 48.3721 4.75971 49.3646 4.75971 50.5737C4.75971 51.7828 3.76431 52.7753 2.55173 52.7753ZM2.55173 48.8413C1.59253 48.8413 0.796205 49.6173 0.796205 50.5917C0.796205 51.5662 1.57443 52.3422 2.55173 52.3422C3.52903 52.3422 4.30726 51.5662 4.30726 50.5917C4.30726 49.6173 3.51093 48.8413 2.55173 48.8413Z"
      fill="#40196D"
    />
    <path
      d="M53.8994 47.5663C52.6868 47.5663 51.6914 46.5738 51.6914 45.3647C51.6914 44.1556 52.6868 43.1631 53.8994 43.1631C55.112 43.1631 56.1074 44.1556 56.1074 45.3647C56.0893 46.5738 55.112 47.5663 53.8994 47.5663ZM53.8994 43.6142C52.9402 43.6142 52.1439 44.3902 52.1439 45.3647C52.1439 46.3392 52.9221 47.1152 53.8994 47.1152C54.8767 47.1152 55.6549 46.3392 55.6549 45.3647C55.6549 44.3902 54.8586 43.6142 53.8994 43.6142Z"
      fill="#40196D"
    />
    <path
      d="M25.5412 13.1726C22.736 13.1726 20.4375 10.8807 20.4375 8.06556C20.4375 5.25037 22.736 2.97656 25.5412 2.97656C28.3645 2.97656 30.6449 5.26842 30.6449 8.06556C30.663 10.8807 28.3645 13.1726 25.5412 13.1726ZM25.5412 3.59013C23.0798 3.59013 21.0528 5.61129 21.0528 8.06556C21.0528 10.5198 23.0617 12.541 25.5412 12.541C28.0206 12.541 30.0295 10.5379 30.0295 8.06556C30.0295 5.59325 28.0206 3.59013 25.5412 3.59013Z"
      fill="#40196D"
    />
    <path
      d="M36.9546 34.6676H31.1631C31.0003 34.6676 30.8555 34.5232 30.8555 34.3608V28.5861C30.8555 28.4237 31.0003 28.2793 31.1631 28.2793H36.9546C37.1174 28.2793 37.2622 28.4237 37.2622 28.5861V34.3608C37.2803 34.5413 37.1355 34.6676 36.9546 34.6676ZM31.4889 34.054H36.6469V28.9109H31.4889V34.054Z"
      fill="#40196D"
    />
    <path
      d="M69.1662 18.5153H66.8858C66.7229 18.5153 66.5781 18.3709 66.5781 18.2085V15.9347C66.5781 15.7723 66.7229 15.6279 66.8858 15.6279H69.1662C69.3291 15.6279 69.4738 15.7723 69.4738 15.9347V18.2085C69.4919 18.3709 69.3472 18.5153 69.1662 18.5153ZM67.2116 17.9017H68.8585V16.2595H67.2116V17.9017Z"
      fill="#40196D"
    />
    <path
      d="M170.739 47.5663C169.527 47.5663 168.531 46.5738 168.531 45.3647C168.531 44.1556 169.527 43.1631 170.739 43.1631C171.952 43.1631 172.947 44.1556 172.947 45.3647C172.929 46.5918 171.952 47.5663 170.739 47.5663ZM170.739 43.6323C169.78 43.6323 168.984 44.4083 168.984 45.3827C168.984 46.3572 169.762 47.1332 170.739 47.1332C171.717 47.1332 172.495 46.3572 172.495 45.3827C172.495 44.4083 171.698 43.6323 170.739 43.6323Z"
      fill="#40196D"
    />
    <path
      d="M159.993 20.7714C158.781 20.7714 157.785 19.7789 157.785 18.5698C157.785 17.3607 158.781 16.3682 159.993 16.3682C161.206 16.3682 162.201 17.3607 162.201 18.5698C162.201 19.7969 161.206 20.7714 159.993 20.7714ZM159.993 16.8374C159.034 16.8374 158.238 17.6133 158.238 18.5878C158.238 19.5623 159.016 20.3383 159.993 20.3383C160.97 20.3383 161.749 19.5623 161.749 18.5878C161.749 17.6133 160.952 16.8374 159.993 16.8374Z"
      fill="#40196D"
    />
    <path
      d="M211.588 60.7825C208.765 60.7825 206.484 58.4906 206.484 55.6935C206.484 52.8783 208.783 50.6045 211.588 50.6045C214.411 50.6045 216.692 52.8963 216.692 55.6935C216.692 58.5087 214.393 60.7825 211.588 60.7825ZM211.588 51.2181C209.109 51.2181 207.1 53.2212 207.1 55.6935C207.1 58.1658 209.109 60.1689 211.588 60.1689C214.068 60.1689 216.076 58.1658 216.076 55.6935C216.076 53.2212 214.049 51.2181 211.588 51.2181Z"
      fill="#40196D"
    />
    <path
      d="M188.767 37.6442H182.976C182.813 37.6442 182.668 37.4998 182.668 37.3374V31.5626C182.668 31.4002 182.813 31.2559 182.976 31.2559H188.767C188.93 31.2559 189.075 31.4002 189.075 31.5626V37.3374C189.075 37.4998 188.948 37.6442 188.767 37.6442ZM183.283 37.0126H188.441V31.8694H183.283V37.0126Z"
      fill="#40196D"
    />
    <path
      d="M209.072 16.2829H206.792C206.629 16.2829 206.484 16.1385 206.484 15.9761V13.7023C206.484 13.5399 206.629 13.3955 206.792 13.3955H209.072C209.235 13.3955 209.38 13.5399 209.38 13.7023V15.9761C209.38 16.1385 209.253 16.2829 209.072 16.2829ZM207.118 15.6513H208.765V14.0091H207.118V15.6513Z"
      fill="#40196D"
    />
    <path
      d="M135.615 63.9998H94.3795C88.932 63.9998 84.4766 59.5444 84.4766 54.0968V12.8795C84.4766 7.432 88.932 2.97656 94.3795 2.97656H135.597C141.044 2.97656 145.5 7.432 145.5 12.8795V54.0968C145.518 59.5444 141.062 63.9998 135.615 63.9998Z"
      fill="#DFE3FF"
    />
    <path
      d="M132.62 61.0233H91.403C85.9554 61.0233 81.5 56.5691 81.5 51.1232V9.90005C81.5 4.45412 85.9554 0 91.403 0H132.62C138.068 0 142.523 4.45412 142.523 9.90005V51.1052C142.541 56.5691 138.086 61.0233 132.62 61.0233Z"
      fill="#40196D"
    />
    <g clipPath="url(#clip0_50813_1213)">
      <path
        d="M104.99 41.3933C104.118 40.5154 104.118 39.1067 104.99 38.2288L123.472 19.6301C124.344 18.7522 125.744 18.7522 126.616 19.6301C127.488 20.508 127.488 21.9166 126.616 22.7945L108.135 41.3933C107.262 42.2711 105.863 42.2711 104.99 41.3933Z"
        fill="white"
      />
      <path
        d="M108.095 41.3727C107.223 42.2505 105.823 42.2505 104.951 41.3727L97.4043 33.7984C96.5319 32.9206 96.5319 31.5119 97.4043 30.634C98.2766 29.7561 99.6764 29.7561 100.549 30.634L108.095 38.2286C108.968 39.0861 108.968 40.4948 108.095 41.3727Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_50813_1213">
        <rect
          width="30.5116"
          height="23.0698"
          fill="white"
          transform="translate(96.7539 18.9766)"
        />
      </clipPath>
    </defs>
  </svg>
);
