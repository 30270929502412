
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Table from "library/Tables/Table";
import Dropdown from "library/Dropdown/Dropdown";

import { LoadMoreSearchIcon } from "components/SvgPack";

import { ModifiedMain } from "layout/Main";

import { paths, fxRateHistoryHeaderLink } from "../HeaderLinks";
import { StyledRemmitanceMgmtWrapper } from "../shared/styles/home.styles";
import { StyledMerchantTableFilterExtra } from "../shared/styles/merchant.styles";
import { fxRateColumns } from "./components/columns";
import { fxRateCurrencies } from "../shared/utils";
import DateRangePicker from "library/DateRangePicker/DateRangePicker";

import { ToastContext } from "context/ToastContext";
import { getFXRatesHistory } from "redux/feature/remittanceManagement/renittabceMgmtActions";



const ManageFXRates = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const toastContext = useContext(ToastContext);

  const [filterValue, setFilterValue] = useState(fxRateCurrencies[0]?.label);
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(undefined);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [searchParam, setSearchParam] = useState("");
  const datePickerDataModel = {
    startDate: "",
    endDate: "",
  };

  const [date, setDate] = useState(datePickerDataModel);

  const { isLoading } = useSelector((state) => ({
    isLoading: state.remittanceMgmt.isFXRatesHistoryLoading,
  }));

  const toastConfig = {
    error: false,
    autoDismiss: true,
  };

  const onError = (message) => {
    toastConfig.error = true;
    toastContext.setToast(message, toastConfig);
    setData([])
  };

  const onSuccess = (data, totalRecords) => {
    setData(data);
    setTotalRecords(totalRecords);
  };

  const getRecords = (pageSize, pageNumber) => {
    setPageSize(pageSize);
    setPageNumber(pageNumber);
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const fetchFXRates = () => {
    dispatch(
      getFXRatesHistory(
        {
          pageSize,
          pageNumber,
          currency: searchParam ?? "",
          ...date
        },
        onSuccess,
        onError
      )
    );
  };

  const handleDate = (date) => {
    setDate(date);
  }

  const handleSelect = (value) => {
    setFilterValue(value)
    setSearchParam(value === "ALL" ? "" : value)
  };

  useEffect(() => {
    fetchFXRates();
  }, [pageSize, pageNumber, searchParam, date]);

  return (
    <ModifiedMain
      childSubRoute
      links={fxRateHistoryHeaderLink}
      path={paths.whitelistedTerminals}
      isSticky={false}
    >
      <StyledRemmitanceMgmtWrapper noGap>
        <div className="top-header">
          <h1>Fx Rate History</h1>

        </div>
        <div className="divider" />


        <Table
          setSearchParam={setSearchParam}
          getRecords={getRecords}
          totalRecords={totalRecords}
          selectFieldLowerHeight={true}
          isLoading={isLoading}
          width="400px"
          columns={fxRateColumns}
          data={data}
          searchFieldPlaceholder="Search"
          customEmptyStateIcon={<LoadMoreSearchIcon />}
          hideSearchField
          customEmptyState={
            <div
              className="d-flex flex-column"
              style={{ alignItems: "center", width: 380 }}
            >
              <p className="text">Not Found</p>
              <p className="sub-text" style={{ fontSize: 15, color: "#979797" }}>
                We couldn’t find a result for the search query entered. Please,
                check and try again.
              </p>
            </div>
          }
          customFilterComponent={
            <StyledMerchantTableFilterExtra>
              <div className="flex gap-10">
                <div className="table-extras">
                  <p className="extra-text">Filter By:</p>
                  <Dropdown filterName="Currencies" items={fxRateCurrencies} onSelect={handleSelect} />
                </div> <div className="table-extras">
                  <DateRangePicker filterDate={handleDate} />
                </div>
              </div>
            </StyledMerchantTableFilterExtra>
          }
        />
      </StyledRemmitanceMgmtWrapper>
    </ModifiedMain>
  );
};

export default ManageFXRates;
