import React, {
  useState,
  useContext,
  useRef,
  useEffect,
  useCallback,
} from "react";
import PropTypes from "prop-types";
import { StyledSelect } from "./Select.styles";
import { ThemeContext } from "styled-components";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import Icon from "library/Icons/Icon";
import { SearchIcon } from "components/SvgPack";
import { debounce } from "lodash";
import { convertToLoweCase } from "utils/utilityFunctions";

const Select = ({
  label,
  width,

	// {
	// 	name: string,
	// 	disabled?: boolean,
	// 	subText?: string,
	// }[] | string[]

  options,
  placeholder,
  defaultValue,
  onGetSelectValue,
  optionKey,
  disabled = false,
  value = null,
  renderCustomButton,
  allowSearch,
  searchPlaceholder,
  showOptionImage,
  onToggle = (toggleState) => {},
  showBoxShadow = true,
  selectItemMaxHeight,
}) => {
  const [savedInputValue, setSavedInputValue] = useState("");

  const node = useRef();
  const inputRef = useRef(null);

  useOnClickOutside(node, () => {
    handleOpen();

    if (selectedValue.length > 0) {
      setInputValue(selectedValue);
    } else if (savedInputValue.length > 0) {
      setInputValue(selectedValue);
    }
  });
  const theme = useContext(ThemeContext);

  const [open, setOpen] = useState(false);
  const [selectedValue, setselectedValue] = useState(placeholder);
  const [selectOptions, setSelectOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setSelectOptions(options);
  }, [options]);

  const onChange = debounce((searchValue) => {
    setInputValue(searchValue);
    setSavedInputValue(searchValue);
    if (selectOptions.length > 0 && searchValue.trim().length > 0) {
      const inputValue = convertToLoweCase(searchValue);
      let filteredOptions = [];

      if (typeof selectOptions[0] === "string") {
        filteredOptions = options.filter((item) =>
          convertToLoweCase(item).includes(inputValue)
        );
      } else {
        filteredOptions = options.filter((item) =>
          Object.values(item).some(
            (value) =>
              typeof value === "string" &&
              convertToLoweCase(value).includes(inputValue)
          )
        );
      }

      if (filteredOptions.length === 0) {
        filteredOptions.push({
          name: "Search Not Found",
        });
      }

      setSelectOptions(filteredOptions);
    } else {
      setSelectOptions(options);
    }
  });

  const getSelectDefaultValue = useCallback(
    (value) => {
      const defaultValue =
        Array.isArray(selectOptions) &&
        selectOptions.find(
          (item) =>
            item[optionKey] && item[optionKey].toString() === value.toString()
        );
      return defaultValue ? defaultValue.name : placeholder;
    },
    [defaultValue, selectOptions]
  );

  useEffect(() => {
    if (defaultValue) {
      let field = getSelectDefaultValue(defaultValue);
      setselectedValue(field);
      setInputValue(field);
      setSavedInputValue(field);
    }

    return () => {
      if (defaultValue) {
        setselectedValue(placeholder);
      }
    };
  }, [getSelectDefaultValue]);

  // controlling the select display field
  useEffect(() => {
    if (value) {
      setselectedValue(value);
      setInputValue(value);
      setSavedInputValue(value);
    } else {
      setselectedValue(placeholder);
      setInputValue("");
      setSavedInputValue("");
    }
  }, [value]);

  const handleOpen = () => {
    onToggle(!open);
    setOpen(!open);
  };

  const handleSelectValue = (item) => {
    setselectedValue(value ? value : item.name || item);
    setInputValue(value ? value : item.name || item);
    setSavedInputValue(value ? value : item.name || item);

    if (item[optionKey]) {
      onGetSelectValue(item[optionKey].toString());
    } else {
      onGetSelectValue(item.name || item);
    }
    handleOpen();
  };

  useEffect(() => {
    setSelectOptions(options);
  }, [open]);

  return (
    <StyledSelect
      open={open}
      theme={theme}
      width={width}
      label={label}
      disabled={disabled}
      showBoxShadow={showBoxShadow}
      selectItemMaxHeight={selectItemMaxHeight}
    >
      {label && <label className="label">{label}</label>}
      {renderCustomButton ? (
        renderCustomButton(selectedValue, handleOpen)
      ) : (
        <button
          className="selected"
          onClick={() => {
            handleOpen();
            if (inputRef?.current) {
              inputRef?.current?.focus();
              setOpen(true);
            }
          }}
          disabled={disabled}
        >
          {allowSearch ? (
            <>
              <div className="select-search">
                <input
                  ref={inputRef}
                  placeholder={searchPlaceholder ?? ""}
                  type="text"
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                  value={inputValue}
                  onFocus={() => {
                    setInputValue("");
                    setOpen(true);
                  }}
                />
              </div>
            </>
          ) : (
            <>{selectedValue}</>
          )}
        </button>
      )}

      {open && (
        <div className="select-items" ref={node}>
          {selectOptions.map((option, i) => (
            <div
              key={i}
              onClick={() => {
                if (option?.name === "Search Not Found") {
                  setOpen(false);
                  setInputValue(selectedValue.length ? selectedValue : "");
                  setSavedInputValue(selectedValue.length ? selectedValue : "");
                } else {
									if(option?.disabled) {
										handleOpen();
									} else {
										handleSelectValue(option);
									}
                }
              }}
              className="select-items-options"
            >
              {showOptionImage && option?.imageUrl ? (
                <img src={option?.imageUrl} alt={option?.name || option} />
              ) : null}

              <div className={`${option?.disabled ? 'disabled-option' : ''}`}>
                {option?.name || option}

                {option?.subtext && (
                  <p className="select-items-options_subtext">
                    {option?.subtext}
                  </p>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </StyledSelect>
  );
};

Select.propTypes = {
  id: PropTypes.string, //This ID for input
  items: PropTypes.string, // The dropdown items
  onClick: PropTypes.func, // The function event triggered when the select input is clicked
  iconRight: PropTypes.node, // Icon ( appears on the right )
  iconLeft: PropTypes.node, // Icon ( appears on the left )
  icon: PropTypes.node, //  Icon (appears on the right or left)
  label: PropTypes.string, // The label tag of an input
  placeholder: PropTypes.string, // The placeholder value for the input
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // The select content default value
  optionKey: PropTypes.string, // The option key value of the selec input
  value: PropTypes.string, //A way to control the value diplayed on Select,
  showOptionImage: PropTypes.bool,
};

Select.defaultProps = {
  placeholder: "Select Item",
  label: "",
  defaultValue: "",
  allowSearch: false,
  showOptionImage: false,
};

export default Select;
