import React from "react";
import  Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import PropTypes from "prop-types";
import "react-loading-skeleton/dist/skeleton.css";

const SkeletonWrap = (height, circle, width, color, count, duration, wrapper) => {
  return (
    <SkeletonTheme color={color}>
      <Skeleton
        height={height}
        circle={circle}
        width={width}
        count={count}
        duration={duration}
        wrapper={wrapper} />
    </SkeletonTheme>
  );
};

SkeletonWrap.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  duration: PropTypes.number,
  count: PropTypes.number,
  color: PropTypes.string,
  circle: PropTypes.bool,
  wrapper: PropTypes.func,
};

SkeletonWrap.defaultProps = {
  wrapper: "box",
  color: "#F9F9F9",
  duration: 1.5,
  count: 1,
  circle: false,
};

export default Skeleton;
